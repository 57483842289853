import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Card, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  createNavbar,
  resetNavbarCreate,
  getAllNavbars,
  resetNavbarList,
  deleteNavbar,
  resetNavbarDelete,
} from "../features/navbars/navbarSlice";

import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { motion } from "framer-motion";

const CreateNavbarScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [image, setImage] = useState("");
  const [allNavbars, setAllNavbars] = useState([]);

  const { userInfo } = useSelector((state) => state.auth);

  const {
    navbars,

    isListLoading,
    isListError,
    listErrorMessage,
    isListSuccess,

    isCreateLoading,
    isCreateError,
    createErrorMessage,
    isCreateSuccess,

    isDeleteLoading,
    isDeleteError,
    deleteErrorMessage,
    isDeleteSuccess,
  } = useSelector((state) => state.navbar);

  useEffect(() => {
    if (!userInfo) {
      toast.error("Please login to perform this action!", {
        position: "top-center",
      });
      navigate("/");
    } else if (userInfo.userType !== "admin") {
      toast.error("You are not authorized to perform this action!", {
        position: "top-center",
      });
      navigate("/");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (isCreateSuccess) {
      toast.success("Navbar created successfully!", {
        position: "top-center",
      });
      setImage("");
      dispatch(getAllNavbars());
      dispatch(resetNavbarCreate());
      // navigate("/createNavbar");
    } else if (isCreateError) {
      toast.error(createErrorMessage, {
        position: "top-center",
      });
    }
  }, [isCreateSuccess, isCreateError, createErrorMessage, navigate, navbars]);

  useEffect(() => {
    if (isListError) {
      // toast.error(listErrorMessage, {
      //   position: 'top-center',
      // })
    } else if (isListSuccess) {
      setAllNavbars(navbars);
    } else {
      dispatch(getAllNavbars());
    }
  }, [dispatch, isListSuccess, isListError, listErrorMessage, navbars]);

  useEffect(() => {
    if (isDeleteError) {
      toast.error(deleteErrorMessage, {
        position: "top-center",
      });
    } else if (isDeleteSuccess) {
      toast.success("Navbar deleted successfully!", {
        position: "top-center",
      });
      dispatch(getAllNavbars());
      dispatch(resetNavbarDelete());
    }
  }, [isDeleteError, isDeleteSuccess, deleteErrorMessage, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetNavbarCreate());
      dispatch(resetNavbarList());
      dispatch(resetNavbarDelete());
    };
  }, [dispatch]);

  //Submit Handler
  // const submitHandler = (e) => {
  //   e.prevenetDefault()
  //   // if (image !== '') {
  //   dispatch(createNavbar({ image }))
  //   // } else {
  //   //   toast.error('Please upload an image!', {
  //   //     position: 'top-center',
  //   //   })
  //   // }
  // }

  // Delete Handler
  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this navbar?")) {
      dispatch(deleteNavbar(id));
    }
  };

  // Image Upload Handler
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        `/api/v1/upload${image ? `/${image.slice(8)}` : ""}`,
        formData,
        config
      );
      setImage(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Row className="py-5">
        <Card.Text as="h2" className="text-center">
          Upload Navbar
        </Card.Text>
      </Row>

      <Row className="mb-5">
        {/* Left column for upload */}
        <Col lg={3} md={4} sm={12} className="mb-5">
          <Row>
            <Card.Text as="h3" className="text-center mb-4">
              Upload Navbars
            </Card.Text>
          </Row>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(createNavbar({ image }));
            }}
          >
            <Card className="mb-4 shadow rounded">
              <Card.Header as="h4" className="text-center">
                navbars
              </Card.Header>
              <Card.Body className="text-center">
                <Card.Img
                  cascade
                  className="img-fluid"
                  src={image !== "" ? image : "/img/test.png"}
                  style={{ height: "20vh", objectFit: "cover" }}
                ></Card.Img>
                <Form.Group controlId="image 1">
                  <Form.Label className="mt-2">Upload Navbar Image</Form.Label>
                  <Form.Control
                    required
                    className="mb-3 rounded"
                    type="file"
                    id="image-file"
                    label="Choose Navbars"
                    controlId="navbarImage"
                    onChange={uploadFileHandler}
                  ></Form.Control>
                </Form.Group>
              </Card.Body>
            </Card>

            <Button
              type="submit"
              variant="outline-dark"
              className="shadow rounded col-12"
            >
              Upload Navbar
            </Button>
          </Form>
        </Col>

        {/* Right column for list */}
        <Col sm={12} md={8} lg={9}>
          <Row>
            <Card.Text as="h3" className="text-center mb-4">
              Navbar List
            </Card.Text>
          </Row>
          <Row>
            {isListLoading ? (
              <Loader />
            ) : allNavbars.length <= 0 ? (
              <h2 variant="danger">No Navbar Found</h2>
            ) : (
              <>
                {allNavbars.map((navbar) => (
                  <Col lg={3} md={4} sm={12}>
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <Card
                        key={navbar._id}
                        className="shadow rounded mb-3 cursorAll"
                      >
                        <Card.Body>
                          <Card.Img
                            style={{ borderRadius: "10px" }}
                            cascade
                            className="img-fluid"
                            alt="Navbars"
                            src={navbar.image}
                          />

                          {userInfo &&
                          userInfo.userType === "admin" &&
                          allNavbars.length > 1 ? (
                            <Button
                              variant="outline-dark"
                              className="rounded mt-3 col-12"
                              onClick={() => deleteHandler(navbar._id)}
                            >
                              Remove Navbar
                            </Button>
                          ) : null}
                        </Card.Body>
                      </Card>
                    </motion.div>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateNavbarScreen;
