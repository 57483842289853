import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  ListGroup,
  Carousel,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import {
  createSmartphone,
  resetSmartphoneCreate,
} from "../features/smartphone/smartphoneSlice";
import {
  updateSmartphoneAddRequest,
  resetSmartphoneAddRequestUpdate,
} from "../features/smartphoneaddrequest/smartphoneAddRequestSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { AiFillPlusSquare } from "react-icons/ai";
import { MdCancel } from "react-icons/md";

const CreateSmartPhoneScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);

  const [searchParams] = useSearchParams();

  // Smart Phone States
  const [smartPhoneBrand, setSmartPhoneBrand] = useState("");

  const [smartPhoneModel, setSmartPhoneModel] = useState("");
  const [smartPhoneRAM, setSmartPhoneRAM] = useState("");
  const [smartPhoneROM, setSmartPhoneROM] = useState("");

  const [smartPhoneGPU, setSmartPhoneGPU] = useState("");

  const [smartPhoneProcessor, setSmartPhoneProcessor] = useState("");
  const [smartPhoneDisplay, setSmartPhoneDisplay] = useState("");

  const [frontCamera, setFrontCamera] = useState("");
  const [backCamera, setBackCamera] = useState("");

  const [smartPhoneBattery, setSmartPhoneBattery] = useState("");
  const [smartPhoneSIM, setSmartPhoneSIM] = useState("");

  const [smartPhoneCoverImage, setSmartPhoneCoverImage] = useState("");
  const [smartPhoneImages, setSmartPhoneImages] = useState([]);

  const [smartPhonePrice, setSmartPhonePrice] = useState(0);
  const [smartPhoneSellingPrice, setSmartPhoneSellingPrice] = useState(0);
  const [smartPhoneStock, setSmartPhoneStock] = useState("");

  const [smartphoneProductType, setSmartphoneProductType] = useState("");

  const [smartphoneVariiants, setSmartphoneVariiants] = useState([]);

  const [description, setDescription] = useState("");

  const [showVariantFields, setShowVariantFields] = useState(false);

  const {
    smartphone,
    isCreateError,
    isCreateSuccess,
    isCreateLoading,
    createErrorMessage,
  } = useSelector((state) => state.smartphone);

  const {
    smartphoneAddRequest,
    isUpdateError,
    isUpdateSuccess,
    isUpdateLoading,
    updateErrorMessage,
  } = useSelector((state) => state.smartphoneAddRequest);

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (userInfo.userType !== "admin") {
      toast.error("You are not authorized to view this page");
      navigate("/");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (isUpdateError) {
      toast.error(updateErrorMessage, { position: "top-center" });
    } else if (isUpdateSuccess) {
      toast.success(
        "Smart Phone Created Successfully & Status Will be Updated",
        {
          position: "top-center",
        }
      );
      // navigate("/dashboard");
    }
  }, [isUpdateError, isUpdateSuccess, updateErrorMessage]);

  useEffect(() => {
    if (isCreateSuccess) {
      toast.success("Smart Phone Created Successfully", {
        position: "top-center",
      });
      navigate("/buySmartPhone");
    } else if (isCreateError) {
      toast.error(createErrorMessage, { position: "top-center" });
    }
  }, [
    isCreateError,
    isCreateSuccess,
    createErrorMessage,
    smartphone,
    navigate,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetSmartphoneCreate());
      dispatch(resetSmartphoneAddRequestUpdate());
    };
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      smartPhoneBrand !== "" &&
      smartPhoneModel !== "" &&
      // smartPhoneRAM !== '' &&
      // smartPhoneROM !== '' &&
      smartPhoneGPU !== "" &&
      smartPhoneProcessor !== "" &&
      smartPhoneDisplay !== "" &&
      frontCamera !== "" &&
      backCamera !== "" &&
      smartPhoneBattery !== "" &&
      smartPhoneSIM !== "" &&
      smartPhoneCoverImage !== "" &&
      smartphoneProductType !== "" &&
      // smartPhonePrice !== '' &&
      smartphoneVariiants.length !== 0
      // smartPhoneStock !== ''
      // description !== ""
    ) {
      dispatch(
        createSmartphone({
          brand: smartPhoneBrand,
          model: smartPhoneModel,
          variants: smartphoneVariiants,
          specifications: {
            processor: smartPhoneProcessor,
            GPU: smartPhoneGPU,
            display: smartPhoneDisplay,
            camera: {
              frontCamera: frontCamera,
              backCamera: backCamera,
            },
            battery: smartPhoneBattery,
            SIM: smartPhoneSIM,
          },
          productType: smartphoneProductType,
          coverImage: smartPhoneCoverImage,
          images: smartPhoneImages,
          // price: smartPhonePrice,
          // sellingPrice: smartPhoneSellingPrice,
          // stock: smartPhoneStock,
          description: description,
        })
      );
      if (searchParams.get("requestid")) {
        dispatch(
          updateSmartphoneAddRequest({
            id: searchParams.get("requestid"),
            smartphoneAddRequestData: {
              status: "Approved",
            },
          })
        );
      }
    } else {
      toast.error("Please fill all the fields & add at least one variant", {
        position: "top-center",
      });
    }
  };

  // Cover Image Upload
  const uploadCoverImageFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `/api/v1/upload${
          smartPhoneCoverImage ? `/${smartPhoneCoverImage.slice(8)}` : ""
        }`,
        formData,
        config
      );
      setSmartPhoneCoverImage(data);
    } catch (error) {
      console.error(error);
    }
  };

  // Multiple Image Upload
  const uploadImageFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(`/api/v1/upload/`, formData, config);

      setSmartPhoneImages([...smartPhoneImages, data]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <>
        {isCreateLoading ? (
          <Loader />
        ) : (
          <Container>
            <Row className="py-5">
              <Card.Text as="h2" className="text-center">
                Create Smart Phone
              </Card.Text>
            </Row>

            <Form onSubmit={submitHandler}>
              <Row>
                <Col sm={12} md={4} lg={3}>
                  <Card className="mb-4 shadow rounded">
                    <Card.Header as="h4" className="p-3 text-center">
                      Cover Image
                    </Card.Header>
                    <Card.Body className="text-center">
                      <Card.Img
                        cascade
                        className="img-fluid rounded"
                        src={
                          smartPhoneCoverImage !== ""
                            ? smartPhoneCoverImage
                            : "https://via.placeholder.com/150"
                        }
                        style={{ height: "20vh", objectFit: "cover" }}
                      />
                      <Form.Group controlId="image 1">
                        <Form.Label className="py-3">
                          Upload Cover Image
                        </Form.Label>
                        <Form.Control
                          required
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Cover Image For Smart Phone"
                          controlId="smartPhoneCoverImage"
                          onChange={uploadCoverImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Card className="mb-4 shadow rounded">
                    <Card.Header as="h4" className="p-3 text-center">
                      Other Images
                    </Card.Header>
                    <Card.Body className="text-center">
                      {smartPhoneImages.length <= 0 ? (
                        <Card.Img
                          cascade
                          className="img-fluid rounded"
                          src="https://via.placeholder.com/150"
                          style={{ height: "20vh", objectFit: "cover" }}
                        />
                      ) : (
                        <Carousel>
                          {smartPhoneImages.map((image, index) => (
                            <Carousel.Item>
                              <img
                                className="d-block w-100"
                                src={image}
                                alt={`Image-${index}`}
                                style={{
                                  maxHeight: "20vh",
                                  objectFit: "cover",
                                }}
                              />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      )}

                      <Form.Group controlId="image 1">
                        <Form.Label className="py-3">
                          Upload Other Images
                        </Form.Label>
                        <Form.Control
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Images"
                          onChange={uploadImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={12} md={8} lg={9}>
                  <Card className="mb-4 shadow rounded">
                    <Card.Header as="h4" className="p-3 text-center">
                      Smart Phone Information
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="smartPhoneBrand"
                          >
                            <Form.Label className="small mb-1">
                              Smart Phone Brand
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                smartPhoneBrand === ""
                                  ? "Brand Name is Required"
                                  : "Enter Smart Phone Brand Name"
                              }
                              value={smartPhoneBrand}
                              onChange={(e) =>
                                setSmartPhoneBrand(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="smartPhoneModel"
                          >
                            <Form.Label className="small mb-1">
                              Smart Phone Model
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                smartPhoneModel === ""
                                  ? "Model Name is Required"
                                  : "Enter Smart Phone Model Name"
                              }
                              value={smartPhoneModel}
                              onChange={(e) =>
                                setSmartPhoneModel(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="smartPhoneProcessor"
                          >
                            <Form.Label className="small mb-1">
                              Processor
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                smartPhoneProcessor === ""
                                  ? "Processor is Required"
                                  : "Enter Smart Phone Processor"
                              }
                              value={smartPhoneProcessor}
                              onChange={(e) =>
                                setSmartPhoneProcessor(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="smartPhoneGPU"
                          >
                            <Form.Label className="small mb-1">GPU</Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                smartPhoneGPU === ""
                                  ? "Define GPU"
                                  : "Enter Smart Phone GPU"
                              }
                              value={smartPhoneGPU}
                              onChange={(e) => setSmartPhoneGPU(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="smartPhoneDisplay"
                          >
                            <Form.Label className="small mb-1">
                              Display
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                smartPhoneDisplay === ""
                                  ? "Display is Required"
                                  : "Enter Smart Phone Display"
                              }
                              value={smartPhoneDisplay}
                              onChange={(e) =>
                                setSmartPhoneDisplay(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="smartPhoneBattery"
                          >
                            <Form.Label className="small mb-1">
                              Battery
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                smartPhoneBattery === ""
                                  ? "Battery is Required"
                                  : "Enter Smart Phone Battery"
                              }
                              value={smartPhoneBattery}
                              onChange={(e) =>
                                setSmartPhoneBattery(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="frontCamera">
                            <Form.Label className="small mb-1">
                              Front Camera
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                frontCamera === ""
                                  ? "Front Camera is Required"
                                  : "Specify Front Camera"
                              }
                              value={frontCamera}
                              onChange={(e) => setFrontCamera(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="backCamera">
                            <Form.Label className="small mb-1">
                              Back Camera
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                backCamera === ""
                                  ? "Back Camera is Required"
                                  : "Specify Back Camera"
                              }
                              value={backCamera}
                              onChange={(e) => setBackCamera(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="smartPhoneSIM"
                          >
                            <Form.Label className="small mb-1">SIM</Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              as="select"
                              type="select"
                              placeholder={
                                smartPhoneSIM === ""
                                  ? "SIM is Required"
                                  : "Enter Smart Phone SIM"
                              }
                              value={smartPhoneSIM}
                              onChange={(e) => setSmartPhoneSIM(e.target.value)}
                            >
                              <option disabled value="">
                                SIM Slots
                              </option>
                              <option value="Dual SIM">Dual SIM</option>
                              <option value="Single SIM">Single SIM</option>
                              <option value="eSIM">eSIM</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="smartPhoneType"
                          >
                            <Form.Label className="small mb-1">
                              Product Type
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              as="select"
                              type="select"
                              placeholder={
                                smartphoneProductType === ""
                                  ? "Type is Required"
                                  : "Select Smart Phone Type"
                              }
                              value={smartphoneProductType}
                              onChange={(e) =>
                                setSmartphoneProductType(e.target.value)
                              }
                            >
                              <option disabled value="">
                                Select Type
                              </option>
                              <option value="New">New</option>
                              <option value="Pre-Owned">Pre-Owned</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        {/* List to display added variants */}
                        {smartphoneVariiants.length > 0 && (
                          <>
                            <Col lg={12} md={12} sm={12}>
                              Variants
                            </Col>
                            <Col lg={12} md={12} sm={12} className="mb-3">
                              <ListGroup variant="flush">
                                {smartphoneVariiants.map((variant) => (
                                  <ListGroup.Item key={variant.id}>
                                    <Row>
                                      <Col lg={3} md={3} sm={3}>
                                        {`${variant.RAM}GB | ${variant.ROM}GB`}
                                      </Col>
                                      <Col lg={3} md={3} sm={3}>
                                        {`Price: BDT ${variant.price}`}
                                      </Col>
                                      <Col lg={3} md={3} sm={3}>
                                        {`Selling Price: BDT ${variant.sellingPrice}`}
                                      </Col>
                                      {smartphoneVariiants.length > 1 && (
                                        <Col lg={3} md={3} sm={3}>
                                          <Button
                                            variant="danger rounded"
                                            onClick={() => {
                                              setSmartphoneVariiants(
                                                smartphoneVariiants.filter(
                                                  (x) =>
                                                    x.price !== variant.price
                                                )
                                              );
                                            }}
                                          >
                                            Delete Variant
                                          </Button>
                                        </Col>
                                      )}
                                    </Row>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </Col>
                          </>
                        )}

                        {/* Button for showing fields to add a new variant */}
                        {showVariantFields ? (
                          <>
                            <Col lg={6} md={6} sm={6} className="mb-3">
                              <Button
                                variant="outline-success rounded"
                                onClick={() => {
                                  // Needs further implementations
                                  if (
                                    smartPhoneRAM !== "" &&
                                    smartPhoneROM !== "" &&
                                    smartPhonePrice !== 0 &&
                                    smartPhoneSellingPrice !== 0 &&
                                    smartPhoneStock !== ""
                                  ) {
                                    setSmartphoneVariiants([
                                      ...smartphoneVariiants,
                                      {
                                        RAM: smartPhoneRAM,
                                        ROM: smartPhoneROM,
                                        price: smartPhonePrice,
                                        sellingPrice: smartPhoneSellingPrice,
                                        stock: smartPhoneStock,
                                      },
                                    ]);
                                    setSmartPhoneRAM("");
                                    setSmartPhoneROM("");
                                    setSmartPhonePrice(0);
                                    setSmartPhoneSellingPrice(0);
                                    setSmartPhoneStock("");
                                    setShowVariantFields(false);
                                  } else {
                                    toast.error(
                                      "Please input all the information to add a variant",
                                      { position: "top-center" }
                                    );
                                  }
                                }}
                              >
                                <AiFillPlusSquare
                                  size={18}
                                  className="mb-1 me-2"
                                />
                                Add Variant
                              </Button>
                            </Col>
                            <Col lg={6} md={6} sm={6} className="mb-3">
                              <Button
                                variant="outline-danger rounded"
                                onClick={() => {
                                  // Needs further implementations
                                  setSmartPhoneRAM("");
                                  setSmartPhoneROM("");
                                  setSmartPhonePrice(0);
                                  setSmartPhoneSellingPrice(0);
                                  setSmartPhoneStock("");
                                  setShowVariantFields(false);
                                }}
                              >
                                <MdCancel
                                  size={18}
                                  className="mb-1 me-2"
                                />
                                Cancel Adding Variant
                              </Button>
                            </Col>
                          </>
                        ) : (
                          <Col lg={12} md={12} sm={12} className="mb-3">
                            <Button
                              variant="outline-dark rounded"
                              onClick={() => setShowVariantFields(true)}
                            >
                              <AiFillPlusSquare
                                size={18}
                                className="mb-1 me-2"
                              />
                              Add a new Variant and price
                            </Button>
                          </Col>
                        )}

                        {/* Fields to add a new variant */}
                        {showVariantFields && (
                          <>
                            <Col sm={12} md={6} lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="smartPhoneRAM"
                              >
                                <Form.Label className="small mb-1">
                                  Smart Phone RAM
                                </Form.Label>
                                <Form.Control
                                  className=" rounded"
                                  required
                                  type="text"
                                  placeholder={
                                    smartPhoneRAM === ""
                                      ? "Ram is Required"
                                      : "Specify Smart Phone Ram"
                                  }
                                  value={smartPhoneRAM}
                                  onChange={(e) =>
                                    setSmartPhoneRAM(e.target.value)
                                  }
                                ></Form.Control>
                              </Form.Group>
                            </Col>

                            <Col sm={12} md={6} lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="smartPhoneROM"
                              >
                                <Form.Label className="small mb-1">
                                  Smart Phone ROM
                                </Form.Label>
                                <Form.Control
                                  className=" rounded"
                                  required
                                  type="text"
                                  placeholder={
                                    smartPhoneROM === ""
                                      ? "Rom is Required"
                                      : "Specify Smart Phone Rom"
                                  }
                                  value={smartPhoneROM}
                                  onChange={(e) =>
                                    setSmartPhoneROM(e.target.value)
                                  }
                                ></Form.Control>
                              </Form.Group>
                            </Col>

                            <Col lg={6} md={6} sm={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="smartPhonePrice"
                              >
                                <Form.Label className="small mb-1">
                                  Price
                                </Form.Label>
                                <Form.Control
                                  className=" rounded"
                                  required
                                  type="number"
                                  placeholder={
                                    smartPhonePrice === ""
                                      ? "Price is Required"
                                      : "Enter Smart Phone Price"
                                  }
                                  value={smartPhonePrice}
                                  onChange={(e) =>
                                    setSmartPhonePrice(e.target.value)
                                  }
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="smartPhoneSellingPrice"
                              >
                                <Form.Label className="small mb-1">
                                  Estimated Selling Price
                                </Form.Label>
                                <Form.Control
                                  className=" rounded"
                                  required
                                  type="number"
                                  placeholder="Define Estimated Selling Price"
                                  value={smartPhoneSellingPrice}
                                  onChange={(e) =>
                                    setSmartPhoneSellingPrice(e.target.value)
                                  }
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="smartPhoneStock"
                              >
                                <Form.Label className="small mb-1">
                                  Status
                                </Form.Label>
                                <Form.Control
                                  className=" rounded"
                                  required
                                  as="select"
                                  type="select"
                                  placeholder={
                                    smartPhoneStock === ""
                                      ? "Status is Required"
                                      : "Select Smart Phone Status"
                                  }
                                  value={smartPhoneStock}
                                  onChange={(e) =>
                                    setSmartPhoneStock(e.target.value)
                                  }
                                >
                                  <option disabled value="">
                                    Select Status
                                  </option>
                                  <option value="In-Stock">In-Stock</option>
                                  <option value="Out of Stock">
                                    Out of Stock
                                  </option>
                                </Form.Control>
                              </Form.Group>
                            </Col>
                          </>
                        )}

                        <Col lg={12} md={12} sm={12}>
                          <Form.Group className="mb-3" controlId="description">
                            <Form.Label className="small mb-1">
                              Other Details
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              // required
                              type="text"
                              as="textarea"
                              rows={4}
                              placeholder="Add other details about the smartphone"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="d-grid px-2">
                        <Button
                          variant="outline-dark"
                          type="submit"
                          className="shadow rounded"
                        >
                          Create Smart Phone
                        </Button>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      </>
    )
  );
};

export default CreateSmartPhoneScreen;
