import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import wearableAddRequestService from "./wearableAddRequestService";

const initialState = {
  wearableAddRequests: [],
  wearableAddRequest: null,

  isListError: false,
  isListLoading: false,
  isListSuccess: false,
  listErrorMessage: "",

  isCreateError: false,
  isCreateLoading: false,
  isCreateSuccess: false,
  createErrorMessage: "",

  isDetailsError: false,
  isDetailsLoading: false,
  isDetailsSuccess: false,
  detailsErrorMessage: "",

  isUpdateError: false,
  isUpdateLoading: false,
  isUpdateSuccess: false,
  updateErrorMessage: "",

  isDeleteError: false,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  deleteErrorMessage: "",
};

//create wearable add request
export const createWearableAddRequest = createAsyncThunk(
  "wearableAddRequest/createWearableAddRequest",
  async (wearableAddRequest, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const createdWearableAddRequest =
        await wearableAddRequestService.createWearableAddRequest(
          wearableAddRequest,
          token
        );
      return createdWearableAddRequest;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all wearable add requests
export const getAllWearableAddRequests = createAsyncThunk(
  "wearableAddRequest/getAllWearableAddRequests",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const wearableAddRequests =
        await wearableAddRequestService.getAllWearableAddRequests(token);
      return wearableAddRequests;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get wearable add request by id
export const getWearableAddRequestById = createAsyncThunk(
  "wearableAddRequest/getWearableAddRequestById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const wearableAddRequest =
        await wearableAddRequestService.getWearableAddRequestById(id, token);
      return wearableAddRequest;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update wearable add request
export const updateWearableAddRequest = createAsyncThunk(
  "wearableAddRequest/updateWearableAddRequest",
  async (wearableAddRequest, thunkAPI) => {
    const { id, wearableAddRequestData } = wearableAddRequest;
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const updatedWearableAddRequest =
        await wearableAddRequestService.updateWearableAddRequest(
          id,
          wearableAddRequestData,
          token
        );
      return updatedWearableAddRequest;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete wearable add request
export const deleteWearableAddRequest = createAsyncThunk(
  "wearableAddRequest/deleteWearableAddRequest",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const deletedWearableAddRequest =
        await wearableAddRequestService.deleteWearableAddRequest(id, token);
      return deletedWearableAddRequest;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const wearableAddRequestSlice = createSlice({
  name: "wearableAddRequest",
  initialState,
  reducers: {
    resetWearableAddRequestCreate: (state) => {
      state.isCreateError = false;
      state.isCreateLoading = false;
      state.isCreateSuccess = false;
      state.createErrorMessage = "";
    },
    resetWearableAddRequestList: (state) => {
      state.wearableAddRequests = [];
      state.isListError = false;
      state.isListLoading = false;
      state.isListSuccess = false;
      state.listErrorMessage = "";
    },
    resetWearableAddRequestDetails: (state) => {
      state.wearableAddRequest = null;
      state.isDetailsError = false;
      state.isDetailsLoading = false;
      state.isDetailsSuccess = false;
      state.detailsErrorMessage = "";
    },
    resetWearableAddRequestUpdate: (state) => {
      state.isUpdateError = false;
      state.isUpdateLoading = false;
      state.isUpdateSuccess = false;
      state.updateErrorMessage = "";
    },
    resetWearableAddRequestDelete: (state) => {
      state.isDeleteError = false;
      state.isDeleteLoading = false;
      state.isDeleteSuccess = false;
      state.deleteErrorMessage = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createWearableAddRequest.pending, (state) => {
        state.isCreateLoading = true;
        state.isCreateError = false;
        state.isCreateSuccess = false;
        state.createErrorMessage = "";
      })
      .addCase(createWearableAddRequest.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = false;
        state.isCreateSuccess = true;
        state.createErrorMessage = "";
        state.wearableAddRequests.push(action.payload);
        state.wearableAddRequest = action.payload;
      })
      .addCase(createWearableAddRequest.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = true;
        state.isCreateSuccess = false;
        state.createErrorMessage = action.payload;
      })
      .addCase(getAllWearableAddRequests.pending, (state) => {
        state.isListLoading = true;
        state.isListError = false;
        state.isListSuccess = false;
        state.listErrorMessage = "";
      })
      .addCase(getAllWearableAddRequests.fulfilled, (state, action) => {
        state.isListLoading = false;
        state.isListError = false;
        state.isListSuccess = true;
        state.listErrorMessage = "";
        state.wearableAddRequests = action.payload;
      })
      .addCase(getAllWearableAddRequests.rejected, (state, action) => {
        state.isListLoading = false;
        state.isListError = true;
        state.isListSuccess = false;
        state.listErrorMessage = action.payload;
      })
      .addCase(getWearableAddRequestById.pending, (state) => {
        state.isDetailsLoading = true;
        state.isDetailsError = false;
        state.isDetailsSuccess = false;
        state.detailsErrorMessage = "";
      })
      .addCase(getWearableAddRequestById.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsError = false;
        state.isDetailsSuccess = true;
        state.detailsErrorMessage = "";
        state.wearableAddRequest = action.payload;
      })
      .addCase(getWearableAddRequestById.rejected, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsError = true;
        state.isDetailsSuccess = false;
        state.detailsErrorMessage = action.payload;
      })
      .addCase(updateWearableAddRequest.pending, (state) => {
        state.isUpdateLoading = true;
        state.isUpdateError = false;
        state.isUpdateSuccess = false;
        state.updateErrorMessage = "";
      })
      .addCase(updateWearableAddRequest.fulfilled, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = false;
        state.isUpdateSuccess = true;
        state.updateErrorMessage = "";
        state.wearableAddRequests = state.wearableAddRequests.map(
          (wearableAddRequest) =>
            wearableAddRequest._id === action.payload._id
              ? action.payload
              : wearableAddRequest
        );
        state.wearableAddRequest = action.payload;
      })
      .addCase(updateWearableAddRequest.rejected, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = true;
        state.isUpdateSuccess = false;
        state.updateErrorMessage = action.payload;
      })
      .addCase(deleteWearableAddRequest.pending, (state) => {
        state.isDeleteLoading = true;
        state.isDeleteError = false;
        state.isDeleteSuccess = false;
        state.deleteErrorMessage = "";
      })
      .addCase(deleteWearableAddRequest.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = false;
        state.isDeleteSuccess = true;
        state.deleteErrorMessage = "";
        state.wearableAddRequests = state.wearableAddRequests.filter(
          (wearableAddRequest) => wearableAddRequest._id !== action.payload._id
        );
        state.wearableAddRequest = null;
      })
      .addCase(deleteWearableAddRequest.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.isDeleteSuccess = false;
        state.deleteErrorMessage = action.payload;
      });
  },
});

export const {
    resetWearableAddRequestCreate,
    resetWearableAddRequestList,
    resetWearableAddRequestDetails,
    resetWearableAddRequestUpdate,
    resetWearableAddRequestDelete,
} = wearableAddRequestSlice.actions;

export default wearableAddRequestSlice.reducer;
