import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer>
      {/* <Container fluid style={{ backgroundColor: "#d9dbda" }}> */}
      <Container fluid style={{ backgroundColor: "#434749" }}>
        {/* 434749 */}
        <Row className="py-2">
          <Col className="d-flex justify-content-center mt-2 text-light">
            Copyright &copy; 2023, Smart Think BD.
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center text-light">
            <p>
              Designed & Developed by{"  "}
              
                <a
                  target="blank"
                  href="https://www.tgitbangladesh.com"
                  style={{ textDecoration: "none" }}
                  className='text-info'
                >
                  TGIT
                </a>
              
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
