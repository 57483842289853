import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Modal,
  Alert,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toast } from "react-toastify";
import Loader from "./Loader";
import {
  createReview,
  resetCreateReview,
  deleteReview,
  resetDeleteReview,
} from "../features/reviews/reviewsSlice";
import { getAllReviews } from "../features/reviews/reviewsSlice";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Reviews = (deviceType) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShowDelete = () => setShowDelete(true);
  const handleCloseDelete = () => setShowDelete(false);

  const [reviewComment, setReviewComment] = useState("");

  const { userInfo } = useSelector((state) => state.auth);

  const {
    reviews,
    isCreateError,
    isCreateLoading,
    isCreateSuccess,
    createErrorMessage,

    isListLoading,
    isListError,
    isListSuccess,
    listErrorMessage,

    isDeleteLoading,
    isDeleteError,
    isDeleteSuccess,
    deleteErrorMessage,
  } = useSelector((state) => state.reviews);

  useEffect(() => {
    if (isDeleteError) {
      toast.error(deleteErrorMessage, { position: "top-center" });
    } else if (isDeleteSuccess) {
      toast.success("Review Deleted Successfully", { position: "top-center" });
      dispatch(getAllReviews());
    }
  }, [isDeleteError, deleteErrorMessage, isDeleteSuccess, navigate]);

  useEffect(() => {
    if (isListError) {
      console.log(listErrorMessage);
    } else if (!isListSuccess) {
      dispatch(getAllReviews());
    }
  }, [dispatch, isListError, isListSuccess, listErrorMessage, reviews]);

  useEffect(() => {
    if (isCreateSuccess) {
      toast.success("Review Added Successfully", { position: "top-center" });
      handleClose();
      setReviewComment("");
      dispatch(getAllReviews());
    } else if (isCreateError) {
      toast.error(createErrorMessage, { position: "top-center" });
    }
  }, [isCreateSuccess, isCreateError, createErrorMessage, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateReview());
      dispatch(resetDeleteReview());
    };
  }, [dispatch]);

  const handleSubmit = (e) => {
    if (reviewComment !== "") {
      dispatch(
        createReview({
          reviewComment,
          user: userInfo._id,
        })
      );
    } else {
      toast.error("Please write a review in the text field to add review", {
        position: "top-center",
      });
    }
  };

  const deleteHandler = (id) => {
    // dispatch(deleteReview(id))
    if (window.confirm("Are you sure you want to delete this review?")) {
      dispatch(deleteReview(id));
    }
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center py-3">Customer Reviews</h1>
      <Row>
        {isListLoading ? (
          <Loader />
        ) : reviews.length <= 0 ? (
          <h3 className='text-center mt-3'>No Reviews Yet</h3>
        ) : (
          <>
            <Carousel
              responsive={responsive}
              transitionDuration={500}
              autoPlay={deviceType !== "mobile" ? true : false}
              autoPlaySpeed={2000}
              deviceType={deviceType}
              infinite={true}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
              {reviews.map((comment) => (
                <Card
                  border="shadow rounded-3 mx-2 text-center cursorAll"
                  style={{
                    minHeight: "18vh",
                    overflow: "hidden",
                    textAlign: "justify",
                  }}
                  key={comment._id}
                  className="shadow"
                >
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <Card.Header>
                        <h5 className="pt-3">
                          {comment.user.firstName} {comment.user.lastName}
                        </h5>
                      </Card.Header>
                      <Card.Text className="text-center px-1 pt-3">
                        {comment.reviewComment}
                      </Card.Text>

                      {userInfo && userInfo.userType === "admin" ? (
                        <>
                          <Button
                            variant="outline-dark rounded"
                            size="sm"
                            onClick={() => deleteHandler(comment._id)}
                            className='mb-4'
                          >
                            Remove
                          </Button>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </Card>
              ))}
            </Carousel>
          </>
        )}
      </Row>

      {userInfo && (
        <Row className="py-3 px-3 d-flex">
          <Col sm={12} md={12} lg={12} className="d-flex justify-content-end">
            <Button
              variant="outline-dark"
              onClick={handleShow}
              size="sm"
              className="rounded"
            >
              <b>Add a Review</b>
            </Button>
          </Col>
        </Row>
      )}

      {/* <Row className="py-3 px-3 d-flex">
        <Col sm={12} md={12} lg={12} className="d-flex justify-content-end">
          <Button
            variant="outline-dark"
            onClick={handleShow}
            size="sm"
            className="rounded"
          >
            <b>Add a Review</b>
          </Button>
        </Col>
      </Row> */}

      {/* Modal for Creaqting a Review */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Create a Review</h5>
          </Modal.Title>
        </Modal.Header>
        {isCreateLoading ? (
          <Loader />
        ) : (
          <Modal.Body>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={
                  reviewComment === ""
                    ? "Please write your review here"
                    : "Please enter your review"
                }
                value={reviewComment}
                onChange={(e) => setReviewComment(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button className="rounded" variant="info" onClick={handleSubmit}>
            Create Review
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Reviews;
