import React from "react";
import { Container, Row, Col, Nav, Button } from "react-bootstrap";
import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaWhatsappSquare,
} from "react-icons/fa";
import {
  FiMail,
  FiPhone
} from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";

const UsefulLinks = () => {
  return (
    <div fluid className="bg-light">
      <Container>
        <Row className='py-3'>
          <Col className="text-center d-flex justify-content-center py-2">
            <Nav className="flex-column">
              <Nav.Link eventKey="disabled" disabled>
                BUY
              </Nav.Link>
              <Nav.Link href="/buySmartPhone">Smartphone</Nav.Link>
              <Nav.Link href="/buyTablet">Tablet</Nav.Link>
              <Nav.Link href="/buyWearable">Wearable</Nav.Link>
              <Nav.Link href="/buyGadget">Gadgets</Nav.Link>
            </Nav>
          </Col>
          <Col className="d-flex justify-content-center py-2 text-center">
            <Nav className="flex-column">
              <Nav.Link eventKey="disabled" disabled>
                SELL
              </Nav.Link>
              <Nav.Link href="/sellSmartphone">Smartphone</Nav.Link>
              <Nav.Link href="/sellTablets">Tablet</Nav.Link>
              <Nav.Link href="/">Gadgets</Nav.Link>
              <Nav.Link href="/sellWearable">Wearable</Nav.Link>
            </Nav>
          </Col>
          <Col
            lg={3}
            md={3}
            sm={5}
            className="d-flex justify-content-center py-2"
          >
            <Nav className="flex-column text-center">
              <Nav.Link eventKey="disabled" disabled>
                USEFUL LINKS
              </Nav.Link>
              <Nav.Link href="/aboutUs">About Us</Nav.Link>
              <Nav.Link href="whyST">Why Smart Think</Nav.Link>
              <Nav.Link href="/termsAndCondition">Terms & Condition</Nav.Link>
              <Nav.Link href="/returnRefund">Return & Refund Policy</Nav.Link>
            </Nav>
          </Col>
          <Col
            lg={5}
            md={3}
            sm={6}
            className=" text-center d-flex justify-content-center py-2"
          >
            <Nav className="flex-column">
              <Nav.Link eventKey="disabled" disabled>
                CONTACT INFO
              </Nav.Link>
              <Nav.Link><FiMail /> smart.think055@gmail.com</Nav.Link>
              <Nav.Link><FiPhone /> +880 1302-610088</Nav.Link>
              <Nav.Link>
                <IoLocationOutline /> Jamuna Future Park, Level: 04, Block: C, Shop: 006C,
                Dhaka, Bangladesh <br />
              </Nav.Link>
              <Row className="mx-3">
                <Col>
                  <a
                    className="social-icon"
                    target="blank"
                    href="https://www.facebook.com/smartthinkRTC"
                  >
                    <FaFacebookSquare color="#3b5998" size={35} />
                  </a>
                  <a
                    className="social-icon"
                    target="blank"
                    href="https://www.instagram.com/himel.babu06/?igshid=MDM4ZDc5MmU%3D&fbclid=IwAR2zOiuWUVgKI3-2o0uYj8cqWEeL6I44yRZMjuWrqz1WLN4rHFNJDKhzl1I"
                  >
                    <FaInstagramSquare
                      className="mx-2"
                      color="#f52702"
                      size={35}
                    />
                  </a>
                  <a
                    className="social-icon"
                    target="blank"
                    href="https://wa.me/+8801767821115"
                  >
                    <FaWhatsappSquare color="#11ba0b" size={35} />
                  </a>
                </Col>
              </Row>
            </Nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UsefulLinks;
