import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import CategoriesToBuyNav from "../components/CategoriesToBuyNav";
import WearableDetails from "../components/wearables/WearableDetails";
import {
  getWearableById,
  deleteWearable,
  resetWearableDelete,
  resetWearableDetails,
} from "../features/wearable/wearableSlice";
import { toast } from "react-toastify";
import { AiTwotoneDelete, AiFillEdit } from "react-icons/ai";

const WearableDetailsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { userInfo } = useSelector((state) => state.auth);

  const [wearablE, setWearablE] = useState(null);

  const {
    wearable,

    isDetailsLoading,
    isDetailsError,
    isDetailsSuccess,
    detailsErrorMessage,

    isDeleteLoading,
    isDeleteError,
    isDeleteSuccess,
    deleteErrorMessage,
  } = useSelector((state) => state.wearable);

  useEffect(() => {
    if (isDetailsError) {
      toast.error(detailsErrorMessage, { position: "top-center" });
    } else if (isDetailsSuccess) {
      setWearablE(wearable);
    } else {
      dispatch(getWearableById(id));
    }
  }, [
    dispatch,
    id,
    isDetailsError,
    isDetailsSuccess,
    detailsErrorMessage,
    wearable,
  ]);

  useEffect(() => {
    if (isDeleteError) {
      toast.error(deleteErrorMessage, { position: "top-center" });
    } else if (isDeleteSuccess) {
      toast.success("Wearable deleted successfully", {
        position: "top-center",
      });
      navigate("/buyWearable");
    }
  }, [isDeleteError, deleteErrorMessage, isDeleteSuccess]);

  useEffect(() => {
    return () => {
      dispatch(resetWearableDetails());
      dispatch(resetWearableDelete());
    };
  }, [dispatch]);

  const deleteHandler = () => {
    if (
      window.confirm(
        "This Device will be deleted permanently, You sure you want to proceed?"
      )
    ) {
      dispatch(deleteWearable(params.id));
    }
  };

  return (
    <>
      <Container fluid>
        <CategoriesToBuyNav />
      </Container>

      <Container>
        <WearableDetails deviceType="wearable" device={wearable} />

        {userInfo && userInfo.userType === "admin" ? (
          <Row>
            <Col
              sm={12}
              md={12}
              lg={12}
              className="d-flex justify-content-end gap-2 mb-4"
            >
              <LinkContainer to={`/updateWearable/${id}`}>
                <Button variant="outline-dark rounded">
                  <AiFillEdit className="mb-1" size={18} />
                  Update Information
                </Button>
              </LinkContainer>

              <Button variant="outline-dark rounded" onClick={deleteHandler}>
                <AiTwotoneDelete className="mb-1" size={18} />
                Delete Wearable
              </Button>
            </Col>
          </Row>
        ) : null}
      </Container>
    </>
  );
};

export default WearableDetailsScreen;
