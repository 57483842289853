import axios from 'axios'

//create smartphone
const createSmartphone = async (smartphone, token) => {
  const response = await axios.post('/api/v1/smartphones', smartphone, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

//get all smartphones
const getAllSmartphones = async () => {
  const response = await axios.get('/api/v1/smartphones?sort=-createdAt')
  return response.data.data
}

//get smartphone by id
const getSmartphoneById = async (id, token) => {
  const response = await axios.get(`/api/v1/smartphones/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

//update smartphone
const updateSmartphone = async (id, smartphone, token) => {
  const response = await axios.patch(`/api/v1/smartphones/${id}`, smartphone, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

//delete smartphone
const deleteSmartphone = async (id, token) => {
  const response = await axios.delete(`/api/v1/smartphones/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

const smartphoneService = {
  createSmartphone,
  getAllSmartphones,
  getSmartphoneById,
  updateSmartphone,
  deleteSmartphone,
}

export default smartphoneService
