import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Carousel,
  Modal,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getGadgetById,
  updateGadget,
  deleteGadget,
  resetGadgetUpdate,
  resetGadgetDelete,
  resetGadgetDetails,
} from "../features/gadget/gadgetSlice";
import { toast } from "react-toastify";
import { AiTwotoneDelete, AiFillEdit } from "react-icons/ai";
import Loader from "../components/Loader";

const UpdateGadgetScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const id = params.id;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { userInfo } = useSelector((state) => state.auth);

  //Gadget states
  const [productType, setProductType] = useState("");
  const [gadgetName, setGadgetName] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [images, setImages] = useState([]);
  const [price, setPrice] = useState(0);
  const [stock, setStock] = useState("");
  const [description, setDescription] = useState("");

  const [imagesToDelete, setImagesToDelete] = useState([]);

  const {
    gadget,

    isDetailsLoading,
    isDetailsError,
    isDetailsSuccess,
    detailsErrorMessage,

    isUpdateLoading,
    isUpdateError,
    isUpdateSuccess,
    updateErrorMessage,

    isDeleteLoading,
    isDeleteError,
    isDeleteSuccess,
    deleteErrorMessage,
  } = useSelector((state) => state.gadget);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else if (userInfo.userType !== "admin") {
      navigate("/");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (isDetailsError) {
      toast.error(detailsErrorMessage, { position: "top-center" });
    } else if (isDetailsSuccess) {
      setProductType(gadget.productType);
      setGadgetName(gadget.gadgetName);
      setCoverImage(gadget.coverImage);
      setImages(gadget.images);
      setPrice(gadget.price);
      setStock(gadget.stock);
      setDescription(gadget.description);
    } else {
      dispatch(getGadgetById(id));
    }
  }, [isDetailsError, isDetailsSuccess, detailsErrorMessage]);

  useEffect(() => {
    if (isUpdateError) {
      toast.error(updateErrorMessage, { position: "top-center" });
    } else if (isUpdateSuccess) {
      toast.success("Gadget updated successfully", { position: "top-center" });
      navigate("/buyGadget");
    }
  }, [isUpdateError, isUpdateSuccess, updateErrorMessage]);

  useEffect(() => {
    if (isDeleteError) {
      toast.error(deleteErrorMessage, { position: "top-center" });
    } else if (isDeleteSuccess) {
      toast.success("Gadget deleted successfully", { position: "top-center" });
      navigate("/buyGadget");
    }
  }, [isDeleteError, isDeleteSuccess, deleteErrorMessage]);

  useEffect(() => {
    return () => {
      dispatch(resetGadgetUpdate());
      dispatch(resetGadgetDelete());
      dispatch(resetGadgetDetails());
    };
  }, [dispatch]);

  const updateHandler = () => {
    // If any of the images is to be deleted, remove them from the server
    if(imagesToDelete.length > 0){
      imagesToDelete.forEach(async (image) => {
        await axios.delete(`/api/v1/upload/delete/${image.slice(8)}`);
      });
    }

    const gadgetData = {
      productType,
      gadgetName,
      coverImage,
      images,
      price,
      stock,
      description,
    };
    dispatch(updateGadget({ id, gadgetData }));
  };

  const deleteHandler = () => {
    if (window.confirm("Are you sure you want to delete this gadget?")) {
      dispatch(deleteGadget(id));
    }
  };

  // Cover Image Upload
  const uploadCoverImageFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `/api/v1/upload${coverImage ? `/${coverImage.slice(8)}` : ""}`,
        formData,
        config
      );
      setCoverImage(data);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadImageFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(`/api/v1/upload/`, formData, config);

      setImages([...images, data]);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteImageHandler = async (image) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      setImages(images.filter((img) => img !== image));
      setImagesToDelete([...imagesToDelete, image]);
    }
  }

  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <>
        {isDetailsLoading || isUpdateLoading ? (
          <Loader />
        ) : (
          <Container>
            <Row className="py-5">
              <Card.Text as="h2" className="text-center">
                Update Gadget
              </Card.Text>
            </Row>

            <Form>
              <Row>
                <Col sm={12} md={4} lg={3}>
                  <Card className="mb-4 shadow rounded">
                    <Card.Header as="h4" className="p-3 text-center">
                      Cover Image
                    </Card.Header>
                    <Card.Body className="text-center">
                      <Card.Img
                        cascade
                        className="img-fluid rounded"
                        src={
                          coverImage !== ""
                            ? coverImage
                            : "https://via.placeholder.com/150"
                        }
                        style={{ height: "20vh", objectFit: "cover" }}
                      />
                      <Form.Group controlId="image 1">
                        <Form.Label className="py-3">
                          Upload Cover Image
                        </Form.Label>
                        <Form.Control
                          required
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Cover Image For Gadget"
                          controlId="coverImage"
                          onChange={uploadCoverImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Card className="mb-4 shadow rounded">
                    <Card.Header as="h4" className="p-3 text-center">
                      Other Images
                    </Card.Header>
                    <Card.Body className="text-center">
                      {images.length <= 0 ? (
                        <Card.Img
                          cascade
                          className="img-fluid rounded"
                          src="https://via.placeholder.com/150"
                          style={{ height: "20vh", objectFit: "cover" }}
                        />
                      ) : (
                        <Carousel indicators={false}>
                          {images.map((image, index) => (
                            <Carousel.Item>
                              <img
                                className="d-block w-100"
                                src={image}
                                alt={`Image-${index}`}
                                style={{
                                  maxHeight: "20vh",
                                  objectFit: "cover",
                                }}
                              />
                              {/* Image Delete Button */}
                              <Button
                                variant="outline-danger"
                                className="btn-sm mt-3 rounded"
                                onClick={() => deleteImageHandler(image)}
                              >
                                Delete Image
                              </Button>
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      )}

                      <Form.Group controlId="image 1">
                        <Form.Label className="py-3">
                          Upload Other Images
                        </Form.Label>
                        <Form.Control
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Images"
                          onChange={uploadImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={12} md={6} lg={9}>
                  <Card className="mb-4 shadow rounded">
                    <Card.Header as="h4" className="p-3 text-center">
                      Gadget Information
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="productType">
                            <Form.Label className="small mb-1">
                              Product Type
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              as="select"
                              type="select"
                              placeholder={
                                productType === ""
                                  ? "Product Type is Required"
                                  : "Select Product Type"
                              }
                              value={productType}
                              onChange={(e) => setProductType(e.target.value)}
                            >
                              <option disabled value="">
                                Select Product Type
                              </option>
                              <option value="Earphone">Earphone</option>
                              <option value="Earbuds">Earbuds</option>
                              <option value="Speaker">Speaker</option>
                              <option value="Charger">Charger</option>
                              <option value="Car Charger">Car Charger</option>
                              <option value="Power Bank">Power Bank</option>
                              <option value="Case">Case</option>
                              <option value="Other">Other</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="gadgetName">
                            <Form.Label className="small mb-1">
                              Detailed Name
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              type="text"
                              placeholder={
                                gadgetName === ""
                                  ? "Gadget Name Required"
                                  : "Enter Gadget Name"
                              }
                              value={gadgetName}
                              onChange={(e) => setGadgetName(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="price">
                            <Form.Label className="small mb-1">
                              Price
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              type="number"
                              placeholder={
                                price === ""
                                  ? "Price is Required"
                                  : "Enter Gadget Price"
                              }
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="stock">
                            <Form.Label className="small mb-1">
                              Status
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              as="select"
                              type="select"
                              placeholder={
                                stock === ""
                                  ? "Status is Required"
                                  : "Select Gadget Status"
                              }
                              value={stock}
                              onChange={(e) => setStock(e.target.value)}
                            >
                              <option disabled value="">
                                Select Status
                              </option>
                              <option value="In Stock">In Stock</option>
                              <option value="Out of Stock">Out of Stock</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                          <Form.Group className="mb-3" controlId="description">
                            <Form.Label className="small mb-1">
                              Other Specifications
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              as="textarea"
                              type="textarea"
                              rows="5"
                              placeholder="Other Specifications"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="px-2 mt-3">
                        <Col sm={6} md={6} lg={6}>
                          <Button
                            variant="outline-dark rounded"
                            onClick={deleteHandler}
                          >
                            <AiTwotoneDelete className="mb-1" size={18} />
                            Delete Gadget
                          </Button>
                        </Col>

                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          className="d-flex justify-content-end gap-2 mb-4"
                        >
                          <Button
                            variant="outline-dark rounded"
                            onClick={updateHandler}
                          >
                            <AiFillEdit className="mb-1" size={18} />
                            Update Gadget
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      </>
    )
  );
};

export default UpdateGadgetScreen;
