import React from 'react'
import CategoriesToSellNav from '../components/CategoriesToSellNav'
import SellGadgetsList from '../components/gadgets/SellGadgetsList'

const GadgetsListScreen = () => {
  return (
    <div>
        <CategoriesToSellNav/>
        <SellGadgetsList/>
    </div>
  )
}

export default GadgetsListScreen