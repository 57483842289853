import {React, useEffect} from 'react'
import CategoriesToBuyNav from '../components/CategoriesToBuyNav'
import BuyTablet from '../components/tablets/BuyTablet'

const BuyTabletScreen = () => {
  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <div>
        <CategoriesToBuyNav />
        <BuyTablet />
      </div>
    )
  );
}

export default BuyTabletScreen