import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import reviewsService from "./reviewsService";

const initialState = {
  reviews: [],
  review: null,

  isListError: false,
  isListLoading: false,
  isListSuccess: false,
  listErrorMessage: "",

  isCreateError: false,
  isCreateLoading: false,
  isCreateSuccess: false,
  createErrorMessage: "",

  isDetailsError: false,
  isDetailsLoading: false,
  isDetailsSuccess: false,
  detailsErrorMessage: "",

  isDeleteError: false,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  deleteErrorMessage: "",
};

//create review
export const createReview = createAsyncThunk(
  "review/createReview",
  async (review, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const createdReview = await reviewsService.createReview(review, token);
      return createdReview;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all reviews
export const getAllReviews = createAsyncThunk(
  "review/getAllReviews",
  async (_, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const reviews = await reviewsService.getAllReviews();
      return reviews;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get review by id
export const getReviewById = createAsyncThunk(
  "review/getReviewById",
  async (id, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const review = await reviewsService.getReviewById(id);
      return review;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete review
export const deleteReview = createAsyncThunk(
  "review/deleteReview",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const review = await reviewsService.deleteReview(id, token);
      return review;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    resetCreateReview: (state) => {
      state.isCreateError = false;
      state.isCreateLoading = false;
      state.isCreateSuccess = false;
      state.createErrorMessage = "";
    },
    resetListReview: (state) => {
      state.reviews = [];
      state.isListError = false;
      state.isListLoading = false;
      state.isListSuccess = false;
      state.listErrorMessage = "";
    },
    resetDeleteReview: (state) => {
      state.isDeleteError = false;
      state.isDeleteLoading = false;
      state.isDeleteSuccess = false;
      state.deleteErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllReviews.pending, (state) => {
        state.isListLoading = true;
        state.isListSuccess = false;
        state.isListError = false;
        state.listErrorMessage = "";
      })
      .addCase(getAllReviews.fulfilled, (state, action) => {
        state.isListLoading = false;
        state.isListSuccess = true;
        state.isListError = false;
        state.listErrorMessage = "";
        state.reviews = action.payload;
      })
      .addCase(getAllReviews.rejected, (state, action) => {
        state.isListLoading = false;
        state.isListSuccess = false;
        state.isListError = true;
        state.listErrorMessage = action.payload;
      })
      .addCase(createReview.pending, (state) => {
        state.isCreateLoading = true;
        state.isCreateSuccess = false;
        state.isCreateError = false;
        state.createErrorMessage = "";
      })
      .addCase(createReview.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateSuccess = true;
        state.isCreateError = false;
        state.createErrorMessage = "";
        state.reviews.push(action.payload);
      })
      .addCase(createReview.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = true;
        state.isCreateSuccess = false;
        state.createErrorMessage = action.payload;
      })
      .addCase(getReviewById.pending, (state) => {
        state.isDetailsLoading = true;
        state.isDetailsSuccess = false;
        state.isDetailsError = false;
        state.detailsErrorMessage = "";
      })
      .addCase(getReviewById.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsSuccess = true;
        state.isDetailsError = false;
        state.detailsErrorMessage = "";
        state.review = action.payload;
      })
      .addCase(getReviewById.rejected, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsError = true;
        state.isDetailsSuccess = false;
        state.detailsErrorMessage = action.payload;
      })
      .addCase(deleteReview.pending, (state) => {
        state.isDeleteLoading = true;
        state.isDeleteSuccess = false;
        state.isDeleteError = false;
        state.deleteErrorMessage = "";
      })
      .addCase(deleteReview.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.isDeleteError = false;
        state.deleteErrorMessage = "";
        state.reviews = state.reviews.filter(
          (review) => review._id !== action.payload
        );
        state.review = null;
      })
      .addCase(deleteReview.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.isDeleteSuccess = false;
        state.deleteErrorMessage = action.payload;
      });
  },
});

export const { resetCreateReview, resetListReview, resetDeleteReview } =
  reviewsSlice.actions;

export default reviewsSlice.reducer;
