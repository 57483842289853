import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Carousel,
  ListGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import {
  createTablet,
  resetTabletCreate,
} from "../features/tablet/tabletSlice";
import {
  updateTabletAddRequest,
  resetTabletAddRequestUpdate,
} from "../features/tabletaddrequest/tabletAddRequestSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { AiFillPlusSquare } from "react-icons/ai";
import { MdCancel } from "react-icons/md";

const CreateTabletScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  // Tablet Phone States
  const [tabletBrand, setTabletBrand] = useState("");
  const [tabletModel, setTabletModel] = useState("");
  const [tabletRAM, setTabletRAM] = useState("");
  const [tabletROM, setTabletROM] = useState("");
  const [tabletProcessor, setTabletProcessor] = useState("");
  const [tabletGPU, setTabletGPU] = useState("");
  const [tabletDisplay, setTabletDisplay] = useState("");
  const [tabletFrontCamera, setTabletFrontCamera] = useState("");
  const [tabletBackCamera, setTabletBackCamera] = useState("");
  const [tabletSIM, setTabletSIM] = useState("");
  const [tabletBattery, setTabletBattery] = useState("");

  const [tabletCoverImage, setTabletCoverImage] = useState("");
  const [tabletImages, setTabletImages] = useState([]);

  const [tabletPrice, setTabletPrice] = useState(0);
  const [tabletSellingPrice, setTabletSellingPrice] = useState(0);
  const [tabletStock, setTabletStock] = useState("");
  const [tabletDescription, setTabletDescription] = useState("");

  const [tabletProductType, setTabletProductType] = useState("");

  const [tabletVariants, setTabletVariants] = useState([]);
  const [showVariantFields, setShowVariantFields] = useState(false);

  const { userInfo } = useSelector((state) => state.auth);

  const {
    tablet,
    isCreateError,
    isCreateSuccess,
    isCreateLoading,
    createErrorMessage,
  } = useSelector((state) => state.tablet);

  const {
    tabletAddRequest,
    isUpdateError,
    isUpdateSuccess,
    isUpdateLoading,
    updateErrorMessage,
  } = useSelector((state) => state.tabletAddRequest);

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (userInfo.userType !== "admin") {
      toast.error("You are not authorized to view this page");
      navigate("/");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (isUpdateError) {
      toast.error(updateErrorMessage, { position: "top-center" });
    } else if (isUpdateSuccess) {
      toast.success("Tablet Created Successfully & Status Will be Updated", {
        position: "top-center",
      });
      // navigate("/dashboard");
    }
  }, [isUpdateError, isUpdateSuccess, updateErrorMessage]);

  useEffect(() => {
    if (isCreateSuccess) {
      toast.success("Tablet Added Successfully", {
        position: "top-center",
      });
      navigate("/buyTablet");
    } else if (isCreateError) {
      toast.error(createErrorMessage, { position: "top-center" });
    }
  }, [isCreateError, isCreateSuccess, createErrorMessage, tablet, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetTabletCreate());
      dispatch(resetTabletAddRequestUpdate());
    };
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      tabletBrand !== "" &&
      tabletModel !== "" &&
      // tabletRAM !== "" &&
      // tabletROM !== "" &&
      tabletProcessor !== "" &&
      tabletGPU !== "" &&
      tabletDisplay !== "" &&
      tabletFrontCamera !== "" &&
      tabletBackCamera !== "" &&
      tabletBattery !== "" &&
      tabletSIM !== "" &&
      tabletCoverImage !== "" &&
      tabletProductType !== "" &&
      // tabletPrice !== "" &&
      // tabletStock !== "" &&
      tabletVariants.length !== 0
      // tabletDescription !== ""
    ) {
      dispatch(
        createTablet({
          brand: tabletBrand,
          model: tabletModel,
          // memory: {
          //   RAM: tabletRAM,
          //   ROM: tabletROM,
          // },
          specifications: {
            processor: tabletProcessor,
            GPU: tabletGPU,
            display: tabletDisplay,
            camera: {
              frontCamera: tabletFrontCamera,
              backCamera: tabletBackCamera,
            },
            battery: tabletBattery,
            SIM: tabletSIM,
          },

          variants: tabletVariants,
          coverImage: tabletCoverImage,
          images: tabletImages,
          productType: tabletProductType,
          // price: tabletPrice,
          // sellingPrice: tabletSellingPrice,
          // stock: tabletStock,
          description: tabletDescription,
        })
      );
      if (searchParams.get("requestid")) {
        dispatch(
          updateTabletAddRequest({
            id: searchParams.get("requestid"),
            tabletAddRequestData: {
              status: "Approved",
            },
          })
        );
      }
    } else {
      toast.error("Please fill all the fields & add at least one variant", {
        position: "top-center",
      });
    }
  };

  // Cover Image Upload
  const uploadCoverImageFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `/api/v1/upload${
          tabletCoverImage ? `/${tabletCoverImage.slice(8)}` : ""
        }`,
        formData,
        config
      );
      setTabletCoverImage(data);
    } catch (error) {
      console.error(error);
    }
  };

  // Multiple Image Upload
  const uploadImageFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(`/api/v1/upload/`, formData, config);

      setTabletImages([...tabletImages, data]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <>
        {isCreateLoading ? (
          <Loader />
        ) : (
          <Container>
            <Row className="py-5">
              <Card.Text as="h2" className="text-center">
                Create Tablet
              </Card.Text>
            </Row>

            <Form onSubmit={submitHandler}>
              <Row>
                <Col sm={12} md={4} lg={3}>
                  <Card className="mb-4 shadow rounded">
                    <Card.Header as="h4" className="p-3 text-center">
                      Cover Image
                    </Card.Header>
                    <Card.Body className="text-center">
                      <Card.Img
                        cascade
                        className="img-fluid rounded"
                        src={
                          tabletCoverImage !== ""
                            ? tabletCoverImage
                            : "https://via.placeholder.com/150"
                        }
                        style={{ height: "20vh", objectFit: "cover" }}
                      />
                      <Form.Group controlId="image 1">
                        <Form.Label className="py-3">
                          Upload Cover Image
                        </Form.Label>
                        <Form.Control
                          required
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Cover Image For Tablet"
                          controlId="tabletImage"
                          onChange={uploadCoverImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Card className="mb-4 shadow rounded">
                    <Card.Header as="h4" className="p-3 text-center">
                      Other Images
                    </Card.Header>
                    <Card.Body className="text-center">
                      {tabletImages.length <= 0 ? (
                        <Card.Img
                          cascade
                          className="img-fluid rounded"
                          src="https://via.placeholder.com/150"
                          style={{ height: "20vh", objectFit: "cover" }}
                        />
                      ) : (
                        <Carousel>
                          {tabletImages.map((image, index) => (
                            <Carousel.Item>
                              <img
                                className="d-block w-100"
                                src={image}
                                alt={`Image-${index}`}
                                style={{
                                  maxHeight: "20vh",
                                  objectFit: "cover",
                                }}
                              />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      )}

                      <Form.Group controlId="image 1">
                        <Form.Label className="py-3">
                          Upload New Images
                        </Form.Label>
                        <Form.Control
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Images"
                          onChange={uploadImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={12} md={8} lg={9}>
                  <Card className="mb-4 shadow rounded">
                    <Card.Header as="h4" className="p-3 text-center">
                      Tablet Information
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group className="mb-3" controlId="tabletBrand">
                            <Form.Label className="small mb-1">
                              Tablet Brand
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              type="text"
                              placeholder={
                                tabletBrand === ""
                                  ? "Brand Name is Required"
                                  : "Enter Tablet Brand Name"
                              }
                              value={tabletBrand}
                              onChange={(e) => setTabletBrand(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="tabletModel">
                            <Form.Label className="small mb-1">
                              Tablet Model
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              type="text"
                              placeholder={
                                tabletModel === ""
                                  ? "Model Name is Required"
                                  : "Enter Tablet Model Name"
                              }
                              value={tabletModel}
                              onChange={(e) => setTabletModel(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="tabletProcessor"
                          >
                            <Form.Label className="small mb-1">
                              Processor
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                tabletProcessor === ""
                                  ? "Processor is Required"
                                  : "Enter Tablet Processor"
                              }
                              value={tabletProcessor}
                              onChange={(e) =>
                                setTabletProcessor(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="tabletGPU">
                            <Form.Label className="small mb-1">GPU</Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                tabletGPU === ""
                                  ? "GPU is Required"
                                  : "Enter Tablet GPU"
                              }
                              value={tabletGPU}
                              onChange={(e) => setTabletGPU(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="tabletDisplay"
                          >
                            <Form.Label className="small mb-1">
                              Display
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                tabletDisplay === ""
                                  ? "Display is Required"
                                  : "Enter Tablet Display"
                              }
                              value={tabletDisplay}
                              onChange={(e) => setTabletDisplay(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="tabletBackCamera"
                          >
                            <Form.Label className="small mb-1">
                              Back Camera
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                tabletBackCamera === ""
                                  ? "Back Camera is Required"
                                  : "Enter Tablet Back Camera"
                              }
                              value={tabletBackCamera}
                              onChange={(e) =>
                                setTabletBackCamera(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="tabletFrontCamera"
                          >
                            <Form.Label className="small mb-1">
                              Front Camera
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                tabletFrontCamera === ""
                                  ? "Front Camera is Required"
                                  : "Enter Tablet Front Camera"
                              }
                              value={tabletFrontCamera}
                              onChange={(e) =>
                                setTabletFrontCamera(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="tabletBattery"
                          >
                            <Form.Label className="small mb-1">
                              Battery
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                tabletBattery === ""
                                  ? "Battery is Required"
                                  : "Enter Tablet Battery"
                              }
                              value={tabletBattery}
                              onChange={(e) => setTabletBattery(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="tabletSIM">
                            <Form.Label className="small mb-1">SIM</Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              as="select"
                              type="select"
                              placeholder={
                                tabletSIM === ""
                                  ? "SIM is Required"
                                  : "Enter Tablet SIM"
                              }
                              value={tabletSIM}
                              onChange={(e) => setTabletSIM(e.target.value)}
                            >
                              <option disabled value="">
                                SIM Slots
                              </option>
                              <option value="Dual SIM">Dual SIM</option>
                              <option value="Single SIM">Single SIM</option>
                              <option value="eSIM">eSIM</option>
                              <option value="No SIM">No SIM</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="tabletType">
                            <Form.Label className="small mb-1">
                              Product Type
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              as="select"
                              type="select"
                              placeholder={
                                tabletProductType === ""
                                  ? "Type is Required"
                                  : "Select Tablet Type"
                              }
                              value={tabletProductType}
                              onChange={(e) =>
                                setTabletProductType(e.target.value)
                              }
                            >
                              <option disabled value="">
                                Select Type
                              </option>
                              <option value="New">New</option>
                              <option value="Pre-Owned">Pre-Owned</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        {/* Variants List */}
                        {tabletVariants.length > 0 && (
                          <>
                            <Col lg={12} md={12} sm={12}>
                              Variants
                            </Col>

                            <Col lg={12} md={12} sm={12} className="mb-3">
                              <ListGroup variant="flush">
                                {tabletVariants.map((variant) => (
                                  <ListGroup.Item key={variant.id}>
                                    <Row>
                                      <Col lg={3} md={3} sm={3}>
                                        {`${variant.RAM}GB | ${variant.ROM}GB`}
                                      </Col>
                                      <Col lg={3} md={3} sm={3}>
                                        {`Price: BDT ${variant.price}`}
                                      </Col>
                                      <Col lg={3} md={3} sm={3}>
                                        {`Selling Price: BDT ${variant.sellingPrice}`}
                                      </Col>
                                      {tabletVariants.length > 1 && (
                                        <Col lg={3} md={3} sm={3}>
                                          <Button
                                            variant="danger rounded"
                                            onClick={() => {
                                              setTabletVariants(
                                                tabletVariants.filter(
                                                  (x) =>
                                                    x.price !== variant.price
                                                )
                                              );
                                            }}
                                          >
                                            Delete Variant
                                          </Button>
                                        </Col>
                                      )}
                                    </Row>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </Col>
                          </>
                        )}

                        {/* Button For showing the fields for adding new variant */}
                        {showVariantFields ? (
                          <>
                            <Col lg={6} md={6} sm={6} className="mb-3">
                              <Button
                                variant="outline-success rounded"
                                onClick={() => {
                                  if (
                                    tabletRAM === "" ||
                                    tabletROM === "" ||
                                    tabletPrice === 0 ||
                                    tabletSellingPrice === 0 ||
                                    tabletStock === ""
                                  ) {
                                    toast.error(
                                      "Please input all the information to add a variant",
                                      { position: "top-center" }
                                    );
                                  } else {
                                    setTabletVariants([
                                      ...tabletVariants,
                                      {
                                        RAM: tabletRAM,
                                        ROM: tabletROM,
                                        price: tabletPrice,
                                        sellingPrice: tabletSellingPrice,
                                        stock: tabletStock,
                                      },
                                    ]);
                                    setTabletRAM("");
                                    setTabletROM("");
                                    setTabletPrice(0);
                                    setTabletSellingPrice(0);
                                    setTabletStock("");
                                    setShowVariantFields(false);
                                  }
                                }}
                              >
                                <AiFillPlusSquare
                                  size={18}
                                  className="mb-1 me-2"
                                />
                                Add Variant
                              </Button>
                            </Col>

                            <Col lg={6} md={6} sm={6} className="mb-3">
                              <Button
                                variant="outline-danger rounded"
                                onClick={() => {
                                  setShowVariantFields(false);
                                  setTabletRAM("");
                                  setTabletROM("");
                                  setTabletPrice(0);
                                  setTabletSellingPrice(0);
                                  setTabletStock("");
                                }}
                              >
                                <MdCancel size={18} className="mb-1 me-2" />
                                Cancel Adding Variant
                              </Button>
                            </Col>
                          </>
                        ) : (
                          <Col lg={12} md={12} sm={12} className="mb-3">
                            <Button
                              variant="outline-dark rounded"
                              onClick={() => setShowVariantFields(true)}
                            >
                              <AiFillPlusSquare
                                size={18}
                                className="mb-1 me-2"
                              />
                              Add a new Variant and price
                            </Button>
                          </Col>
                        )}

                        {/* Fields for adding new variant */}
                        {showVariantFields && (
                          <>
                            <Col lg={6} md={6} sm={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="tabletRAM"
                              >
                                <Form.Label className="small mb-1">
                                  RAM
                                </Form.Label>
                                <Form.Control
                                  className=" rounded"
                                  required
                                  type="text"
                                  placeholder={
                                    tabletRAM === ""
                                      ? "RAM is Required"
                                      : "Enter Tablet RAM"
                                  }
                                  value={tabletRAM}
                                  onChange={(e) => setTabletRAM(e.target.value)}
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="tabletROM"
                              >
                                <Form.Label className="small mb-1">
                                  ROM
                                </Form.Label>
                                <Form.Control
                                  className=" rounded"
                                  required
                                  type="text"
                                  placeholder={
                                    tabletROM === ""
                                      ? "ROM is Required"
                                      : "Enter Tablet ROM"
                                  }
                                  value={tabletROM}
                                  onChange={(e) => setTabletROM(e.target.value)}
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="tabletPrice"
                              >
                                <Form.Label className="small mb-1">
                                  Price
                                </Form.Label>
                                <Form.Control
                                  className=" rounded"
                                  required
                                  type="number"
                                  placeholder={
                                    tabletPrice === ""
                                      ? "Price is Required"
                                      : "Enter Tablet Price"
                                  }
                                  value={tabletPrice}
                                  onChange={(e) =>
                                    setTabletPrice(e.target.value)
                                  }
                                ></Form.Control>
                              </Form.Group>
                            </Col>

                            <Col lg={6} md={6} sm={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="tabletSellingPrice"
                              >
                                <Form.Label className="small mb-1">
                                  Estimated Selling Price
                                </Form.Label>
                                <Form.Control
                                  className=" rounded"
                                  required
                                  type="number"
                                  placeholder="Define Estimated Selling Price"
                                  value={tabletSellingPrice}
                                  onChange={(e) =>
                                    setTabletSellingPrice(e.target.value)
                                  }
                                ></Form.Control>
                              </Form.Group>
                            </Col>

                            <Col lg={6} md={6} sm={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="tabletStock"
                              >
                                <Form.Label className="small mb-1">
                                  Status
                                </Form.Label>
                                <Form.Control
                                  className=" rounded"
                                  required
                                  as="select"
                                  type="select"
                                  placeholder={
                                    tabletStock === ""
                                      ? "Status is Required"
                                      : "Select Tablet Status"
                                  }
                                  value={tabletStock}
                                  onChange={(e) =>
                                    setTabletStock(e.target.value)
                                  }
                                >
                                  <option disabled value="">
                                    Select Status
                                  </option>
                                  <option value="In Stock">In Stock</option>
                                  <option value="Out of Stock">
                                    Out of Stock
                                  </option>
                                </Form.Control>
                              </Form.Group>
                            </Col>
                          </>
                        )}

                        <Col lg={12} md={12} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="tabletDescription"
                          >
                            <Form.Label className="small mb-1">
                              Other Details
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              type="text"
                              as="textarea"
                              rows={4}
                              placeholder="Add other details about the tablet"
                              value={tabletDescription}
                              onChange={(e) =>
                                setTabletDescription(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="d-grid px-2">
                        <Button
                          variant="outline-dark"
                          type="submit"
                          className="shadow rounded"
                        >
                          Create Tablet
                        </Button>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      </>
    )
  );
};

export default CreateTabletScreen;
