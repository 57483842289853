import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  // ListGroup,
  // Carousel,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createSmartphoneAddRequest,
  resetSmartphoneAddRequestCreate,
} from "../features/smartphoneaddrequest/smartphoneAddRequestSlice";
import { toast } from "react-toastify";
// import { LinkContainer } from 'react-router-bootstrap'

const CustomizedSmartPhoneAddRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    smartphoneAddRequest,
    isCreateError,
    isCreateLoading,
    isCreateSuccess,
    createErrorMessage,
  } = useSelector((state) => state.smartphoneAddRequest);

  const { userInfo } = useSelector((state) => state.auth);

  //States
  const [smartPhoneBrand, setSmartPhoneBrand] = useState("");
  const [smartPhoneModel, setSmartPhoneModel] = useState("");
  // const [used, setUsed] = useState(0);
  const [comments, setComments] = useState("");

  // const [smartPhoneImage, setSmartPhoneImage] = useState('')

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
      toast.error(
        "You Need to Login to Create a Customized Smartphone Add Request",
        { position: "top-center" }
      );
    }
  });

  useEffect(() => {
    if (isCreateSuccess) {
      toast.success("Smart Phone Add Request Created Successfully", {
        position: "top-center",
      });
      navigate("/userDashboard");
    } else if (isCreateError) {
      toast.error(createErrorMessage, { position: "top-center" });
    }
  }, [isCreateSuccess, isCreateError, createErrorMessage, dispatch, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetSmartphoneAddRequestCreate());
    };
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createSmartphoneAddRequest({
        brand: smartPhoneBrand,
        model: smartPhoneModel,
        // used,
        comments,
        user: userInfo._id,
      })
    );
  };

  return (
    <Container>
      <Row className="py-5">
        <Card.Text as="h2" className="text-center">
          Add Smart Phone Request
        </Card.Text>
      </Row>

      <Form onSubmit={submitHandler}>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <Card className="mb-4 shadow rounded">
              <Card.Header className="text-center">
                Smart Phone Information
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="smartPhoneBrand">
                      <Form.Label className="small mb-1">
                        Smart Phone Brand
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder={
                          smartPhoneBrand === ""
                            ? "Brand Name is Required"
                            : "Enter Smart Phone Brand Name"
                        }
                        value={smartPhoneBrand}
                        onChange={(e) => setSmartPhoneBrand(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <Form.Group className="mb-3" controlId="smartPhoneModel">
                      <Form.Label className="small mb-1">
                        Smart Phone Model
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder={
                          smartPhoneModel === ""
                            ? "Model Name is Required"
                            : "Enter Smart Phone Model Name"
                        }
                        value={smartPhoneModel}
                        onChange={(e) => setSmartPhoneModel(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col lg={12} md={12} sm={12}>
                    <Form.Group className="mb-3" controlId="comments">
                      <Form.Label className="small mb-1">
                        Comments Regarding Smartphone
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows="3"
                        placeholder="Any Comments Regarding Smart Phone and It's Condition"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-grid px-2">
                  <Button
                    variant="outline-dark"
                    type="submit"
                    className="rounded"
                  >
                    Create Smart Phone
                  </Button>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default CustomizedSmartPhoneAddRequest;
