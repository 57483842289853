import axios from "axios";

//create review
const createReview = async (review, token) => {
  // console.log(review)
  const response = await axios.post("/api/v1/reviews", review, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

//get all reviews
const getAllReviews = async () => {
  const response = await axios.get("/api/v1/reviews?sort=-createdAt");
  return response.data.data;
};

//get review by id
const getReviewById = async (id) => {
  const response = await axios.get(`/api/v1/reviews/${id}`);
  return response.data.data;
};

//delete review
const deleteReview = async (id, token) => {
  const response = await axios.delete(`/api/v1/reviews/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

const reviewService = {
  createReview,
  getAllReviews,
  getReviewById,
  deleteReview,
};

export default reviewService;
