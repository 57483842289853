import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  ListGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createTabletAddRequest,
  resetTabletAddRequestCreate,
} from "../features/tabletaddrequest/tabletAddRequestSlice";

const CustomizedTabletAddRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);

  const {
    tabletAddRequest,
    isCreateError,
    isCreateLoading,
    isCreateSuccess,
    createErrorMessage,
  } = useSelector((state) => state.tabletAddRequest);

  //States
  const [tabletBrand, setTabletBrand] = useState("");
  const [tabletModel, setTabletModel] = useState("");
  // const [used, setUsed] = useState(0);
  const [comments, setComments] = useState("");

  // const [tabletImage, setTabletImage] = useState("");

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
      toast.error("You Need To Login To Create a Customized Tablet Add Request", {position : 'top-center'})
    }
  })

  useEffect(() => {
    if (isCreateSuccess) {
      toast.success("Tablet Add Request Created Successfully", {
        position: "top-center",
      });
      navigate("/userDashboard");
    } else if (isCreateError) {
      toast.error(createErrorMessage, { position: "top-center" });
    }
  }, [isCreateSuccess, isCreateError, createErrorMessage, navigate, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetTabletAddRequestCreate());
    };
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createTabletAddRequest({
        brand: tabletBrand,
        model: tabletModel,
        // used : used,
        comments,
        user: userInfo._id,
      })
    );
  };

  return (
    <Container>
      <Row className="py-5">
        <Card.Text as="h2" className="text-center">
          Add Request For Tablet
        </Card.Text>
      </Row>

      <Form onSubmit={submitHandler}>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <Card className="mb-4 shadow rounded">
              <Card.Header className='text-center'>Tablet Information</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="tabletBrand">
                      <Form.Label className="small mb-1">
                        Tablet Brand
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder={
                          tabletBrand === ""
                            ? "Brand Name is Required"
                            : "Enter Tablet Brand Name"
                        }
                        value={tabletBrand}
                        onChange={(e) => setTabletBrand(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <Form.Group className="mb-3" controlId="tabletModel">
                      <Form.Label className="small mb-1">
                        Tablet Model
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder={
                          tabletModel === ""
                            ? "Model Name is Required"
                            : "Enter Tablet Model Name"
                        }
                        value={tabletModel}
                        onChange={(e) => setTabletModel(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col lg={12} md={12} sm={12}>
                    <Form.Group className="mb-3" controlId="comments">
                      <Form.Label className="small mb-1">
                        Comments Regarding Tablet
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows="3"
                        placeholder="Any Comments Regarding Tablet and It's Condition"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-grid px-2">
                  <Button variant="outline-dark" type="submit" className='rounded'>
                    Create Tablet
                  </Button>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default CustomizedTabletAddRequest;
