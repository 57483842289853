import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import CategoriesToBuyNav from "../components/CategoriesToBuyNav";
import GadgetDetails from "../components/gadgets/GadgetDetails";
import {
  getGadgetById,
  deleteGadget,
  resetGadgetDelete,
  resetGadgetDetails,
} from "../features/gadget/gadgetSlice";
import { toast } from "react-toastify";
import { AiTwotoneDelete, AiFillEdit } from "react-icons/ai";

const GadgetDetailsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { userInfo } = useSelector((state) => state.auth);

  const [gadgeT, setGadgeT] = useState(null);

  const {
    gadget,

    isDetailsLoading,
    isDetailsError,
    isDetailsSuccess,
    detailsErrorMessage,

    isDeleteLoading,
    isDeleteError,
    isDeleteSuccess,
    deleteErrorMessage,
  } = useSelector((state) => state.gadget);

  useEffect(() => {
    if (isDetailsError) {
      toast.error(detailsErrorMessage, { position: "top-center" });
    } else if (isDetailsSuccess) {
      setGadgeT(gadget);
    } else {
      dispatch(getGadgetById(id));
    }
  }, [
    dispatch,
    id,
    isDetailsError,
    isDetailsSuccess,
    detailsErrorMessage,
    gadget,
  ]);

  useEffect(() => {
    if (isDeleteError) {
      toast.error(deleteErrorMessage, { position: "top-center" });
    } else if (isDeleteSuccess) {
      toast.success("Gadget deleted successfully", { position: "top-center" });
      navigate("/buyGadget");
    }
  }, [isDeleteError, isDeleteSuccess, deleteErrorMessage]);

  useEffect(() => {
    return () => {
      dispatch(resetGadgetDetails());
      dispatch(resetGadgetDelete());
    };
  }, [dispatch]);

  const deleteHandler = () => {
    if (
      window.confirm(
        "Gadget will be deleted permanently, You sure you want to proceed?"
      )
    ) {
      dispatch(deleteGadget(id));
    }
  };

  return (
    <>
      <Container fluid>
        <CategoriesToBuyNav />
      </Container>

      <Container>
        <GadgetDetails deviceType="gadget" device={gadget} />

        {userInfo && userInfo.userType === "admin" ? (
          <Row>
            <Col
              sm={12}
              md={12}
              lg={12}
              className="d-flex justify-content-end gap-2 mb-4"
            >
              <LinkContainer to={`/updateGadget/${id}`}>
                <Button variant="outline-dark rounded">
                  <AiFillEdit className="mb-1" size={18} />
                  Update Information
                </Button>
              </LinkContainer>

              <Button variant="outline-dark rounded" onClick={deleteHandler}>
                <AiTwotoneDelete className="mb-1" size={18} />
                Delete Gadget
              </Button>
            </Col>
          </Row>
        ) : null}
      </Container>
    </>
  );
};

export default GadgetDetailsScreen;
