import React from 'react'
import {motion} from 'framer-motion'
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const CategoriesToSell = () => {
  return (
    <Container>
      <h1 className="text-center mb-3">Categories to Sell</h1>
      <Row>
        <Col lg={6} md={6} sm={12} className="py-2">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/sellSmartphone">
              <Card className="rounded cursorAll">
                <Card.Img
                  style={{ borderRadius: "12px" }}
                  cascade
                  className="img-fluid p-2"
                  src="/img/buysell/sellphone.png"
                />
                <Card.Body className="d-flex justify-content-center">
                  <Card.Title className="text-center">
                    SELL SMARTPHONES
                  </Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>

        <Col lg={6} md={6} sm={12} className="py-2">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/sellTablets">
              <Card className="rounded cursorAll">
                <Card.Img
                  style={{ borderRadius: "12px" }}
                  cascade
                  className="img-fluid p-2"
                  src="/img/buysell/selltablet.png"
                />
                <Card.Body cascade className="d-flex justify-content-center">
                  <Card.Title className="text-center">SELL TABLET</Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>

        {/* <Col lg={4} md={4} sm={12} className="py-2">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/sellWearable">
              <Card className="rounded cursorAll">
                <Card.Img
                  style={{ borderRadius: "12px" }}
                  cascade
                  className="img-fluid p-2"
                  src="/img/buysell/sellwearable.png"
                />
                <Card.Body className="d-flex justify-content-center">
                  <Card.Title className="text-center">
                    SELL WEARABLES
                  </Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </motion.div>
        </Col> */}
      </Row>
    </Container>
  );
}

export default CategoriesToSell