import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../Loader";
import Message from "../Message";
import { TbCurrencyTaka } from "react-icons/tb";
import {
  getAllGadgets,
  resetGadgetList,
} from "../../features/gadget/gadgetSlice";
import { motion } from "framer-motion";

const BuyGadget = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allGadgets, setAllGadgets] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [productType, setProductType] = useState("");

  const [expandPrice, setExpandPrice] = useState(false);
  const [expandType, setExpandType] = useState(false);

  const [searchGadgets, setSearchGadgets] = useState("");

  const {
    gadgets,
    isListLoading,
    isListError,
    listErrorMessage,
    isListSuccess,
  } = useSelector((state) => state.gadget);

  useEffect(() => {
    if (isListError) {
      // toast.error(listErrorMessage, { position: "top-center" });
    } else if (isListSuccess) {
      const filteredGadgets = gadgets
        .filter((gadget) => {
          if (minPrice === 0) {
            return gadget;
          } else if (gadget.price >= minPrice) {
            return gadget;
          }
        })
        .filter((gadget) => {
          if (maxPrice === 0) {
            return gadget;
          } else if (gadget.price <= maxPrice) {
            return gadget;
          }
        })
        .filter((gadget) => {
          if (productType === "") {
            return gadget;
          } else if (gadget.productType === productType) {
            return gadget;
          }
        })
        .filter((gadget) => {
          if (searchGadgets === "") {
            return gadget;
          } else if (gadget.gadgetName.toLowerCase().includes(searchGadgets)) {
            return gadget;
          }
        });
      setAllGadgets(filteredGadgets);
    } else {
      dispatch(getAllGadgets());
    }
  }, [
    dispatch,
    isListError,
    isListSuccess,
    listErrorMessage,
    gadgets,
    minPrice,
    maxPrice,
    productType,
    searchGadgets,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetGadgetList());
    };
  }, [dispatch]);

  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <Container>
        <h2
          style={{ fontWeight: "bold" }}
          className="h1-responsive my-5 pt-3 text-center"
        >
          Choose Your Preferred Gadget To Buy
        </h2>
        <Row className="mb-5">
          <Col sm={12} md={12} lg={3}>
            <Card className="shadow p-3 rounded">
              <Card.Title as="h3" className="text-dark text-center">
                {" "}
                Filters{" "}
              </Card.Title>
              <Card.Title
                className="py-2 text-dark cursorAll"
                onClick={() => setExpandPrice(!expandPrice)}
              >
                Price
              </Card.Title>
              {expandPrice && (
                <Row className="pb-4">
                  <Col sm={12} md={12} lg={6}>
                    <Form.Control
                      className="rounded"
                      type="text"
                      placeholder="Min Price"
                      value={minPrice}
                      onChange={(e) => setMinPrice(e.target.value)}
                    ></Form.Control>
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <Form.Control
                      className="rounded"
                      type="text"
                      placeholder="Max Price"
                      value={maxPrice}
                      onChange={(e) => setMaxPrice(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
              )}
              <Card.Title
                className="pb-2 text-dark cursorAll"
                onClick={() => setExpandType(!expandType)}
              >
                Product Type
              </Card.Title>
              {expandType && (
                <Form.Control
                  type="select"
                  as="select"
                  placeholder="Select Product Type"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                  className="mb-4 rounded"
                >
                  <option disabled value="">
                    Select Product Type
                  </option>
                  <option value="">All</option>
                  <option value="Earphone">Earphone</option>
                  <option value="Earbuds">Earbuds</option>
                  <option value="Speaker">Speaker</option>
                  <option value="Charger">Charger</option>
                  <option value="Car Charger">Car Charger</option>
                  <option value="Power Bank">Power Bank</option>
                  <option value="Case">Case</option>
                  <option value="Other">Other</option>
                </Form.Control>
              )}
            </Card>
          </Col>
          <Col sm={12} md={12} lg={9}>
            <Card className="shadow p-3 rounded">
              <Row className="mb-4">
                <Col sm={12} md={12} lg={12}>
                  <Form>
                    <Form.Control
                      type="text"
                      placeholder="Search Gadgets"
                      name="Search Gadgets"
                      onChange={(e) => setSearchGadgets(e.target.value)}
                      style={{ border: "1px solid black" }}
                      className="shadow rounded"
                    ></Form.Control>
                  </Form>
                </Col>
              </Row>
              <Row>
                {isListLoading ? (
                  <Loader />
                ) : allGadgets.length <= 0 ? (
                  <h3 variant="info">No Gadgets Found</h3>
                ) : (
                  <>
                    {allGadgets.map((gadget) => (
                      <Col sm={12} md={4} lg={3}>
                        <motion.div
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.9 }}
                        >
                          <LinkContainer to={`/gadgetDetails/${gadget._id}`}>
                            <Card
                              key={gadget._id}
                              className="shadow rounded mb-3 cursorAll"
                            >
                              <Card.Img
                                cascade
                                className="img-fluid mb-3 rounded p-2 buyCard"
                                src={gadget.coverImage}
                              />
                              <Card.Body className="buyCardBody">
                                <Card.Text className="text-center mb-2 px-2">
                                  <h5>{gadget.gadgetName}</h5>
                                </Card.Text>
                                <Card.Text className="text-center text-muted">
                                  <h5>{gadget.stock}</h5>
                                </Card.Text>
                                <Card.Text className="text-center">
                                  <h5>
                                    Price: {gadget.price}
                                    <TbCurrencyTaka
                                      className="mb-2"
                                      size={20}
                                    />
                                  </h5>
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </LinkContainer>
                        </motion.div>
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  );
};

export default BuyGadget;
