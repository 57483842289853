import axios from "axios";

//create gadget
const createGadget = async (gadget, token) => {
    const response = await axios.post("/api/v1/gadgets", gadget, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data.data;
}

//get all gadgets
const getAllGadgets = async () => {
    const response = await axios.get("/api/v1/gadgets?sort=-createdAt")
    return response.data.data;
}

//get gadget by id
const getGadgetById = async (id) => {
    const response = await axios.get(`/api/v1/gadgets/${id}`);
    return response.data.data;
}

//update gadget
const updateGadget = async (id, gadget, token) => {
    const response = await axios.patch(`/api/v1/gadgets/${id}`, gadget, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data.data;
}

//delete gadget
const deleteGadget = async (id, token) => {
    const response = await axios.delete(`/api/v1/gadgets/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data.data;
}

const gadgetService = {
    createGadget,
    getAllGadgets,
    getGadgetById,
    updateGadget,
    deleteGadget,
}

export default gadgetService;