import React from "react";
import { Container, Card } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 10,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 6,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 4,
    slidesToSlide: 1,
  },
};

const TopBrands = ({ deviceType }) => {
  return (
    <Container className="mt-5 py-3 text-center">
      <h1 className="text-center mb-3">Top Selling Brands</h1>
      <Carousel
        responsive={responsive}
        transitionDuration={500}
        autoPlay={deviceType !== "mobile" ? true : false}
        autoPlaySpeed={2000}
        deviceType={deviceType}
        infinite={true}
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
      >
        <Card.Img
          style={{ height: "80px", width: "80px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/oneplus.png"
        />

        <Card.Img
          style={{ height: "80px", width: "80px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/apple.png"
        />

        <Card.Img
          style={{ height: "80px", width: "80px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/oppo.png"
        />

        <Card.Img
          style={{ height: "80px", width: "80px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/vivo.png"
        />

        <Card.Img
          style={{ height: "70px", width: "70px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/xiaomi.png"
        />

        <Card.Img
          style={{ height: "80px", width: "80px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/realme.png"
        />

        <Card.Img
          style={{ height: "80px", width: "80px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/asus.png"
        />

        <Card.Img
          style={{ height: "70px", width: "70px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/google.png"
        />

        <Card.Img
          style={{ height: "80px", width: "80px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/nokia.png"
        />

        <Card.Img
          style={{ height: "80px", width: "80px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/sony.png"
        />

        <Card.Img
          style={{ height: "80px", width: "80px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/lg.png"
        />

        <Card.Img
          style={{ height: "80px", width: "80px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/lenovo.png"
        />
        <Card.Img
          style={{ height: "70px", width: "70px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/huawei.png"
        />

        <Card.Img
          style={{ height: "80px", width: "80px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/infinix.png"
        />

        <Card.Img
          style={{ height: "80px", width: "80px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/nothing.png"
        />

        <Card.Img
          style={{ height: "70px", width: "70px" }}
          cascade
          className="img-fluid cursorAll"
          src="./img/smartphoneBrands/motorola.png"
        />
      </Carousel>
    </Container>
  );
};

export default TopBrands;
