import axios from 'axios'

//create wearable
const createWearable = async (wearable, token) => {
  const response = await axios.post('/api/v1/wearables', wearable, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

//get all wearables
const getAllWearables = async () => {
  const response = await axios.get('/api/v1/wearables?sort=-createdAt')
  return response.data.data
}

//get wearable by id
const getWearableById = async (id) => {
  const response = await axios.get(`/api/v1/wearables/${id}`)
  return response.data.data
}

//update wearable
const updateWearable = async (id, wearable, token) => {
  const response = await axios.patch(`/api/v1/wearables/${id}`, wearable, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

//delete wearable
const deleteWearable = async (id, token) => {
  const response = await axios.delete(`/api/v1/wearables/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

const wearableService = {
  createWearable,
  getAllWearables,
  getWearableById,
  updateWearable,
  deleteWearable,
}

export default wearableService
