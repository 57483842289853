import React,{useEffect} from 'react'
import { Container, Row, ListGroup } from "react-bootstrap";

const TermsAndCondition = () => {
  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <>
        <Container id="about" fluid className="text-center mb-4">
          <Row
            style={{
              backgroundColor: "#4AA24A",
              paddingBottom: "15px",
              paddingTop: "20px",
            }}
          >
            <h2>Terms & Conditions</h2>
          </Row>
          <Container>
            <Row className="mt-3">
              <ListGroup style={{ textAlign: "justify" }}>
                Welcome to Smart Think, a phone and gadget selling shop. By
                using our website and services, you agree to the following terms
                and conditions:
                <ListGroup.Item variant="danger" className="m-2">
                  1. Product Information and Pricing: We strive to provide
                  accurate and up-to-date information about our products and
                  pricing. However, we reserve the right to change product
                  information and pricing at any time without prior notice.
                </ListGroup.Item>
                <ListGroup.Item variant="danger" className="m-2">
                  2. Order Placement and Payment: You may place an order on our
                  website or by contacting our customer service team. We accept
                  payment by credit card, debit card, or PayPal. Payment must be
                  made in full at the time of purchase.
                </ListGroup.Item>
                <ListGroup.Item variant="danger" className="m-2">
                  3. Shipping: We ship to most locations within the United
                  States. Shipping fees will vary depending on the size and
                  weight of the product and the shipping location. We typically
                  ship orders within 1-2 business days of receiving payment.
                </ListGroup.Item>
                <ListGroup.Item variant="danger" className="m-2">
                  4. Returns and Refunds: Please refer to our Return and Refund
                  Policy for information on returns and refunds.
                </ListGroup.Item>
                <ListGroup.Item variant="danger" className="m-2">
                  5. Warranty: All of our products come with a manufacturer's
                  warranty, which covers defects in materials and workmanship.
                  Please refer to the warranty information provided with your
                  product for specific details.
                </ListGroup.Item>
                <ListGroup.Item variant="danger" className="m-2">
                  6. Limitation of Liability: We are not liable for any damages
                  or losses arising from the use or inability to use our
                  products or services. We are also not liable for any indirect,
                  special, or consequential damages, including lost profits or
                  revenue.
                </ListGroup.Item>
                <ListGroup.Item variant="danger" className="m-2">
                  7. Intellectual Property: All content on our website,
                  including text, images, and logos, is the property of Smart
                  Think or its respective owners. You may not use, reproduce, or
                  distribute our content without our express permission.
                </ListGroup.Item>
                <ListGroup.Item variant="danger" className="m-2">
                  8. Privacy: Please refer to our Privacy Policy for information
                  on how we collect, use, and safeguard your personal
                  information.
                </ListGroup.Item>
                <ListGroup.Item variant="danger" className="m-2">
                  9. Governing Law: These terms and conditions are governed by
                  the laws of the state of California. Any disputes arising from
                  or related to these terms and conditions shall be resolved
                  through arbitration in accordance with the rules of the
                  American Arbitration Association.
                </ListGroup.Item>
                <ListGroup.Item variant="danger" className="m-2">
                  10. Changes to Terms and Conditions: We may update these terms
                  and conditions from time to time, and any changes will be
                  posted on our website. By using our services after any changes
                  to these terms and conditions, you agree to the revised terms.
                </ListGroup.Item>
                If you have any questions or concerns about these terms and
                conditions, please contact us at support@smartthink.com.
              </ListGroup>
            </Row>
          </Container>
        </Container>
      </>
    )
  );
}

export default TermsAndCondition