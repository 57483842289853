import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { TbCurrencyTaka } from 'react-icons/tb'

const ProductCard = ({ deviceType, device }) => {
  return (
    <Col lg={2} md={4} xs={6} key={device._id} className='mb-4' >
      <LinkContainer
        to={
          deviceType === "smartPhone"
            ? `/productEvaluation/${device._id}`
            : deviceType === "tablet"
            ? `/productEvaluation/${device._id}`
            : deviceType === "wearable"
            ? `/wearableDetails/${device._id}`
            : ''
        }
        style={{ minHeight: "36vh" }}
      >
      {/* <LinkContainer> */}
        <Card className="rounded d-flex align-items-center shadow p-2">
          <Card.Img cascade className="img-fluid pb-2" src={device.coverImage} />
          <h5 className="text-center py-3">
            {device.brand} {device.model}
          </h5>
          <Card.Text>
            <h5 className='text-center text-muted pb-2'>Price : {device.variants[0]?.sellingPrice}<TbCurrencyTaka className='mb-1' /></h5>
          </Card.Text>
        </Card>
      </LinkContainer>

      {/* <LinkContainer to="">
        <Button className="w-100 mb-5">Generate Price</Button>
      </LinkContainer> */}
    </Col>
  );
};

export default ProductCard;
