import React, {useEffect} from "react";
import CategoriesToBuyNav from "../components/CategoriesToBuyNav";
import BuyWearable from "../components/wearables/BuyWearable";

const BuyWearableScreen = () => {
  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <div>
        <CategoriesToBuyNav />
        <BuyWearable />
      </div>
    )
  );
};

export default BuyWearableScreen;
