import React from 'react'
import Navbar from '../components/Navbar'
import Offers from '../components/Offers'
import CategoriesToSell from '../components/CategoriesToSell'
import CategoriesToBuy from '../components/CategoriesToBuy'
import TopSales from '../components/TopSales'
import TopBrands from '../components/TopBrands'
import NewAvailableProduct from '../components/NewAvailableProduct'
import Reviews from '../components/Reviews'

const HomeScreen = () => {
  return (
    <div className='pb-5'>
        <Navbar/>
        <Offers/>
        <CategoriesToSell/>
        <CategoriesToBuy/>
        {/* <TopSales/> */}
        <NewAvailableProduct/>
        <TopBrands/>
        <Reviews/>
    </div>
  )
}

export default HomeScreen