import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import smartphoneReducer from "../features/smartphone/smartphoneSlice";
import smartphoneAddRequestReducer from "../features/smartphoneaddrequest/smartphoneAddRequestSlice";
import tabletReducer from "../features/tablet/tabletSlice";
import tabletAddRequestReducer from "../features/tabletaddrequest/tabletAddRequestSlice";
import wearableReducer from "../features/wearable/wearableSlice";
import wearableAddRequestReducer from "../features/wearableaddrequest/wearableAddRequestSlice";
import gadgetReducer from "../features/gadget/gadgetSlice";
import reviewsReducers from "../features/reviews/reviewsSlice";
import offerReducers from "../features/offers/offersSlice";
import navbarReducer from "../features/navbars/navbarSlice";
import evaluationReducer from "../features/evaluation/evaluationSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    smartphone: smartphoneReducer,
    smartphoneAddRequest: smartphoneAddRequestReducer,

    tablet: tabletReducer,
    tabletAddRequest: tabletAddRequestReducer,

    wearable: wearableReducer,
    wearableAddRequest: wearableAddRequestReducer,

    gadget: gadgetReducer,

    reviews: reviewsReducers,
    offer: offerReducers,
    navbar: navbarReducer,
    
    evaluation: evaluationReducer
  },
});
