import { React, useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Loader from "../Loader";
import ImageViewer from "react-simple-image-viewer";
import { FaFacebookSquare, FaWhatsappSquare } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { useSelector } from "react-redux";

const SmartPhoneDetails = ({ deviceType, device }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [variantIndex, setVariantIndex] = useState(0);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <Container className="justify-content-center">
        {!device ? (
          <Loader />
        ) : (
          <>
            <Row className="my-4" key={device._id}>
              <h2 className="d-flex justify-content-center mb-5">
                Device Specifications
              </h2>

              <h4 className="d-flex h4-responsive text-center justify-content-center py-2 mb-3">
                {device.brand} {device.model}
              </h4>

              <Col sm={12} md={5} lg={5} className="pt-2">
                <Row>
                  <Card className="border-0 ">
                    <Card.Img
                      cascade
                      className="img-fluid pb-3 rounded"
                      src={device.coverImage}
                    />
                  </Card>
                </Row>

                <Row>
                  {device.images.map((image, index) => (
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <Row className="align-items-center pb-4">
                        <img
                          src={image}
                          onClick={() => openImageViewer(index)}
                          key={index}
                          alt={`Image-${index}`}
                        />
                      </Row>
                    </Col>
                  ))}

                  {isViewerOpen && (
                    <ImageViewer
                      src={device.images}
                      currentIndex={currentImage}
                      disableScroll={false}
                      closeOnClickOutside={true}
                      onClose={closeImageViewer}
                      backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.7)",
                      }}
                    />
                  )}
                </Row>
              </Col>

              <Col sm={12} md={7} lg={7} className="pt-2">
                <Card className="rounded shadow p-2">
                  <Card.Body>
                    <h4>Variants</h4>
                    <Row>
                      {/* Show a clickable for each variant*/}
                      {device.variants?.map((variant, index) => (
                        <Col lg={3} md={3} sm={3}>
                          <Button
                            key={index}
                            variant={
                              variantIndex === index
                                ? "outline-success rounded"
                                : "outline-dark rounded"
                            }
                            onClick={() => setVariantIndex(index)}
                          >
                            {`${variant.RAM}GB | ${variant.ROM}GB`}
                          </Button>
                        </Col>
                      ))}
                      {/* <Col className="py-3">
                        <Card.Title>RAM</Card.Title>
                        <Button variant="primary rounded" className="mx-2">
                          {device.memory.RAM}
                        </Button>
                      </Col>
                      <Col className="py-3">
                        <Card.Title>ROM</Card.Title>
                        <Button variant="primary rounded" className="mx-2">
                          {device.memory.ROM}
                        </Button>
                      </Col> */}
                    </Row>

                    <h4 className="mt-5">Specifications</h4>
                    <Row>
                      <Col sm={12} md={6} lg={6} className="py-3">
                        <Card.Title>Processor</Card.Title>
                        <Card.Text>{device.specifications.processor}</Card.Text>
                      </Col>
                      <Col sm={12} md={6} lg={6} className="py-3">
                        <Card.Title>GPU</Card.Title>
                        <Card.Text>{device.specifications.GPU}</Card.Text>
                      </Col>
                      <Col sm={12} md={6} lg={6} className="py-3">
                        <Card.Title>Display</Card.Title>
                        <Card.Text>{device.specifications.display}</Card.Text>
                      </Col>
                      <Col sm={12} md={6} lg={6} className="py-3">
                        <Card.Title>Battery</Card.Title>
                        <Card.Text>{device.specifications.battery}</Card.Text>
                      </Col>
                      <Col sm={12} md={6} lg={6} className="py-3">
                        <Card.Title>Front Camera</Card.Title>
                        <Card.Text>
                          {device.specifications.camera.frontCamera}
                        </Card.Text>
                      </Col>
                      <Col sm={12} md={6} lg={6} className="py-3">
                        <Card.Title>Back Camera</Card.Title>
                        <Card.Text>
                          {device.specifications.camera.backCamera}
                        </Card.Text>
                      </Col>
                      <Col sm={12} md={6} lg={6} className="py-3">
                        <Card.Title>SIM</Card.Title>
                        <Card.Text>{device.specifications.SIM}</Card.Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6} md={6} sm={12}>
                        <h4 className="mt-5">Status</h4>

                        <Card.Text>
                          {device.variants[variantIndex].stock}
                        </Card.Text>
                      </Col>
                      <Col lg={6} md={6} sm={12}>
                        <h4 className="mt-5">Price</h4>

                        <Card.Text>
                          {device.variants[variantIndex].price} BDT
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className="d-flex justify-content-end flex-column align-items-end mt-5"
                >
                  <Row className="mb-3">
                    <h4 className="">Contact To Buy</h4>
                  </Row>

                  <Row>
                    <h5 className="d-flex gap-4">
                      <a className="social-icon" href="tel:+880 1302-610088">
                        <FiPhoneCall size="28" />
                      </a>

                      <a
                        className="social-icon"
                        target="blank"
                        href="https://www.facebook.com/smartthinkRTC"
                      >
                        <FaFacebookSquare color="#3b5998" size="28" />
                      </a>

                      <a
                        className="social-icon"
                        target="blank"
                        href="https://wa.me/+8801767821115"
                      >
                        <FaWhatsappSquare color="#11ba0b" size="28" />
                      </a>
                    </h5>
                  </Row>
                </Col>
              </Row>
            </Row>

            <Col lg={12} md={12} sm={12}>
              <Card className="mt-3 mb-5 rounded shadow">
                <Card.Body>
                  <h3 className="d-flex justify-content-center pt-2 pb-3">
                    Description
                  </h3>
                  <Card.Text as="p" className="d-flex justify-content-center">
                    {device.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </>
        )}
      </Container>
    )
  );
};

export default SmartPhoneDetails;
