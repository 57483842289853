import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container, Carousel } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllNavbars,
  resetNavbarList,
} from "../features/navbars/navbarSlice";
import { toast } from "react-toastify";

// const banners = [
//   {
//     label: "Banner",
//     value: "Banner1",
//     imageSrc: "/img/Banner/banner1.jpg",
//   },
//   {
//     label: "Banner",
//     value: "Banner2",
//     imageSrc: "/img/Banner/banner2.jpg",
//   },
//   {
//     label: "Banner",
//     value: "Banner3",
//     imageSrc: "/img/Banner/banner3.jpg",
//   },
//   {
//     label: "Banner",
//     value: "Banner4",
//     imageSrc: "/img/Banner/banner4.jpg",
//   },
//   {
//     label: "Banner",
//     value: "Banner5",
//     imageSrc: "/img/Banner/banner5.jpg",
//   },
//   {
//     label: "Banner",
//     value: "Banner6",
//     imageSrc: "/img/Banner/banner6.jpg",
//   },
// ];

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allNavbars, setAllNavbars] = useState([]);

  const {
    navbars,

    isListLoading,
    isListError,
    listErrorMessage,
    isListSuccess,
  } = useSelector((state) => state.navbar);

  useEffect(() => {
    if (isListError) {
      // toast.error(listErrorMessage, { position: "top-center" });
    } else if (isListSuccess) {
      setAllNavbars(navbars);
    } else {
      dispatch(getAllNavbars());
    }
  }, [dispatch, isListError, listErrorMessage, isListSuccess, navbars]);

  useEffect(() => {
    return () => {
      dispatch(resetNavbarList());
    };
  }, [dispatch]);

  return (
    <>
      {isMobile ? (
        <Carousel style={{ margin: "-10px" }}>
          {navbars.map((navbar) => (
            <Carousel.Item>
              <Card>
                <Card.Img
                  cascade
                  src={navbar.image}
                  style={{ height: "240px" }}
                />
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <Container>
          <Row>
            <Col>
              <Carousel>
                {navbars.map((navbar) => (
                  <Carousel.Item>
                    <Card>
                      <Card.Img
                        cascade
                        src={navbar.image}
                        style={{ height: "600px" }}
                      />
                    </Card>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Navbar;
