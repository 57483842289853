import React from "react";
import { motion } from "framer-motion";
import { Container, Row, Col, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const CategoriesToBuyNav = () => {
  return (
    <Container fluid className="py-5 px-5 shadow-sm">
      <Row>
        <Col xs={6} md={3} lg={2}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 1 }}>
            <LinkContainer to="/buySmartPhone">
              <Card className="d-flex align-items-center border-0 cursorAll">
                <Card.Img
                  style={{ height: "100px", width: "100px" }}
                  cascade
                  className="img-fluid"
                  src="/img/buysmartphone.jpg"
                />

                <Card.Body cascade>
                  <Card.Title className="text-center">
                    Buy Smartphone
                  </Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>

        <Col xs={6} md={3} lg={2}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/buyTablet">
              <Card className="d-flex align-items-center border-0 cursorAll">
                <Card.Img
                  style={{ height: "100px", width: "100px" }}
                  cascade
                  className="img-fluid"
                  src="/img/buytablet.jpg"
                />

                <Card.Body cascade>
                  <Card.Title className="text-center">Buy Tablet</Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>

        <Col xs={6} md={3} lg={2}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/buyWearable">
              <Card className="d-flex align-items-center border-0 cursorAll">
                <Card.Img
                  style={{ height: "100px", width: "100px" }}
                  cascade
                  className="img-fluid"
                  src="/img/buywearables.jpg"
                />

                <Card.Body cascade>
                  <Card.Title className="text-center">Buy Wearables</Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>

        <Col xs={6} md={3} lg={2}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/buyGadget">
              <Card className="d-flex align-items-center border-0 cursorAll">
                <Card.Img
                  cascade
                  style={{ height: "100px", width: "100px" }}
                  className="img-fluid"
                  src="/img/buygadgets.jpg"
                />

                <Card.Body cascade>
                  <Card.Title className="text-center">
                    Buy Accessories
                  </Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>

        <Col xs={6} md={6} lg={2}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 1 }}>
            <LinkContainer to="/sellSmartphone">
              <Card className="d-flex align-items-center border-0 cursorAll">
                <Card.Img
                  style={{ height: "100px", width: "100px" }}
                  cascade
                  className="img-fluid"
                  src="/img/sellsmartphone.jpg"
                />

                <Card.Body cascade>
                  <Card.Title className="text-center">
                    Sell Smartphone
                  </Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>

        <Col xs={6} md={6} lg={2}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/sellTablets">
              <Card className="d-flex align-items-center border-0 cursorAll">
                <Card.Img
                  style={{ height: "100px", width: "100px" }}
                  cascade
                  className="img-fluid"
                  src="/img/selltablet.jpg"
                />

                <Card.Body cascade>
                  <Card.Title className="text-center">Sell Tablet</Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export default CategoriesToBuyNav;
