import React from 'react'
import CategoriesToBuyNav from '../components/CategoriesToBuyNav'
import BuyGadget from '../components/gadgets/BuyGadget'

const BuyGadgetScreen = () => {
  return (
    <div>
        <CategoriesToBuyNav />
        <BuyGadget />
    </div>
  )
}

export default BuyGadgetScreen