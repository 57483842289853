import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Carousel,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import {
  createGadget,
  resetGadgetCreate,
} from "../features/gadget/gadgetSlice";

const CreateGadgetScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Gadget states
  const [productType, setProductType] = useState("");
  const [gadgetName, setGadgetName] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [images, setImages] = useState([]);
  const [price, setPrice] = useState(0);
  const [stock, setStock] = useState("");
  const [description, setDescription] = useState("");

  const { userInfo } = useSelector((state) => state.auth);

  const {
    gadget,
    isCreateError,
    isCreateLoading,
    isCreateSuccess,
    createErrorMessage,
  } = useSelector((state) => state.gadget);

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (userInfo.userType !== "admin") {
      toast.error("You are not authorized to view this page");
      navigate("/");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (isCreateSuccess) {
      toast.success("Gadget Created Successfully", { position: "top-center" });
      navigate("/buyGadget");
    } else if (isCreateError) {
      toast.error(createErrorMessage, { position: "top-center" });
    }
    // dispatch(resetGadgetCreate());
  }, [isCreateSuccess, isCreateError, createErrorMessage, gadget, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetGadgetCreate());
    };
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      productType !== "" &&
      gadgetName !== "" &&
      coverImage !== "" &&
      price !== "" &&
      stock !== ""
    ) {
      dispatch(
        createGadget({
          productType,
          gadgetName,
          coverImage,
          images,
          price,
          stock,
          description,
        })
      );
    } else {
      toast.error("Please fill all the fields", { position: "top-center" });
    }
  };

  //Cover Image Upload
  const uploadCoverImageFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        `/api/v1/upload${coverImage ? `/${coverImage.slice(8)}` : ""}`,
        formData,
        config
      );
      setCoverImage(data);
    } catch (error) {
      console.error(error);
    }
  };

  //Multiple Image Upload
  const uploadImageFileHandler = async (e) => {
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("image", files);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(`/api/v1/upload`, formData, config);
      setImages([...images, data]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <>
        {isCreateLoading ? (
          <Loader />
        ) : (
          <Container>
            <Row className="py-5">
              <Card.Text as="h2" className="text-center">
                Create Gadget
              </Card.Text>
            </Row>

            <Form onSubmit={submitHandler}>
              <Row>
                <Col sm={12} md={4} lg={3}>
                  <Card className="mb-4 shadow rounded">
                    <Card.Header as='h4' className='p-3 text-center'>Cover Image</Card.Header>
                    <Card.Body className="text-center">
                      <Card.Img
                        cascade
                        className="img-fluid rounded"
                        src={
                          coverImage !== ""
                            ? coverImage
                            : "https://via.placeholder.com/150"
                        }
                        style={{ height: "20vh", objectFit: "cover" }}
                      />
                      <Form.Group controlId="image 1">
                        <Form.Label className='py-3'>Upload Cover Image</Form.Label>
                        <Form.Control
                          required
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Cover Image For Gadget"
                          controlId="coverImage"
                          onChange={uploadCoverImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Card className="mb-4 shadow rounded">
                    <Card.Header as='h4' className='p-3 text-center'>Other Images</Card.Header>
                    <Card.Body className="text-center">
                      {images.length <= 0 ? (
                        <Card.Img
                          cascade
                          className="img-fluid rounded"
                          src="https://via.placeholder.com/150"
                          style={{ height: "20vh", objectFit: "cover" }}
                        />
                      ) : (
                        <Carousel>
                          {images.map((image, index) => (
                            <Carousel.Item>
                              <img
                                className="d-block w-100"
                                src={image}
                                alt={`Image-${index}`}
                                style={{
                                  maxHeight: "20vh",
                                  objectFit: "cover",
                                }}
                              />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      )}

                      <Form.Group controlId="image 1">
                        <Form.Label className='py-3'>Upload Other Images</Form.Label>
                        <Form.Control
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Images"
                          onChange={uploadImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={6} lg={9}>
                  <Card className="mb-4 shadow rounded">
                    <Card.Header as='h4' className='p-3 text-center'>Gadget Information</Card.Header>
                    <Card.Body>
                      <Row>
                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="productType">
                            <Form.Label className="small mb-1">
                              Product Type
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              as="select"
                              type="select"
                              placeholder={
                                productType === ""
                                  ? "Product Type is Required"
                                  : "Select Product Type"
                              }
                              value={productType}
                              onChange={(e) => setProductType(e.target.value)}
                            >
                              <option disabled value="">
                                Select Product Type
                              </option>
                              <option value="Earphone">Earphone</option>
                              <option value="Earbuds">Earbuds</option>
                              <option value="Speaker">Speaker</option>
                              <option value="Charger">Charger</option>
                              <option value="Car Charger">Car Charger</option>
                              <option value="Power Bank">Power Bank</option>
                              <option value="Case">Case</option>
                              <option value="Other">Other</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="gadgetName">
                            <Form.Label className="small mb-1">
                              Detailed Name
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                gadgetName === ""
                                  ? "Gadget Name Required"
                                  : "Enter Gadget Name"
                              }
                              value={gadgetName}
                              onChange={(e) => setGadgetName(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="price">
                            <Form.Label className="small mb-1">
                              Price
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="number"
                              placeholder={
                                price === ""
                                  ? "Price is Required"
                                  : "Enter Gadget Price"
                              }
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="stock">
                            <Form.Label className="small mb-1">
                              Status
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              as="select"
                              type="select"
                              placeholder={
                                stock === ""
                                  ? "Status is Required"
                                  : "Select Gadget Status"
                              }
                              value={stock}
                              onChange={(e) => setStock(e.target.value)}
                            >
                              <option disabled value="">
                                Select Status
                              </option>
                              <option value="In Stock">In Stock</option>
                              <option value="Out of Stock">Out of Stock</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                          <Form.Group className="mb-3" controlId="description">
                            <Form.Label className="small mb-1">
                              Other Specifications
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              as="textarea"
                              type="textarea"
                              rows="5"
                              placeholder="Other Specifications"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="d-grid px-2">
                        <Button
                          variant="outline-dark"
                          type="submit"
                          className="shadow rounded"
                        >
                          Create Gadget
                        </Button>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      </>
    )
  );
};

export default CreateGadgetScreen;
