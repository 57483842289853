import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const WearableScreen = () => {
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [productType, setProductType] = useState("");
  const [expandPrice, setExpandPrice] = useState(false);
  const [expandType, setExpandType] = useState(false);
  const [expandBrand, setExpandBrand] = useState(false);

  const [searchWearables, setSearchWearables] = useState("");

  return (
    <Container>
      <h2
        style={{ fontWeight: "bold" }}
        className="h1-responsive my-5 pt-3 text-center"
      >
        Wearables
      </h2>
      <Row>
        <Col sm={12} md={12} lg={3}>
          <Card className="shadow p-3 rounded">
            <Card.Title as="h3" className="text-dark text-center">
              {" "}
              Filters{" "}
            </Card.Title>
            <Card.Title
              className="py-2 text-dark"
              style={{ cursor: "pointer" }}
              onClick={() => setExpandPrice(!expandPrice)}
            >
              Price
            </Card.Title>
            {expandPrice && (
              <Row className="pb-4">
                <Col sm={12} md={12} lg={6}>
                  <Form.Control
                    type="text"
                    placeholder="Min Price"
                    value={minPrice}
                    onChange={(e) => setMinPrice(e.target.value)}
                  ></Form.Control>
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <Form.Control
                    type="text"
                    placeholder="Max Price"
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(e.target.value)}
                  ></Form.Control>
                </Col>
              </Row>
            )}
            <Card.Title
              className="pb-2 text-dark"
              style={{ cursor: "pointer" }}
              onClick={() => setExpandType(!expandType)}
            >
              Product Type
            </Card.Title>
            {expandType && (
              <Form.Control
                type="select"
                as="select"
                placeholder="Select Product Type"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                className="mb-4"
              >
                <option value="disabled">Select Product Type</option>
                <option value="new">New</option>
                <option value="used">Pre-Owned</option>
              </Form.Control>
            )}

            <Card.Title
              className="text-dark"
              style={{ cursor: "pointer" }}
              onClick={() => setExpandBrand(!expandBrand)}
            >
              Brands
            </Card.Title>
            {expandBrand && (
              <>
                <Form.Check name="" label="Apple" className="mb-2" />
                <Form.Check name="" label="Xiaomi" className="mb-2" />
                <Form.Check name="" label="Huawei" className="mb-2" />
                <Form.Check name="" label="One Plus" className="mb-2" />
                <Form.Check name="" label="LG" className="mb-2" />
              </>
            )}
          </Card>
        </Col>

        <Col sm={12} md={12} lg={9}>
          <Card className="shadow p-3 rounded">
            <Row className="mb-4">
              <Col sm={12} md={12} lg={6}>
                <Form>
                  <Form.Control
                    type="text"
                    placeholder="Search Smart Watches"
                    value={searchWearables}
                    onChange={(e) => setSearchWearables(e.target.value)}
                    style={{ border: "1px solid black" }}
                    className="shadow rounded"
                  ></Form.Control>
                </Form>
              </Col>
              <Col
                sm={12}
                md={12}
                lg={6}
                className="d-flex justify-content-end"
              >
                <Button className="rounded">Search</Button>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={4} lg={3}>
                <LinkContainer to="">
                  <Card className="shadow rounded">
                    <Card.Img
                      cascade
                      className="img-fluid"
                      src="./img/SmartWatch/amazfit.jpg"
                      // style={{ height: "25vh" }}
                    />

                    <Card.Text className="text-center mb-2">
                      <h3>Apple</h3>
                    </Card.Text>
                    <Card.Text className="text-center text-muted">
                      <h5>In Stock</h5>
                    </Card.Text>
                    <Card.Text className="text-center">
                      <h5>Price : 60000 BDT</h5>
                    </Card.Text>
                  </Card>
                </LinkContainer>
              </Col>
              <Col sm={12} md={4} lg={3}>
                <LinkContainer to="">
                  <Card className="shadow rounded">
                    <Card.Img
                      cascade
                      className="img-fluid"
                      src="./img/SmartWatch/Apple-Watch-1.jpg"
                      // style={{ height: "25vh" }}
                    />

                    <Card.Text className="text-center mb-2">
                      <h3>Apple</h3>
                    </Card.Text>
                    <Card.Text className="text-center text-muted">
                      <h5>In Stock</h5>
                    </Card.Text>
                    <Card.Text className="text-center">
                      <h5>Price : 60000 BDT</h5>
                    </Card.Text>
                  </Card>
                </LinkContainer>
              </Col>
              <Col sm={12} md={4} lg={3}>
                <LinkContainer to="">
                  <Card className="shadow rounded">
                    <Card.Img
                      cascade
                      className="img-fluid"
                      src="./img/SmartWatch/Amazfit-T-Rex-Pro.jpg"
                      // style={{ height: "25vh" }}
                    />

                    <Card.Text className="text-center mb-2">
                      <h3>Apple</h3>
                    </Card.Text>
                    <Card.Text className="text-center text-muted">
                      <h5>In Stock</h5>
                    </Card.Text>
                    <Card.Text className="text-center">
                      <h5>Price : 60000 BDT</h5>
                    </Card.Text>
                  </Card>
                </LinkContainer>
              </Col>
              <Col sm={12} md={4} lg={3}>
                <LinkContainer to="">
                  <Card className="shadow rounded">
                    <Card.Img
                      cascade
                      className="img-fluid"
                      src="./img/SmartWatch/amazfit-t-rex-2.jpg"
                      // style={{ height: "25vh" }}
                    />

                    <Card.Text className="text-center mb-2">
                      <h3>Apple</h3>
                    </Card.Text>
                    <Card.Text className="text-center text-muted">
                      <h5>In Stock</h5>
                    </Card.Text>
                    <Card.Text className="text-center">
                      <h5>Price : 60000 BDT</h5>
                    </Card.Text>
                  </Card>
                </LinkContainer>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WearableScreen;
