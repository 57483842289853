import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import ProductCard from "../components/ProductCard";
import CategoriesToSellNav from "../components/CategoriesToSellNav";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllWearables,
  resetWearableList,
} from "../features/wearable/wearableSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const WearablesListScreen = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const brand = params.brand;

  const [wearaBles, setWearaBles] = useState([]);

  const [searchWearable, setSearchWearable] = useState("");

  const {
    wearables,
    isListLoading,
    isListError,
    listErrorMessage,
    isListSuccess,
  } = useSelector((state) => state.wearable);

  useEffect(() => {
    if (isListError) {
      // toast.error(listErrorMessage, { position: "top-center" });
    } else if (isListSuccess) {
      const filteredWearables = wearables.filter((wearable) => {
        if (searchWearable === "") {
          return wearable;
        } else {
          return (
            wearable.brand.toLowerCase().includes(searchWearable) ||
            wearable.model.toLowerCase().includes(searchWearable)
          );
        }
      });
      setWearaBles(filteredWearables);
    } else {
      dispatch(getAllWearables());
    }
  }, [
    dispatch,
    isListError,
    isListSuccess,
    listErrorMessage,
    searchWearable,
    wearables,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetWearableList());
    };
  }, [dispatch]);

  return (
    <Container>
      <Row className="py-2 mb-5">
        <CategoriesToSellNav />

        <h1 className="mt-5">Sell Your {brand} Wearables</h1>

        <Row className="mb-5 mt-3">
          <Col lg={6} md={6} sm={12}>
            <Form>
              <Form.Control
                type="text"
                placeholder={`Search ${brand} Wearable`}
                value={searchWearable}
                onChange={(e) => setSearchWearable(e.target.value)}
              ></Form.Control>
            </Form>
          </Col>

          {/* <Col lg={6} md={6} sm={12} className="d-flex justify-content-end">
            <Button>Create Customized Add</Button>
          </Col> */}
        </Row>

        {isListLoading ? (
          <Loader />
        ) : wearaBles.filter((wearable) => wearable.brand === brand).length <=
          0 ? (
          <h2 className="my-3">No Wearables Found</h2>
        ) : (
          <>
            {wearaBles
              .filter((wearable) => wearable.brand === brand)
              .map((wearable) => (
                <ProductCard deviceType="wearable" device={wearable} />
              ))}
          </>
        )}
      </Row>

      <Row className="mt-5">
        <Col className="d-flex justify-content-center">
          <h5>Can't Find What You're Willing to Sell?</h5>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col className="d-flex justify-content-center">
          <LinkContainer to="/addWearable">
            <Button className="rounded">Create Customized Add</Button>
          </LinkContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default WearablesListScreen;
