import React,{useEffect} from 'react'
import { Container, Row, Col, Nav, Button, Card, CardGroup, Form } from 'react-bootstrap'


const AboutUs = () => {


  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <div>
        <Container id="about" fluid className="text-center">
          <Row
            style={{
              backgroundColor: "#d9dbda",
              paddingBottom: "15px",
              paddingTop: "20px",
            }}
          >
            <h2>About Us</h2>
          </Row>
          <Row className="mt-3 d-flex justify-content-center">
            <Card className="mt-2" style={{ width: "20rem", border: "none" }}>
              <Card.Img variant="top" src="/img/fullLogo.jpg" />
            </Card>

            <Card style={{ width: "70rem", border: "none" }}>
              <Card.Body>
                <Card.Text style={{ textAlign: "justify" }}>
                  <p>
                    Welcome to Smart Think, your one-stop-shop for all things
                    mobile and gadget-related. At Smart Think, we believe that
                    technology should be accessible to everyone and strive to
                    provide our customers with the latest and greatest
                    smartphones, tablets, and accessories at affordable prices.
                    Our team of experts is passionate about technology and is
                    always on the lookout for the newest and most innovative
                    devices on the market. We carry a wide range of products
                    from leading brands such as Apple, Samsung, Huawei, Google,
                    and more. Whether you're looking for a sleek new iPhone, a
                    powerful gaming phone, or a stylish smartwatch, we've got
                    you covered. At Smart Think, we pride ourselves on providing
                    exceptional customer service. Our knowledgeable staff is
                    always available to answer any questions you may have and
                    help you find the perfect device to fit your needs. We
                    understand that buying a new phone or gadget can be
                    overwhelming, which is why we're here to make the process as
                    simple and stress-free as possible. In addition to our wide
                    selection of products, we also offer repair services for
                    smartphones and tablets. Our skilled technicians are
                    experienced in repairing a variety of issues, including
                    cracked screens, water damage, and battery replacements. We
                    understand how important your device is to you and will work
                    quickly to get it back up and running in no time.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>
        </Container>

        <Container
          fluid
          variant="dark"
          className="h3-responsive my-5 font-weight-bold text-center mt-3"
        >
          <Row
            style={{
              backgroundColor: "#d9dbda",
              paddingBottom: "15px",
              paddingTop: "20px",
            }}
          >
            <h2>Contact Us</h2>
          </Row>

          <CardGroup className="pt-2">
            <Card style={{ color: "white" }}>
              <Card.Body>
                <Card
                  className="mt-2"
                  style={{ border: "none", backgroundColor: "#179609" }}
                >
                  <Card.Body>
                    <Card.Title>Address</Card.Title>
                    <Card.Text>Level 05, Block: C, Shop: 006</Card.Text>
                    <Card.Text>Jamuna Future Park</Card.Text>
                  </Card.Body>
                </Card>

                <Card
                  className="mt-4"
                  style={{ border: "none", backgroundColor: "#990909" }}
                >
                  <Card.Body>
                    <Card.Title>Phone</Card.Title>
                    <Card.Text>+880 1767-821115</Card.Text>
                  </Card.Body>
                </Card>

                <Card
                  className="mt-4"
                  style={{ border: "none", backgroundColor: "#009999" }}
                >
                  <Card.Body>
                    <Card.Title>Email</Card.Title>
                    <Card.Text>info@smartthinkbd.com</Card.Text>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body>
                <iframe
                  src="https://maps.google.com/maps?q=jamuna%20future%20park&t=&z=15&ie=UTF8&iwloc=&output=embed"
                  style={{ width: "100%", height: "100%" }}
                  allowfullscreen
                ></iframe>
              </Card.Body>
            </Card>
          </CardGroup>
        </Container>
      </div>
    )
  );
}

export default AboutUs