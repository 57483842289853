import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import ProductCard from "../components/ProductCard";
import CategoriesToBuyNav from "../components/CategoriesToBuyNav";
import { useDispatch, useSelector } from "react-redux";
import { getAllTablets, resetTabletList } from "../features/tablet/tabletSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const TabletsListScreen = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const params = useParams();
  const brand = params.brand;

  const [tabLets, setTabLets] = useState([]);

  const [searchTablet, setSearchTablet] = useState("");

  const {
    tablets,
    isListLoading,
    isListError,
    listErrorMessage,
    isListSuccess,
  } = useSelector((state) => state.tablet);

  useEffect(() => {
    if (isListError) {
      // toast.error(listErrorMessage, { position: "top-center" });
    } else if (isListSuccess) {
      const filteredTablets = tablets.filter((tablet) => {
        if (searchTablet === "") {
          return tablet;
        } else {
          return (
            tablet.brand.toLowerCase().includes(searchTablet) ||
            tablet.model.toLowerCase().includes(searchTablet)
          );
        }
      });
      setTabLets(filteredTablets);
    } else {
      dispatch(getAllTablets());
    }
  }, [
    dispatch,
    isListError,
    isListSuccess,
    listErrorMessage,
    searchTablet,
    tablets,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetTabletList());
    };
  }, [dispatch]);

  return (
    <>
      <Container fluid>
        <CategoriesToBuyNav />
      </Container>

      <Container>
        <Row className="py-2">
          <h1 className="mt-5 text-center">Sell Your {brand} Tablet</h1>

          <Row className="mb-5 mt-3">
            <Col lg={6} md={6} sm={12}>
              <Form>
                <Form.Control
                  type="text"
                  placeholder={`Search ${brand} Tablet`}
                  value={searchTablet}
                  onChange={(e) => setSearchTablet(e.target.value)}
                ></Form.Control>
              </Form>
            </Col>
          </Row>

          {isListLoading ? (
            <Loader />
          ) : tabLets.filter((tablet) => tablet.brand === brand).length <= 0 ? (
            <h2 className="my-3">No Tablets Listed</h2>
          ) : (
            <>
              {tabLets
                .filter((tablet) => tablet.brand === brand)
                .map((tablet) => (
                  <ProductCard deviceType="tablet" device={tablet} />
                ))}
            </>
          )}
        </Row>

        <Row className="mt-5">
          <Col className="d-flex justify-content-center mb-3">
            <h5 className='text-center'>Can't Find What You're Willing to Sell?</h5>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex justify-content-center">
            <LinkContainer to="/addTablet">
              <Button className="rounded">Create Customized Add</Button>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TabletsListScreen;
