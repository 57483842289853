import React from "react";
import CategoriesToSellNav from "../components/CategoriesToSellNav";
import WearableBrands from "../components/wearables/WearableBrands";

const SellWearableScreeen = () => {
  return (
    <div>
      <CategoriesToSellNav />
      <WearableBrands />
    </div>
  );
};

export default SellWearableScreeen;
