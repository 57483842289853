import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { sendOTP } from '../features/auth/authSlice'
import { toast } from 'react-toastify'

const SendOTPModal = ({
  showSendOTPModal,
  setShowSendOTPModal,
  setGlobalPhone,
}) => {
  const dispatch = useDispatch()

  const [phone, setPhone] = useState('')

  const sendOTPHandler = () => {
    if (phone === '') {
      toast.error('Phone Number is Required', { position: 'top-center' })
      return
    }
    dispatch(sendOTP({ mobile: phone }))
  }

  return (
    <Modal
      // backdrop='static'
      // keyboard={false}
      show={showSendOTPModal}
      onHide={() => setShowSendOTPModal(false)}
      size='lg'
    >
      {/* <Modal.Header>
        <Modal.Title>LOGIN</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <h1 className='text-center mb-5'>Send OTP</h1>
        <p className='text-center'>
          Please Enter your phone number to get OTP for login or signup
        </p>
        <Form className='d-flex justify-content-center'>
          <Row>
            <Col lg={3} md={3} sm={3} className='pe-0'>
              <Form.Group className='mb-3'>
                <Form.Control
                  className='rounded'
                  type='text'
                  placeholder='+88'
                  disabled='true'
                />
              </Form.Group>
            </Col>
            <Col
              lg={9}
              md={9}
              sm={9}
              className='ps-0 d-flex justify-content-start'
            >
              <Form.Group className='mb-3 w-100' controlId='phone'>
                <Form.Control
                  className='rounded'
                  type='text'
                  placeholder={
                    phone === ''
                      ? 'Phone Number is Required'
                      : 'Enter Phone Number'
                  }
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value)
                    setGlobalPhone(e.target.value)
                  }}
                  autoFocus
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outline-primary'
          className='rounded'
          onClick={() => {
            sendOTPHandler()
          }}
        >
          Send Otp
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SendOTPModal
