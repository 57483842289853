import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import ProductCard from '../components/ProductCard'
import CategoriesToSellNav from '../components/CategoriesToSellNav'
import CategoriesToBuyNav from '../components/CategoriesToBuyNav'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllSmartphones,
  resetSmartphoneList,
} from '../features/smartphone/smartphoneSlice'
import { toast } from 'react-toastify'
import Loader from '../components/Loader'

const SmartPhonesListScreen = () => {
  const dispatch = useDispatch()
  // const navigate = useNavigate();

  const params = useParams()
  const brand = params.brand

  const [smartPhones, setSmartPhones] = useState([])

  const [searchSmartPhone, setSearchSmartPhone] = useState('')

  const {
    smartphones,
    isListLoading,
    isListError,
    listErrorMessage,
    isListSuccess,
  } = useSelector((state) => state.smartphone)

  useEffect(() => {
    if (isListError) {
      // toast.error(listErrorMessage, { position: "top-center" });
    } else if (isListSuccess) {
      const filteredSmartphones = smartphones.filter((smartPhone) => {
        if (searchSmartPhone === '') {
          return smartPhone
        } else {
          return (
            smartPhone.brand.toLowerCase().includes(searchSmartPhone) ||
            smartPhone.model.toLowerCase().includes(searchSmartPhone)
          )
        }
      })
      setSmartPhones(filteredSmartphones)
    } else {
      dispatch(getAllSmartphones())
    }
  }, [
    dispatch,
    isListError,
    isListSuccess,
    listErrorMessage,
    searchSmartPhone,
    smartphones,
  ])

  useEffect(() => {
    return () => {
      dispatch(resetSmartphoneList())
    }
  }, [dispatch])

  // const filteredSmartPhones = smartPhones.filter(
  //   (smartPhone) => smartPhone.brand === brand
  // );

  return (
    <>
      <Container fluid>
        <CategoriesToBuyNav />
      </Container>

      <Container>
        <Row className='py-2'>
          <h1 className='mt-5 text-center'>Sell Your {brand} SmartPhone</h1>

          <Row className='mb-5 mt-3'>
            <Col lg={6} md={6} sm={12}>
              <Form>
                <Form.Control
                  type='text'
                  placeholder={`Search ${brand} Smart Phone`}
                  value={searchSmartPhone}
                  onChange={(e) => setSearchSmartPhone(e.target.value)}
                ></Form.Control>
              </Form>
            </Col>
          </Row>

          {isListLoading ? (
            <Loader />
          ) : smartPhones.filter((smartPhone) => smartPhone.brand === brand)
              .length <= 0 ? (
            <h2 className='my-3'>No SmartPhones Listed</h2>
          ) : (
            <>
              {smartPhones
                .filter((smartPhone) => smartPhone.brand === brand)
                .map((smartPhone) => (
                  <ProductCard deviceType='smartPhone' device={smartPhone} />
                ))}
            </>
          )}
        </Row>

        <Row className='mt-5'>
          <Col className='d-flex justify-content-center mb-3'>
            <h5 className='text-center'>
              Can't Find What You're Willing to Sell?
            </h5>
          </Col>
        </Row>
        <Row className='mb-5'>
          <Col className='d-flex justify-content-center'>
            <LinkContainer to='/addSmartPhone'>
              <Button className='rounded'>Create Customized Add</Button>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SmartPhonesListScreen
