import axios from 'axios'

//create smartphone add request
const createSmartphoneAddRequest = async (smartphoneAddRequestData, token) => {
  const response = await axios.post(
    '/api/v1/smartphones/add-request',
    smartphoneAddRequestData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return response.data.data
}

//get all smartphone add requests
const getAllSmartphoneAddRequests = async (token) => {
  const response = await axios.get(
    '/api/v1/smartphones/add-request?sort=-createdAt',
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return response.data.data
}

//get smartphone add request by id
const getSmartphoneAddRequestById = async (id, token) => {
  const response = await axios.get(`/api/v1/smartphones/add-request/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

//update smartphone add request
const updateSmartphoneAddRequest = async (
  id,
  smartphoneAddRequestData,
  token
) => {
  const response = await axios.patch(
    `/api/v1/smartphones/add-request/${id}`,
    smartphoneAddRequestData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return response.data.data
}

//delete smartphone add request
const deleteSmartphoneAddRequest = async (id, token) => {
  const response = await axios.delete(`/api/v1/smartphones/add-request/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

const smartphoneAddRequestService = {
  createSmartphoneAddRequest,
  getAllSmartphoneAddRequests,
  getSmartphoneAddRequestById,
  updateSmartphoneAddRequest,
  deleteSmartphoneAddRequest,
}

export default smartphoneAddRequestService
