import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Card,
  Carousel,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getWearableAddRequestById,
  resetWearableAddRequestDetails,
  deleteWearableAddRequest,
  resetWearableAddRequestDelete,
  updateWearableAddRequest,
  resetWearableAddRequestUpdate,
} from "../features/wearableaddrequest/wearableAddRequestSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const WearablesAddRequestDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;

  const { userInfo } = useSelector((state) => state.auth);

  const {
    wearableAddRequest,

    isDetailsLoading,
    isDetailsError,
    detailsErrorMessage,
    isDetailsSuccess,

    isDeleteLoading,
    isDeleteError,
    deleteErrorMessage,
    isDeleteSuccess,

    isUpdateLoading,
    isUpdateError,
    updateErrorMessage,
    isUpdateSuccess,
  } = useSelector((state) => state.wearableAddRequest);

  // wearable add request states
  const [wearableBrand, setWearableBrand] = useState("");
  const [wearableModel, setWearableModel] = useState("");
  const [comments, setComments] = useState("");

  useEffect(() => {
    if (isDetailsError) {
      toast.error(detailsErrorMessage, { position: "top-center" });
    } else if (isDetailsSuccess) {
      setWearableBrand(wearableAddRequest.brand);
      setWearableModel(wearableAddRequest.model);
      setComments(wearableAddRequest.comments);
    } else {
      dispatch(getWearableAddRequestById(id)); // id = params.id
    }
  }, [isDetailsError, detailsErrorMessage, isDetailsSuccess]);

  useEffect(() => {
    if (isUpdateError) {
      toast.error(updateErrorMessage, { position: "top-center" });
    } else if (isUpdateSuccess) {
      toast.success("Wearable Add Request Updated Successfully", {
        position: "top-center",
      });
      navigate("/dashboard");
    }
  }, [isUpdateError, updateErrorMessage, isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteError) {
      toast.error(deleteErrorMessage, { position: "top-center" });
    } else if (isDeleteSuccess) {
      toast.success("Add Request Deleted Successfully", {
        position: "top-center",
      });
      navigate("/dashboard");
    }
  }, [isDeleteError, deleteErrorMessage, isDeleteSuccess]);

  useEffect(() => {
    return () => {
      dispatch(resetWearableAddRequestDetails());
      dispatch(resetWearableAddRequestDelete());
      dispatch(resetWearableAddRequestUpdate());
    };
  }, [dispatch]);

  // For Deleting Request
  const deleteHandler = () => {
    dispatch(deleteWearableAddRequest(id));
  };

  // For Declining Request
  const declineHandler = () => {
    const wearableAddRequestData = {
      status: "Rejected",
    };
    dispatch(updateWearableAddRequest({ id, wearableAddRequestData }));
  };

  return (
    <>
      {isDetailsLoading || isDeleteLoading || isUpdateLoading ? (
        <Loader />
      ) : (
        <Container>
          <Row className="py-5">
            <Card.Text as="h2" className="text-center">
              Wearables Add Request Details
            </Card.Text>
          </Row>

          {userInfo && userInfo.userType === "admin" ? (
            <>
              <Row className="mb-5 px-3">
                <Card className="rounded p-3">
                  <Card.Text as="h3" className="pb-2 text-center">
                    Tablet Add Requested By
                  </Card.Text>
                  <Card.Text as="h5" className="text-center">
                    {" "}
                    Name : {wearableAddRequest?.user?.firstName}{" "}
                    {wearableAddRequest?.user?.lastName}
                  </Card.Text>
                  <Card.Text as="h5" className="text-center">
                    {" "}
                    Contact : {wearableAddRequest?.user?.mobile}
                  </Card.Text>
                </Card>
              </Row>
            </>
          ) : null}

          <Form>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <Card className="mb-4 shadow rounded">
                  <Card.Header as="h4" className="text-center p-3">Wearables Information</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Group className="mb-3" controlId="wearableBrand">
                          <Form.Label className="small mb-1">Brand</Form.Label>
                          <Form.Control
                            className=" rounded"
                            required
                            type="text"
                            placeholder="Brand Name Must Be Specified"
                            value={wearableBrand}
                            onChange={(e) => setWearableBrand(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                      <Col lg={6} md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="wearableModel">
                          <Form.Label className="small mb-1">Model</Form.Label>
                          <Form.Control
                            className=" rounded"
                            required
                            type="text"
                            placeholder="Model Must Be Specified"
                            value={wearableModel}
                            onChange={(e) => setWearableModel(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                      <Col lg={12} md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="comments">
                          <Form.Label className="small mb-1">
                            Notes Regarding Wearable and It's Condition
                          </Form.Label>
                          <Form.Control
                            className=" rounded"
                            as="textarea"
                            type="textarea"
                            rows="3"
                            placeholder="Any Comments Regarding Wearables and It's Condition"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    {userInfo && userInfo.userType === "admin" ? (
                      <Row>
                        {wearableAddRequest?.status !== "Approved" ? (
                          <Col lg={6} md={6} sm={12}>
                            <LinkContainer
                              to={{
                                pathname: `/createWearables`,
                                search: `?requestid=${id}`,
                              }}
                            >
                              <Button
                                className="shadow rounded"
                                variant="outline-dark"
                                type="submit"
                              >
                                Add Device
                              </Button>
                            </LinkContainer>
                          </Col>
                        ) : (
                          <Col lg={6} md={6} sm={12}>
                            <LinkContainer to="">
                              <Button
                                className="shadow rounded"
                                variant="outline-dark"
                                type="submit"
                                disabled
                              >
                                Add Device
                              </Button>
                            </LinkContainer>
                          </Col>
                        )}

                        <Col
                          lg={6}
                          md={6}
                          sm={12}
                          className="d-flex justify-content-end"
                        >
                          {wearableAddRequest?.status === "Pending" ? (
                            <Button
                              className="shadow rounded"
                              variant="outline-dark"
                              type="submit"
                              onClick={declineHandler}
                            >
                              Decline Add Request
                            </Button>
                          ) : (
                            <Button
                              className="shadow rounded"
                              variant="outline-dark"
                              type="submit"
                              onClick={deleteHandler}
                            >
                              Delete Add Request
                            </Button>
                          )}
                        </Col>
                      </Row>
                    ) : null}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
    </>
  );
};

export default WearablesAddRequestDetails;
