import React, { useState, useEffect } from "react";
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAuthedUser,
  resetUpdateAuthedUser,
} from "../features/auth/authSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Message from "../components/Message";

const UpdateProfileScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    userInfo,

    isUpdateAuthedUserError,
    isUpdateAuthedUserSuccess,
    isUpdateAuthedUserLoading,
    updateAuthedUserErrorMessage,
  } = useSelector((state) => state.auth);

  const [firstName, setFirstName] = useState(userInfo?.firstName);
  const [lastName, setLastName] = useState(userInfo?.lastName);
  const [email, setEmail] = useState(userInfo?.email);

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
      toast.error("You need to login first", { position: "top-center" });
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (isUpdateAuthedUserError) {
      toast.error(updateAuthedUserErrorMessage, { position: "top-center" });
    } else if (isUpdateAuthedUserSuccess) {
      toast.success("Profile Updated Successfully", { position: "top-center" });
      navigate("/");
    }
  }, [
    isUpdateAuthedUserError,
    isUpdateAuthedUserSuccess,
    updateAuthedUserErrorMessage,
    navigate,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateAuthedUser());
    };
  }, [dispatch]);

  const updateInfoHandler = () => {
    const userData = {
      firstName,
      lastName,
      email,
    };
    dispatch(updateAuthedUser({ userData, id: userInfo._id }));
  };

  return (
    <>
      {isUpdateAuthedUserLoading ? (
        <Loader />
      ) : (
        <Container>
          <Row className="py-5">
            <Card.Text as="h2" className="text-center">
              Update Profile
            </Card.Text>
          </Row>

          <Form>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <Card className="shadow rounded">
                  <Card.Header className="text-center p-3" as='h3'>
                    Profile Information
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Group className="mb-3" controlId="firstName">
                          <Form.Label className="mb-1">First Name</Form.Label>
                          <Form.Control
                            className="rounded"
                            required
                            type="text"
                            placeholder={
                              firstName === ""
                                ? "First Name is Required"
                                : "Please Enter Your First Name"
                            }
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                      <Col sm={12} md={6} lg={6}>
                        <Form.Group className="mb-3" controlId="lastName">
                          <Form.Label className="mb-1">Last Name</Form.Label>
                          <Form.Control
                            className="rounded"
                            required
                            type="text"
                            placeholder={
                              lastName === ""
                                ? "Last Name is Required"
                                : "Please Enter Your Last Name"
                            }
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                      <Col sm={12} md={12} lg={12}>
                        <Form.Group className="mb-3" controlId="email">
                          <Form.Label className="mb-1">Email</Form.Label>
                          <Form.Control
                            className="rounded"
                            required
                            type="text"
                            placeholder={
                              setEmail === ""
                                ? "Email is Required"
                                : "Please Enter Your Email"
                            }
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className='px-3'>
                      <Button
                        variant="outline-dark rounded"
                        onClick={updateInfoHandler}
                        type="submit"
                      >
                        Update Information
                      </Button>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
    </>
  );
};

export default UpdateProfileScreen;
