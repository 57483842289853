import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Card, Table, Button } from 'react-bootstrap'
import { FcViewDetails } from 'react-icons/fc'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllSmartphoneAddRequests,
  resetSmartphoneAddRequestList,
} from '../features/smartphoneaddrequest/smartphoneAddRequestSlice'

import {
  getAllTabletAddRequests,
  resetTabletAddRequestList,
} from '../features/tabletaddrequest/tabletAddRequestSlice'

import {
  getAllWearableAddRequests,
  resetWearableAddRequestList,
} from '../features/wearableaddrequest/wearableAddRequestSlice'

import {
  getAllEvaluations,
  resetEvaluationList,
} from '../features/evaluation/evaluationSlice'

import { LinkContainer } from 'react-router-bootstrap'

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from '../components/Loader'

const UserDashboardScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    smartphoneAddRequests,
    isListError: smartphoneListError,
    isListLoading: smartphoneListLoading,
    isListSuccess: smartphoneListSuccess,
    listErrorMessage: smartphoneListErrorMessage,
  } = useSelector((state) => state.smartphoneAddRequest)

  const {
    tabletAddRequests,
    isListError: tabletListError,
    isListLoading: tabletListLoading,
    isListSuccess: tabletListSuccess,
    listErrorMessage: tabletListErrorMessage,
  } = useSelector((state) => state.tabletAddRequest)

  const {
    wearableAddRequests,
    isListError: wearableListError,
    isListLoading: wearableListLoading,
    isListSuccess: wearableListSuccess,
    listErrorMessage: wearableListErrorMessage,
  } = useSelector((state) => state.wearableAddRequest)

  const {
    evaluations,
    isListError: evaluationListError,
    isListLoading: evaluationListLoading,
    isListSuccess: evaluationListSuccess,
    listErrorMessage: evaluationListErrorMessage,
  } = useSelector((state) => state.evaluation)

  const { userInfo } = useSelector((state) => state.auth)

  const [searchProducts, setSearchProducts] = useState('')
  const [queryFilteredAddRequests, setQueryFilteredAddRequest] = useState([]) //For search field
  const [sort, setSort] = useState('')
  const [filteredAddRequests, setFilteredAddRequests] = useState([])

  const [searchProductsEvaluations, setSearchProductsEvaluations] = useState('')
  const [sortEvaluations, setSortEvaluations] = useState('')
  const [queryFilteredEvaluations, setQueryFilteredEvaluations] = useState([])
  const [filteredEvaluations, setFilteredEvaluations] = useState([])

  // const [search, setSearch] = useState("");

  useEffect(() => {
    if (!userInfo) {
      navigate('/')
    } else if (userInfo.userType !== 'customer') {
      toast.error('You are not authorized to access this page!')
      navigate('/')
    }
  }, [userInfo, navigate])

  useEffect(() => {
    if (smartphoneListError) {
      // toast.error(smartphoneListErrorMessage, { position: "top-center" });
    } else if (smartphoneListSuccess) {
      const filter = smartphoneAddRequests
        .filter((addRequest) => addRequest.user._id === userInfo._id)
        .filter(
          (addRequest) =>
            filteredAddRequests.filter(
              (filterAddRequest) => filterAddRequest._id === addRequest._id
            ).length === 0
        )
      setFilteredAddRequests([...filter, ...filteredAddRequests])
    } else {
      dispatch(getAllSmartphoneAddRequests())
    }
  }, [
    smartphoneListError,
    smartphoneListSuccess,
    smartphoneListErrorMessage,
    dispatch,
    smartphoneAddRequests,
    userInfo,
  ])

  useEffect(() => {
    if (tabletListError) {
      // toast.error(tabletListErrorMessage, { position: "top-center" });
    } else if (tabletListSuccess) {
      const filter = tabletAddRequests
        .filter((addRequest) => addRequest.user._id === userInfo._id)
        .filter(
          (addRequest) =>
            filteredAddRequests.filter(
              (filterAddRequest) => filterAddRequest._id === addRequest._id
            ).length === 0
        )
      setFilteredAddRequests([...filter, ...filteredAddRequests])
    } else {
      dispatch(getAllTabletAddRequests())
    }
  }, [
    tabletListError,
    tabletListSuccess,
    tabletListErrorMessage,
    dispatch,
    tabletAddRequests,
    userInfo,
  ])

  useEffect(() => {
    if (wearableListError) {
      // toast.error(wearableListErrorMessage, { position: "top-center" });
    } else if (wearableListSuccess) {
      const filter = wearableAddRequests
        .filter((addRequest) => addRequest.user._id === userInfo._id)
        .filter(
          (addRequest) =>
            filteredAddRequests.filter(
              (filterAddRequest) => filterAddRequest._id === addRequest._id
            ).length === 0
        )
      setFilteredAddRequests([...filter, ...filteredAddRequests])
    } else {
      dispatch(getAllWearableAddRequests())
    }
  }, [
    wearableListError,
    wearableListSuccess,
    wearableListErrorMessage,
    dispatch,
    wearableAddRequests,
    userInfo,
  ])

  useEffect(() => {
    if (evaluationListError) {
      // toast.error(evaluationListErrorMessage, { position: "top-center" });
    } else if (evaluationListSuccess) {
      const filter = evaluations
        .filter((evaluation) => evaluation.user._id === userInfo._id)
        .filter(
          (evaluation) =>
            filteredEvaluations.filter(
              (filterEvaluation) => filterEvaluation._id === evaluation._id
            ).length === 0
        )
      setFilteredEvaluations([...filter, ...filteredEvaluations])
    } else {
      dispatch(getAllEvaluations())
    }
  }, [
    evaluationListError,
    evaluationListSuccess,
    evaluationListErrorMessage,
    dispatch,
    evaluations,
    userInfo,
  ])

  useEffect(() => {
    return () => {
      dispatch(resetSmartphoneAddRequestList())
      dispatch(resetTabletAddRequestList())
      dispatch(resetWearableAddRequestList())
      dispatch(resetEvaluationList())
    }
  }, [dispatch])

  //handle search for brand and model for add requests
  useEffect(() => {
    if (searchProducts && sort !== '') {
      const filter = filteredAddRequests
        .filter(
          (addRequest) =>
            addRequest.brand
              .toLowerCase()
              .includes(searchProducts.toLowerCase()) ||
            addRequest.model
              .toLowerCase()
              .includes(searchProducts.toLowerCase())
        )
        .filter((addRequest) => addRequest.status === sort)
      setQueryFilteredAddRequest(filter)
    } else if (searchProducts && sort === '') {
      const filter = filteredAddRequests.filter(
        (addRequest) =>
          addRequest.brand
            .toLowerCase()
            .includes(searchProducts.toLowerCase()) ||
          addRequest.model.toLowerCase().includes(searchProducts.toLowerCase())
      )
      setQueryFilteredAddRequest(filter)
    } else if (sort && searchProducts === '') {
      const filter = filteredAddRequests.filter((addRequest) => {
        return addRequest.status === sort
      })
      setQueryFilteredAddRequest(filter)
    } else {
      setQueryFilteredAddRequest(filteredAddRequests)
    }
  }, [searchProducts, filteredAddRequests, sort, queryFilteredAddRequests])

  //handle search for brand and model for evaluations
  useEffect(() => {
    if (searchProductsEvaluations && sortEvaluations !== '') {
      const filter = filteredEvaluations
        .filter(
          (evaluation) =>
            evaluation.brand
              .toLowerCase()
              .includes(searchProductsEvaluations.toLowerCase()) ||
            evaluation.model
              .toLowerCase()
              .includes(searchProductsEvaluations.toLowerCase())
        )
        .filter((evaluation) => evaluation.status === sortEvaluations)
      setQueryFilteredEvaluations(filter)
    } else if (searchProductsEvaluations && sortEvaluations === '') {
      const filter = filteredEvaluations.filter(
        (evaluation) =>
          evaluation.brand
            .toLowerCase()
            .includes(searchProductsEvaluations.toLowerCase()) ||
          evaluation.model
            .toLowerCase()
            .includes(searchProductsEvaluations.toLowerCase())
      )
      setQueryFilteredEvaluations(filter)
    } else if (sortEvaluations && searchProductsEvaluations === '') {
      const filter = filteredEvaluations.filter((evaluation) => {
        return evaluation.status === sortEvaluations
      })
      setQueryFilteredEvaluations(filter)
    } else {
      setQueryFilteredEvaluations(filteredEvaluations)
    }
  }, [
    searchProductsEvaluations,
    filteredEvaluations,
    sortEvaluations,
    queryFilteredEvaluations,
  ])

  return (
    <Container className='mb-5 pt-5'>
      <h1 className='text-center py-4'>Dashboard</h1>
      <Row className='mb-3'>
        <Col sm={12} md={6} lg={6} className=''>
          <Form>
            <Form.Group className='mb-2' controlId='searchProducts'>
              <Form.Control
                type='text'
                placeholder='Search Products'
                value={searchProducts}
                onChange={(e) => setSearchProducts(e.target.value)}
                className='shadow rounded'
              ></Form.Control>
            </Form.Group>
          </Form>
        </Col>
        <Col sm={12} md={6} lg={6}>
          <Form.Group className='mb-2' controlId='sort'>
            <Form.Control
              as='select'
              type='select'
              placeholder='Sort By'
              value={sort}
              onChange={(e) => setSort(e.target.value)}
              className='shadow rounded'
            >
              <option disabled value=''>
                Sort By
              </option>
              <option value=''>All</option>
              <option value='Pending'>Pending</option>
              <option value='Approved'>Approved</option>
              <option value='Rejected'>Rejected</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {smartphoneListLoading || tabletListLoading || wearableListLoading ? (
          <Loader />
        ) : filteredAddRequests.length <= 0 ? (
          <h2 className='text-center mt-5'>
            You Don't Have Any Existing Add Requests
          </h2>
        ) : (
          <>
            <Col>
              <Card className='shadow rounded'>
                <Card.Header className='text-center py-3' as='h3'>
                  Requested Products
                </Card.Header>

                <Table
                  bordered
                  hover
                  responsive
                  className='table-sm overflow-auto'
                >
                  <thead style={{ fontWeight: 'bold' }}>
                    <tr style={{ fontWeight: 'bold' }}>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Details</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {queryFilteredAddRequests.map((addRequest) => (
                        <tr key={addRequest._id}>
                          <td>{addRequest.brand}</td>
                          <td>{addRequest.model}</td>
                          <td>
                            <LinkContainer
                              to={
                                addRequest.deviceType === 'SmartPhone'
                                  ? `/smartphoneAddRequestDetails/${addRequest._id}`
                                  : addRequest.deviceType === 'Tablet'
                                  ? `/tabletAddRequestDetails/${addRequest._id}`
                                  : `/wearablesAddRequestDetails/${addRequest._id}`
                              }
                            >
                              <Button
                                variant='dark'
                                size='sm'
                                className='rounded'
                              >
                                <FcViewDetails size='20' />
                              </Button>
                            </LinkContainer>
                          </td>
                          <td>{addRequest.status}</td>
                        </tr>
                      ))}
                    </>
                  </tbody>
                </Table>
              </Card>
            </Col>
          </>
        )}
      </Row>

      {/* Evaluation Table */}
      <Row className='my-5'>
        {evaluationListLoading ? (
          <Loader />
        ) : filteredEvaluations.length <= 0 ? (
          <h2 className='text-center'>
            You Don't Have Any Existing Sell Request
          </h2>
        ) : (
          <>
            <Col>
              <Card className='shadow rounded'>
                <Card.Header className='text-center py-3' as='h3'>
                  Device Sell Requests
                </Card.Header>

                <Table
                  bordered
                  hover
                  responsive
                  className='table-sm overflow-auto'
                >
                  <thead style={{ fontWeight: 'bold' }}>
                    <tr>
                      <th>Device Type</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Details</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {queryFilteredEvaluations.map((evaluation) => (
                      <tr key={evaluation._id}>
                        <td>{evaluation.deviceType}</td>
                        <td>
                          {evaluation.deviceType === 'SmartPhone'
                            ? evaluation.smartPhone?.brand
                            : evaluation.deviceType === 'Tablet'
                            ? evaluation.tablet?.brand
                            : null}
                        </td>
                        <td>
                          {evaluation.deviceType === 'SmartPhone'
                            ? evaluation.smartPhone?.model
                            : evaluation.deviceType === 'Tablet'
                            ? evaluation.tablet?.model
                            : null}
                        </td>
                        <td>
                          <LinkContainer
                            to={`/evaluationDetails/${evaluation._id}`}
                          >
                            <Button
                              className='rounded'
                              variant='dark'
                              size='sm'
                            >
                              <FcViewDetails size='20' />
                            </Button>
                          </LinkContainer>
                        </td>
                        <td>{evaluation.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </Container>
  )
}

export default UserDashboardScreen
