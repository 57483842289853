import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Carousel,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createWearable,
  resetWearableCreate,
} from "../features/wearable/wearableSlice";
import {
  updateWearableAddRequest,
  resetWearableAddRequestUpdate,
} from "../features/wearableaddrequest/wearableAddRequestSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const CreateWearableScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [wearableBrand, setWearableBrand] = useState("");
  const [wearableModel, setWearableModel] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [wearablePrice, setWearablePrice] = useState(0);
  const [wearableStock, setWearableStock] = useState("");
  const [wearableProductType, setWearableProductType] = useState("");

  const [wearableCoverImage, setWearableCoverImage] = useState("");
  const [wearableImages, setWearableImages] = useState([]);

  const { userInfo } = useSelector((state) => state.auth);

  const {
    wearable,
    isCreateError,
    isCreateSuccess,
    isCreateLoading,
    createErrorMessage,
  } = useSelector((state) => state.wearable);

  const {
    wearableAddRequest,
    isUpdateError,
    isUpdateSuccess,
    isUpdateLoading,
    updateErrorMessage,
  } = useSelector((state) => state.wearableAddRequest);

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (userInfo.userType !== "admin") {
      toast.error("You are not authorized to view this page");
      navigate("/");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (isUpdateError) {
      toast.error(updateErrorMessage, { position: "top-center" });
    } else if (isUpdateSuccess) {
      toast.success(
        "Wearable Add Request Updated Successfully & Status Will be Updated",
        {
          position: "top-center",
        }
      );
      // navigate("/dashboard");
    }
  }, [isUpdateError, isUpdateSuccess, updateErrorMessage]);

  useEffect(() => {
    if (isCreateSuccess) {
      toast.success("Wearable Added Successfully", {
        position: "top-center",
      });
      navigate("/buyWearable");
    } else if (isCreateError) {
      toast.error(createErrorMessage, { position: "top-center" });
    }
  }, [isCreateError, isCreateSuccess, createErrorMessage, wearable, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetWearableCreate());
      dispatch(resetWearableAddRequestUpdate());
    };
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      wearableBrand !== "" &&
      wearableModel !== "" &&
      specifications !== "" &&
      wearablePrice !== 0 &&
      wearableStock !== "" &&
      wearableProductType !== "" &&
      wearableCoverImage !== "" &&
      wearableImages !== ""
    ) {
      dispatch(
        createWearable({
          brand: wearableBrand,
          model: wearableModel,
          specifications: specifications,
          productType: wearableProductType,
          price: wearablePrice,
          stock: wearableStock,
          coverImage: wearableCoverImage,
          images: wearableImages,
        })
      );
      if (searchParams.get("requestid")) {
        dispatch(
          updateWearableAddRequest({
            id: searchParams.get("requestid"),
            wearableAddRequestData: {
              status: "Approved",
            },
          })
        );
      }
    } else {
      toast.error("Please Fill All The Fields", {
        position: "top-center",
      });
    }
  };

  // Cover Image Upload
  const uploadCoverImageFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `/api/v1/upload${
          wearableCoverImage ? `/${wearableCoverImage.slice(8)}` : ""
        }`,
        formData,
        config
      );
      setWearableCoverImage(data);
    } catch (error) {
      console.error(error);
    }
  };

  // Multiple Image Upload
  const uploadImageFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(`/api/v1/upload/`, formData, config);

      setWearableImages([...wearableImages, data]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <>
        {isCreateLoading ? (
          <Loader />
        ) : (
          <Container>
            <Row className="py-5">
              <Card.Text as="h2" className="text-center">
                Create Wearables
              </Card.Text>
            </Row>

            <Form onSubmit={submitHandler}>
              <Row>
                <Col sm={12} md={4} lg={3}>
                  <Card className="mb-4 shadow rounded">
                    <Card.Header as='h4' className='p-3 text-center'>Cover Image</Card.Header>
                    <Card.Body className="text-center">
                      <Card.Img
                        cascade
                        className="img-fluid rounded"
                        src={
                          wearableCoverImage !== ""
                            ? wearableCoverImage
                            : "https://via.placeholder.com/150"
                        }
                        style={{ height: "20vh", objectFit: "cover" }}
                      />
                      <Form.Group controlId="image 1">
                        <Form.Label className='py-3'>Upload Cover Image</Form.Label>
                        <Form.Control
                          required
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Cover Image For Wearables"
                          controlId="wearableCoverImage"
                          onChange={uploadCoverImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Card className="mb-4 shadow rounded">
                    <Card.Header as='h4' className='p-3 text-center'>Other Images</Card.Header>
                    <Card.Body className="text-center">
                      {wearableImages.length <= 0 ? (
                        <Card.Img
                          cascade
                          className="img-fluid rounded"
                          src="https://via.placeholder.com/150"
                          style={{ height: "20vh", objectFit: "cover" }}
                        />
                      ) : (
                        <Carousel>
                          {wearableImages.map((image, index) => (
                            <Carousel.Item>
                              <img
                                className="d-block w-100"
                                src={image}
                                alt={`Image-${index}`}
                                style={{
                                  maxHeight: "20vh",
                                  objectFit: "cover",
                                }}
                              />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      )}

                      <Form.Group controlId="image 1">
                        <Form.Label className='py-3'>Upload Other Images</Form.Label>
                        <Form.Control
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Images"
                          onChange={uploadImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={12} md={8} lg={9}>
                  <Card className="mb-4 shadow rounded">
                    <Card.Header as='h4' className='p-3 text-center'>Wearable Details</Card.Header>
                    <Card.Body>
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="wearableBrand"
                          >
                            <Form.Label className="small mb-1">
                              Wearable Brand
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                wearableBrand === ""
                                  ? "Enter Wearable Brand"
                                  : "Brand Name is Required"
                              }
                              value={wearableBrand}
                              onChange={(e) => setWearableBrand(e.target.value)}
                            />
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="wearableModel"
                          >
                            <Form.Label className="small mb-1">
                              Wearable Model
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="text"
                              placeholder={
                                wearableModel === ""
                                  ? "Model Name is Required"
                                  : "Enter Wearable Model Name"
                              }
                              value={wearableModel}
                              onChange={(e) => setWearableModel(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="specifications"
                          >
                            <Form.Label className="small mb-1">
                              Other Specifications
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              as="textarea"
                              type="textarea"
                              rows="5"
                              placeholder="Other Specifications"
                              value={specifications}
                              onChange={(e) =>
                                setSpecifications(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="wearablePrice"
                          >
                            <Form.Label className="small mb-1">
                              Price
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              type="number"
                              placeholder={
                                wearablePrice === ""
                                  ? "Price is Required"
                                  : "Enter Wearable Price"
                              }
                              value={wearablePrice}
                              onChange={(e) => setWearablePrice(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="wearableType">
                            <Form.Label className="small mb-1">
                              Product Type
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              as="select"
                              type="select"
                              placeholder={
                                wearableProductType === ""
                                  ? "Type is Required"
                                  : "Select Wearable Type"
                              }
                              value={wearableProductType}
                              onChange={(e) =>
                                setWearableProductType(e.target.value)
                              }
                            >
                              <option disabled value="">
                                Select Type
                              </option>
                              <option value="New">New</option>
                              <option value="Pre-Owned">Pre-Owned</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="wearableStock"
                          >
                            <Form.Label className="small mb-1">
                              Status
                            </Form.Label>
                            <Form.Control
                              className=" rounded"
                              required
                              as="select"
                              type="select"
                              placeholder={
                                wearableStock === ""
                                  ? "Status is Required"
                                  : "Select Wearable Status"
                              }
                              value={wearableStock}
                              onChange={(e) => setWearableStock(e.target.value)}
                            >
                              <option disabled value="">
                                Select Status
                              </option>
                              <option value="In Stock">In Stock</option>
                              <option value="Out of Stock">Out of Stock</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="d-grid px-2">
                        <Button
                          variant="outline-dark"
                          type="submit"
                          className="shadow rounded"
                        >
                          Create Wearable
                        </Button>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      </>
    )
  );
};

export default CreateWearableScreen;
