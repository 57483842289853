import axios from "axios";

// Signup amar apatoto dorkar nai
// const signup = async () => {
//   console.log("signup");
// };

//signin user locally
// const signinLocal = async (userData) => {
//   const response = await axios.post("/api/v1/users/signin", userData);
//   if (response.data.status === "success") {
//     localStorage.setItem("userInfo", JSON.stringify(response.data.data));
//   }
//   return response.data.data;
// };

// Send OTP
const sendOTP = async (userData) => {
  const response = await axios.post("/api/v1/users/sendOTP", userData);
  return response.data.data;
};

// Verify OTP
const verifyOTP = async (userData) => {
  const response = await axios.post("/api/v1/users/verifyOTP", userData);

  if (response.data.status === "success") {
    localStorage.setItem("userInfo", JSON.stringify(response.data.data));
  }
  return response.data.data;
};

// Update New User Information
const updateNewUser = async (userData, token) => {
  const response = await axios.patch("/api/v1/users/updateMe", userData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.data.status === "success") {
    localStorage.setItem("userInfo", JSON.stringify(response.data.data));
  }
  return response.data.data;
};

// Dorkar nai, cause user login korar ektai way (OTP Login), and admin user er kono info change korte parbe na
// const getAuthedUser = async (id) => {

// }

// Update User Information
const updateAuthedUser = async (id, userData) => {
  const response = await axios.patch(`/api/v1/users/auth/${id}`, userData);
  if (response.data.status === "success") {
    localStorage.setItem("userInfo", JSON.stringify(response.data.data));
  }
  return response.data.data;
}

const authService = {
  // signup,
  // signinLocal,
  sendOTP,
  verifyOTP,
  updateNewUser,
  updateAuthedUser,
};

export default authService;
