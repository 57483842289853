import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Carousel,
  Modal,
  ListGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import {
  updateTablet,
  getTabletById,
  deleteTablet,
  resetTabletUpdate,
  resetTabletDelete,
  resetTabletDetails,
} from "../features/tablet/tabletSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { AiTwotoneDelete, AiFillEdit, AiFillPlusSquare } from "react-icons/ai";
import { MdCancel } from "react-icons/md";

const UpdateTabletScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { userInfo } = useSelector((state) => state.auth);

  const {
    tablet,

    isDetailsLoading,
    isDetailsError,
    isDetailsSuccess,
    detailsErrorMessage,

    isUpdateLoading,
    isUpdateError,
    isUpdateSuccess,
    updateErrorMessage,

    isDeleteLoading,
    isDeleteError,
    isDeleteSuccess,
    deleteErrorMessage,
  } = useSelector((state) => state.tablet);

  // Tablet States
  const [tabletBrand, setTabletBrand] = useState("");
  const [tabletModel, setTabletModel] = useState("");
  const [tabletRAM, setTabletRAM] = useState("");
  const [tabletROM, setTabletROM] = useState("");
  const [tabletProcessor, setTabletProcessor] = useState("");
  const [tabletGPU, setTabletGPU] = useState("");
  const [tabletDisplay, setTabletDisplay] = useState("");
  const [tabletFrontCamera, setTabletFrontCamera] = useState("");
  const [tabletBackCamera, setTabletBackCamera] = useState("");
  const [tabletSIM, setTabletSIM] = useState("");
  const [tabletBattery, setTabletBattery] = useState("");
  const [tabletCoverImage, setTabletCoverImage] = useState("");
  const [tabletImages, setTabletImages] = useState([]);
  const [tabletPrice, setTabletPrice] = useState(0);
  const [tabletSellingPrice, setTabletSellingPrice] = useState(0);
  const [tabletStock, setTabletStock] = useState("");
  const [tabletDescription, setTabletDescription] = useState("");
  const [tabletProductType, setTabletProductType] = useState("");

  const [showVariantFields, setShowVariantFields] = useState(false); // Show variant fields for mobiles and tablets
  const [tabletVariants, setTabletVariants] = useState([]); // Variants for tablets

  const [imagesToDelete, setImagesToDelete] = useState([]); // Images to be deleted from the server

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else if (userInfo.userType !== "admin") {
      navigate("/");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (isDetailsError) {
      toast.error(detailsErrorMessage, { position: "top-center" });
    } else if (isDetailsSuccess) {
      setTabletBrand(tablet.brand);
      setTabletModel(tablet.model);
      // setTabletRAM(tablet.memory.RAM);
      // setTabletROM(tablet.memory.ROM);
      setTabletProcessor(tablet.specifications.processor);
      setTabletGPU(tablet.specifications.GPU);
      setTabletDisplay(tablet.specifications.display);
      setTabletFrontCamera(tablet.specifications.camera.frontCamera);
      setTabletBackCamera(tablet.specifications.camera.backCamera);
      setTabletBattery(tablet.specifications.battery);
      setTabletSIM(tablet.specifications.SIM);
      setTabletCoverImage(tablet.coverImage);
      setTabletImages(tablet.images);
      // setTabletPrice(tablet.price);
      // setTabletSellingPrice(tablet.sellingPrice);
      setTabletProductType(tablet.productType);
      setTabletVariants(tablet.variants);
      // setTabletStock(tablet.stock);
      setTabletDescription(tablet.description);
    } else {
      dispatch(getTabletById(params.id));
    }
  }, [isDetailsError, detailsErrorMessage, isDetailsSuccess]);

  useEffect(() => {
    if (isUpdateError) {
      toast.error(updateErrorMessage, { position: "top-center" });
    } else if (isUpdateSuccess) {
      toast.success("Tablet updated successfully", {
        position: "top-center",
      });
      navigate("/buyTablet");
    }
  }, [isUpdateError, updateErrorMessage, isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteError) {
      toast.error(deleteErrorMessage, { position: "top-center" });
    } else if (isDeleteSuccess) {
      toast.success("Tablet deleted successfully", {
        position: "top-center",
      });
      navigate("/buyTablet");
    }
  }, [isDeleteError, deleteErrorMessage, isDeleteSuccess]);

  useEffect(() => {
    return () => {
      dispatch(resetTabletDetails());
      dispatch(resetTabletUpdate());
      dispatch(resetTabletDelete());
    };
  }, [dispatch]);

  const updateHandler = () => {
    //If any images are set to delete, remove them from the server
    if (imagesToDelete.length > 0) {
      imagesToDelete.forEach(async (image) => {
        await axios.post(`/api/v1/upload/delete/${image.slice(8)}`);
      });
    }

    const tabletData = {
      brand: tabletBrand,
      model: tabletModel,
      variants: tabletVariants,
      specifications: {
        processor: tabletProcessor,
        GPU: tabletGPU,
        display: tabletDisplay,
        camera: {
          frontCamera: tabletFrontCamera,
          backCamera: tabletBackCamera,
        },
        battery: tabletBattery,
        SIM: tabletSIM,
      },

      coverImage: tabletCoverImage,
      images: tabletImages,
      productType: tabletProductType,
      // price: tabletPrice,
      // sellingPrice: tabletSellingPrice,
      // stock: tabletStock,
      description: tabletDescription,
    };
    dispatch(updateTablet({ id: params.id, tabletData }));
  };

  const deleteHandler = () => {
    if (window.confirm("Are you sure you want to delete this tablet?")) {
      dispatch(deleteTablet(params.id));
    }
    // dispatch(deleteTablet(params.id));
  };

  // Cover Image Upload
  const uploadCoverImageFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `/api/v1/upload${
          tabletCoverImage ? `/${tabletCoverImage.slice(8)}` : ""
        }`,
        formData,
        config
      );
      setTabletCoverImage(data);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadImageFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(`/api/v1/upload/`, formData, config);

      setTabletImages([...tabletImages, data]);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteImageHandler = async (image) => {
    if (
      window.confirm(
        "Are you sure you want to delete this image? This action cannot be undone!"
      )
    ) {
      setTabletImages(tabletImages.filter((img) => img !== image));

      setImagesToDelete([...imagesToDelete, image]);
    }
  };
  console.log(tabletImages);

  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <>
        {isDetailsLoading || isUpdateLoading ? (
          <Loader />
        ) : (
          <Container>
            <Row className="py-5">
              <Card.Text as="h2" className="text-center">
                Update Tablet
              </Card.Text>
            </Row>

            <Form>
              <Row>
                <Col sm={12} md={4} lg={3}>
                  <Card className="mb-4 shadow rounded">
                    <Card.Header as="h4" className="p-3 text-center">
                      Cover Image
                    </Card.Header>
                    <Card.Body className="text-center">
                      <Card.Img
                        cascade
                        className="img-fluid rounded"
                        src={
                          tabletCoverImage !== ""
                            ? tabletCoverImage
                            : "https://via.placeholder.com/150"
                        }
                        style={{ height: "20vh", objectFit: "cover" }}
                      />
                      <Form.Group controlId="image 1">
                        <Form.Label className="py-3">
                          Upload Cover Image
                        </Form.Label>
                        <Form.Control
                          required
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Cover Image For Tablet"
                          controlId="tabletImage"
                          onChange={uploadCoverImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Card className="mb-4 shadow rounded">
                    <Card.Header as="h4" className="p-3 text-center">
                      Other Images
                    </Card.Header>
                    <Card.Body className="text-center">
                      {tabletImages.length <= 0 ? (
                        <Card.Img
                          cascade
                          className="img-fluid rounded"
                          src="https://via.placeholder.com/150"
                          style={{ height: "20vh", objectFit: "cover" }}
                        />
                      ) : (
                        <Carousel indicators={false}>
                          {tabletImages.map((image, index) => (
                            <Carousel.Item>
                              <img
                                className="d-block w-100 rounded"
                                src={image}
                                alt={`Image-${index}`}
                                style={{
                                  maxHeight: "20vh",
                                  objectFit: "cover",
                                }}
                              />
                              {/* Image Delete Button */}
                              <Button
                                variant="outline-danger"
                                className="btn-sm mt-3 rounded"
                                onClick={() => deleteImageHandler(image)}
                              >
                                Delete Image
                              </Button>
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      )}

                      <Form.Group controlId="image 1">
                        <Form.Label className="py-3">
                          Upload Other Images
                        </Form.Label>
                        <Form.Control
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Images"
                          onChange={uploadImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={12} md={8} lg={9}>
                  <Card className="mb-4 shadow rounded">
                    <Card.Header as="h4" className="p-3 text-center">
                      Tablet Information
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group className="mb-3" controlId="tabletBrand">
                            <Form.Label className="small mb-1">
                              Tablet Brand
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              type="text"
                              placeholder={
                                tabletBrand === ""
                                  ? "Brand Name is Required"
                                  : "Enter Tablet Brand Name"
                              }
                              value={tabletBrand}
                              onChange={(e) => setTabletBrand(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="tabletModel">
                            <Form.Label className="small mb-1">
                              Tablet Model
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              type="text"
                              placeholder={
                                tabletModel === ""
                                  ? "Model Name is Required"
                                  : "Enter Tablet Model Name"
                              }
                              value={tabletModel}
                              onChange={(e) => setTabletModel(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="tabletProcessor"
                          >
                            <Form.Label className="small mb-1">
                              Processor
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              type="text"
                              placeholder={
                                tabletProcessor === ""
                                  ? "Processor is Required"
                                  : "Enter Tablet Processor"
                              }
                              value={tabletProcessor}
                              onChange={(e) =>
                                setTabletProcessor(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="tabletGPU">
                            <Form.Label className="small mb-1">GPU</Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              type="text"
                              placeholder={
                                tabletGPU === ""
                                  ? "GPU is Required"
                                  : "Enter Tablet GPU"
                              }
                              value={tabletGPU}
                              onChange={(e) => setTabletGPU(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="tabletDisplay"
                          >
                            <Form.Label className="small mb-1">
                              Display
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              type="text"
                              placeholder={
                                tabletDisplay === ""
                                  ? "Display is Required"
                                  : "Enter Tablet Display"
                              }
                              value={tabletDisplay}
                              onChange={(e) => setTabletDisplay(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="tabletBackCamera"
                          >
                            <Form.Label className="small mb-1">
                              Back Camera
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              type="text"
                              placeholder={
                                tabletBackCamera === ""
                                  ? "Back Camera is Required"
                                  : "Enter Tablet Back Camera"
                              }
                              value={tabletBackCamera}
                              onChange={(e) =>
                                setTabletBackCamera(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="tabletFrontCamera"
                          >
                            <Form.Label className="small mb-1">
                              Front Camera
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              type="text"
                              placeholder={
                                tabletFrontCamera === ""
                                  ? "Front Camera is Required"
                                  : "Enter Tablet Front Camera"
                              }
                              value={tabletFrontCamera}
                              onChange={(e) =>
                                setTabletFrontCamera(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="tabletBattery"
                          >
                            <Form.Label className="small mb-1">
                              Battery
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              type="text"
                              placeholder={
                                tabletBattery === ""
                                  ? "Battery is Required"
                                  : "Enter Tablet Battery"
                              }
                              value={tabletBattery}
                              onChange={(e) => setTabletBattery(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="tabletSIM">
                            <Form.Label className="small mb-1">SIM</Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              as="select"
                              type="select"
                              placeholder={
                                tabletSIM === ""
                                  ? "SIM is Required"
                                  : "Enter Tablet SIM"
                              }
                              value={tabletSIM}
                              onChange={(e) => setTabletSIM(e.target.value)}
                            >
                              <option disabled value="">
                                SIM Slots
                              </option>
                              <option value="Dual SIM">Dual SIM</option>
                              <option value="Single SIM">Single SIM</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="tabletType">
                            <Form.Label className="small mb-1">
                              Product Type
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              as="select"
                              type="select"
                              placeholder={
                                tabletProductType === ""
                                  ? "Type is Required"
                                  : "Select Tablet Type"
                              }
                              value={tabletProductType}
                              onChange={(e) =>
                                setTabletProductType(e.target.value)
                              }
                            >
                              <option disabled value="">
                                Select Type
                              </option>
                              <option value="New">New</option>
                              <option value="Pre-Owned">Pre-Owned</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        {/* Added Variants List */}
                        {tabletVariants.length > 0 && (
                          <>
                            <Col lg={12} md={12} sm={12}>
                              Variants
                            </Col>
                            <Col lg={12} md={12} sm={12} className="mb-3">
                              <ListGroup variant="flush">
                                {tabletVariants.map((variant) => (
                                  <ListGroup.Item key={variant.id}>
                                    <Row>
                                      <Col lg={3} md={3} sm={3}>
                                        {`${variant.RAM}GB | ${variant.ROM}GB`}
                                      </Col>
                                      <Col lg={3} md={3} sm={3}>
                                        {`Price: BDT ${variant.price}`}
                                      </Col>
                                      <Col lg={3} md={3} sm={3}>
                                        {`Selling Price: BDT ${variant.sellingPrice}`}
                                      </Col>
                                      {tabletVariants.length > 1 && (
                                        <Col lg={3} md={3} sm={3}>
                                          <Button
                                            variant="danger rounded"
                                            onClick={() => {
                                              setTabletVariants(
                                                tabletVariants.filter(
                                                  (x) =>
                                                    x.price !== variant.price
                                                )
                                              );
                                            }}
                                          >
                                            Delete Variant
                                          </Button>
                                        </Col>
                                      )}
                                    </Row>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </Col>
                          </>
                        )}

                        {/* Button For Showing Fields */}
                        {showVariantFields ? (
                          <>
                            <Col lg={6} md={6} sm={6} className="mb-3">
                              <Button
                                variant="outline-success rounded"
                                onClick={() => {
                                  if (
                                    tabletRAM === "" ||
                                    tabletROM === "" ||
                                    tabletPrice === 0 ||
                                    tabletSellingPrice === 0 ||
                                    tabletStock === ""
                                  ) {
                                    toast.error(
                                      "Please input all the fields to add a variant",
                                      { position: "top-center" }
                                    );
                                  } else {
                                    setTabletVariants([
                                      ...tabletVariants,
                                      {
                                        RAM: tabletRAM,
                                        ROM: tabletROM,
                                        price: tabletPrice,
                                        sellingPrice: tabletSellingPrice,
                                        stock: tabletStock,
                                      },
                                    ]);
                                    setTabletRAM("");
                                    setTabletROM("");
                                    setTabletPrice(0);
                                    setTabletSellingPrice(0);
                                    setTabletStock("");
                                    setShowVariantFields(false);
                                  }
                                }}
                              >
                                <AiFillPlusSquare
                                  size={18}
                                  className="mb-1 me-2"
                                />
                                Add Variant
                              </Button>
                            </Col>

                            <Col lg={6} md={6} sm={6} className="mb-3">
                              <Button
                                variant="outline-danger rounded"
                                onClick={() => {
                                  setShowVariantFields(false);
                                  setTabletRAM("");
                                  setTabletROM("");
                                  setTabletPrice(0);
                                  setTabletSellingPrice(0);
                                  setTabletStock("");
                                }}
                              >
                                <AiFillPlusSquare
                                  size={18}
                                  className="mb-1 me-2"
                                />
                                Cancel Adding Variant
                              </Button>
                            </Col>
                          </>
                        ) : (
                          <Col lg={12} md={12} sm={12} className="mb-3">
                            <Button
                              variant="outline-dark rounded"
                              onClick={() => setShowVariantFields(true)}
                            >
                              <AiFillPlusSquare
                                size={18}
                                className="mb-1 me-2"
                              />
                              Add a new Variant and price
                            </Button>
                          </Col>
                        )}

                        {/* Variant Fields */}
                        {showVariantFields && (
                          <>
                            <Col lg={6} md={6} sm={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="tabletRAM"
                              >
                                <Form.Label className="small mb-1">
                                  RAM
                                </Form.Label>
                                <Form.Control
                                  className="rounded"
                                  required
                                  type="text"
                                  placeholder={
                                    tabletRAM === ""
                                      ? "RAM is Required"
                                      : "Enter Tablet RAM"
                                  }
                                  value={tabletRAM}
                                  onChange={(e) => setTabletRAM(e.target.value)}
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="tabletROM"
                              >
                                <Form.Label className="small mb-1">
                                  ROM
                                </Form.Label>
                                <Form.Control
                                  className="rounded"
                                  required
                                  type="text"
                                  placeholder={
                                    tabletROM === ""
                                      ? "ROM is Required"
                                      : "Enter Tablet ROM"
                                  }
                                  value={tabletROM}
                                  onChange={(e) => setTabletROM(e.target.value)}
                                ></Form.Control>
                              </Form.Group>
                            </Col>

                            <Col lg={6} md={6} sm={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="tabletPrice"
                              >
                                <Form.Label className="small mb-1">
                                  Price
                                </Form.Label>
                                <Form.Control
                                  className="rounded"
                                  required
                                  type="number"
                                  placeholder={
                                    tabletPrice === ""
                                      ? "Price is Required"
                                      : "Enter Tablet Price"
                                  }
                                  value={tabletPrice}
                                  onChange={(e) =>
                                    setTabletPrice(e.target.value)
                                  }
                                ></Form.Control>
                              </Form.Group>
                            </Col>

                            <Col lg={6} md={6} sm={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="tabletSellingPrice"
                              >
                                <Form.Label className="small mb-1">
                                  Estimated Selling Price
                                </Form.Label>
                                <Form.Control
                                  className="rounded"
                                  required
                                  type="number"
                                  placeholder="Enter Tablet Estimated Selling Price"
                                  value={tabletSellingPrice}
                                  onChange={(e) =>
                                    setTabletSellingPrice(e.target.value)
                                  }
                                ></Form.Control>
                              </Form.Group>
                            </Col>

                            <Col lg={6} md={6} sm={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="tabletStock"
                              >
                                <Form.Label className="small mb-1">
                                  Status
                                </Form.Label>
                                <Form.Control
                                  className="rounded"
                                  required
                                  as="select"
                                  type="select"
                                  placeholder={
                                    tabletStock === ""
                                      ? "Status is Required"
                                      : "Select Tablet Status"
                                  }
                                  value={tabletStock}
                                  onChange={(e) =>
                                    setTabletStock(e.target.value)
                                  }
                                >
                                  <option disabled value="">
                                    Select Status
                                  </option>
                                  <option value="In Stock">In Stock</option>
                                  <option value="Out of Stock">
                                    Out of Stock
                                  </option>
                                </Form.Control>
                              </Form.Group>
                            </Col>
                          </>
                        )}

                        <Col lg={12} md={12} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="tabletDescription"
                          >
                            <Form.Label className="small mb-1">
                              Other Details
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              type="text"
                              as="textarea"
                              rows={3}
                              placeholder="Add other details about the tablet"
                              value={tabletDescription}
                              onChange={(e) =>
                                setTabletDescription(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="px-2 py-2">
                        <Col lg={6} md={6} sm={12}>
                          <Button
                            variant="outline-dark rounded"
                            onClick={deleteHandler}
                          >
                            <AiTwotoneDelete className="mb-1" size={18} />
                            Delete Tablet
                          </Button>
                        </Col>
                        <Col
                          lg={6}
                          md={6}
                          sm={12}
                          className="d-flex justify-content-end"
                        >
                          <Button
                            variant="outline-dark rounded"
                            onClick={updateHandler}
                          >
                            <AiFillEdit className="mb-1" size={18} />
                            Update Tablet
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      </>
    )
  );
};

export default UpdateTabletScreen;
