import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const SellGadgetsList = () => {
  const [searchGadget, setSearchGadget] = useState("");
  return (
    <Container>
      <h2
        style={{ fontWeight: "bold" }}
        className="h1-responsive my-5 pt-3 text-center"
      >
        Sell Your Gadgets
      </h2>
      <Row>
        <Col lg={6} md={6} sm={12}>
          <Form.Control
            type="text"
            onChange={(e) => setSearchGadget(e.target.value)}
            placeholder="Search Preffered Gadget to Sell"
          ></Form.Control>
        </Col>
        <Col lg={6} md={6} sm={12} className="d-flex justify-content-end">
          <Button>Create Add</Button>
        </Col>
      </Row>
      <Row className="my-5">
        <Col lg={2} md={3} sm={4} className="my-4">
          <LinkContainer to="">
            <Card>
              <Card.Img
                cascade
                className="img-fluid mb-2"
                src="/img/Tablet/huawei.png"
              />

              <Card.Text className="text-center mb-2">Headphone</Card.Text>
              <Button>
                Generate Price
              </Button>
            </Card>
          </LinkContainer>
        </Col>
        <Col lg={2} md={3} sm={4} className="my-4">
          <LinkContainer to="">
            <Card>
              <Card.Img
                cascade
                className="img-fluid mb-2"
                src="/img/Tablet/huawei.png"
              />

              <Card.Text className="text-center mb-2">Headphone</Card.Text>
              <Button>
                Generate Price
              </Button>
            </Card>
          </LinkContainer>
        </Col>
        <Col lg={2} md={3} sm={4} className="my-4">
          <LinkContainer to="">
            <Card>
              <Card.Img
                cascade
                className="img-fluid mb-2"
                src="/img/Tablet/huawei.png"
              />

              <Card.Text className="text-center mb-2">Headphone</Card.Text>
              <Button>
                Generate Price
              </Button>
            </Card>
          </LinkContainer>
        </Col>
        <Col lg={2} md={3} sm={4} className="my-4">
          <LinkContainer to="">
            <Card>
              <Card.Img
                cascade
                className="img-fluid mb-2"
                src="/img/Tablet/huawei.png"
              />

              <Card.Text className="text-center mb-2">Headphone</Card.Text>
              <Button>
                Generate Price
              </Button>
            </Card>
          </LinkContainer>
        </Col>
        <Col lg={2} md={3} sm={4} className="my-4">
          <LinkContainer to="">
            <Card>
              <Card.Img
                cascade
                className="img-fluid mb-2"
                src="/img/Tablet/huawei.png"
              />

              <Card.Text className="text-center mb-2">Headphone</Card.Text>
              <Button>
                Generate Price
              </Button>
            </Card>
          </LinkContainer>
        </Col>
        <Col lg={2} md={3} sm={4} className="my-4">
          <LinkContainer to="">
            <Card>
              <Card.Img
                cascade
                className="img-fluid mb-2"
                src="/img/Tablet/huawei.png"
              />

              <Card.Text className="text-center mb-2">Headphone</Card.Text>
              <Button>
                Generate Price
              </Button>
            </Card>
          </LinkContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default SellGadgetsList;
