import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Modal, Card, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import CategoriesToBuyNav from "../components/CategoriesToBuyNav";
import TabletsDetails from "../components/tablets/TabletsDetails";
import {
  getTabletById,
  deleteTablet,
  resetTabletDelete,
  resetTabletDetails,
} from "../features/tablet/tabletSlice";
import { toast } from "react-toastify";
import { AiTwotoneDelete, AiFillEdit } from "react-icons/ai";

const TabletDetailsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const { userInfo } = useSelector((state) => state.auth);

  const [tableT, setTableT] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    tablet,

    isDetailsLoading,
    isDetailsError,
    isDetailsSuccess,
    detailsErrorMessage,

    isDeleteLoading,
    isDeleteError,
    isDeleteSuccess,
    deleteErrorMessage,
  } = useSelector((state) => state.tablet);

  useEffect(() => {
    if (isDetailsError) {
      toast.error(detailsErrorMessage, { position: "top-center" });
    } else if (isDetailsSuccess) {
      setTableT(tablet);
    } else {
      dispatch(getTabletById(id));
    }
  }, [
    dispatch,
    id,
    isDetailsError,
    isDetailsSuccess,
    detailsErrorMessage,
    tablet,
  ]);

  useEffect(() => {
    if (isDeleteError) {
      toast.error(deleteErrorMessage, { position: "top-center" });
    } else if (isDeleteSuccess) {
      toast.success("Tablet deleted successfully", {
        position: "top-center",
      });
      navigate("/buyTablet");
    }
  }, [isDeleteError, deleteErrorMessage, isDeleteSuccess]);

  useEffect(() => {
    return () => {
      dispatch(resetTabletDetails());
      dispatch(resetTabletDelete());
    };
  }, [dispatch]);

  const deleteHandler = () => {
    if (
      window.confirm(
        "Tablet will be deleted permanently, You sure you want to proceed?"
      )
    ) {
      dispatch(deleteTablet(params.id));
    }
    // dispatch(deleteTablet(params.id));
  };

  return (
    <>
      <Container fluid>
        <CategoriesToBuyNav />
      </Container>
      
      <Container>
        <TabletsDetails deviceType="tablet" device={tablet} />

        {userInfo && userInfo.userType === "admin" ? (
          <Row>
            <Col
              sm={12}
              md={12}
              lg={12}
              className="d-flex justify-content-end gap-2 mb-4"
            >
              <LinkContainer to={`/updateTablet/${id}`}>
                <Button variant="outline-dark rounded">
                  <AiFillEdit className="mb-1" size={18} />
                  Update Information
                </Button>
              </LinkContainer>

              <Button variant="outline-dark rounded" onClick={deleteHandler}>
                <AiTwotoneDelete className="mb-1" size={18} />
                Delete Tablet
              </Button>
            </Col>
          </Row>
        ) : null}
      </Container>
    </>
  );
};

export default TabletDetailsScreen;
