import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { verifyOTP } from '../features/auth/authSlice'

const VerifyOTPModal = ({
  showVerifyOTPModal,
  setShowVerifyOTPModal,
  globalPhone,
}) => {
  const dispatch = useDispatch()

  const [otp, setOTP] = useState('')

  const verifyOTPHandler = () => {
    dispatch(verifyOTP({ mobile: globalPhone, otp }))
  }

  return (
    <Modal
      backdrop='static'
      keyboard={false}
      show={showVerifyOTPModal}
      onHide={() => setShowVerifyOTPModal(false)}
      size='lg'
    >
      <Modal.Body>
        <h1 className='text-center mb-5'>OTP Verification</h1>
        <p className='text-center'>
          A 6 Digit OTP has been sent to your mobile number, Check your phone!
        </p>
        <Form>
          <Form.Group className='mb-3' controlId='otp'>
            <Form.Control
              className='rounded'
              type='text'
              placeholder='Enter OTP'
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
              autoFocus
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outline-primary'
          className='rounded'
          onClick={() => verifyOTPHandler()}
        >
          Verify
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default VerifyOTPModal
