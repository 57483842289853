import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Container } from "react-bootstrap";
import Header1 from "./components/Header1";
import Footer from "./components/Footer";
import UsefulLinks from "./components/UsefulLinks.js";
import HomeScreen from "./screens/HomeScreen";

import SellSmartphoneScreen from "./screens/SellSmartphoneScreen";
import SellTabletScreen from "./screens/SellTabletScreen";
import SellWearableScreeen from "./screens/SellWearableScreeen";
import GadgetsListScreen from "./screens/GadgetsListScreen";

import SmartPhonesListScreen from "./screens/SmartPhonesListScreen";
import TabletsListScreen from "./screens/TabletsListScreen";
import WearablesListScreen from "./screens/WearablesListScreen";

import SmartPhoneDetailsScreen from "./screens/SmartPhoneDetailsScreen";
import TabletDetailsScreen from "./screens/TabletDetailsScreen";
import WearableDetailsScreen from "./screens/WearableDetailsScreen";
import GadgetDetailsScreen from "./screens/GadgetDetailsScreen";

import SmartPhoneAddRequestDetails from "./screens/SmartPhoneAddRequestDetails";
import TabletAddRequestDetails from "./screens/TabletAddRequestDetails";
import WearablesAddRequestDetails from "./screens/WearablesAddRequestDetails";

import SmartphoneScreen from "./components/smartphones/SmartphoneScreen";
import GadgetScreen from "./components/gadgets/GadgetScreen";
import WearableScreen from "./components/wearables/WearableScreen";
import TabletScreen from "./components/tablets/TabletScreen";

import BuySmartPhoneScreen from "./screens/BuySmartPhoneScreen";
import BuyTabletScreen from "./screens/BuyTabletScreen";
import BuyWearableScreen from "./screens/BuyWearableScreen";
import BuyGadgetScreen from "./screens/BuyGadgetScreen";

import UpdateSmartphoneScreen from "./screens/UpdateSmartphoneScreen";
import UpdateTabletScreen from "./screens/UpdateTabletScreen";
import UpdateWearableScreen from "./screens/UpdateWearableScreen";
import UpdateGadgetScreen from "./screens/UpdateGadgetScreen";

import UpdateProfileScreen from "./screens/UpdateProfileScreen";

import CreateSmartPhoneScreen from "./screens/CreateSmartPhoneScreen";
import CreateTabletScreen from "./screens/CreateTabletScreen";
import CreateWearableScreen from "./screens/CreateWearableScreen";
import CreateGadgetScreen from "./screens/CreateGadgetScreen";

import CustomizedSmartPhoneAddRequest from "./screens/CustomizedSmartPhoneAddRequest";
import CustomizedTabletAddRequest from "./screens/CustomizedTabletAddRequest";
import CustomizedWearablesAddRequest from "./screens/CustomizedWearablesAddRequest";

import DashboardScreen from "./screens/DashboardScreen";
import UserDashboardScreen from "./screens/UserDashboardScreen";

import AboutUs from "./components/AboutUs";
import WhyST from "./components/WhyST";
import TermsAndCondition from "./components/TermsAndCondition";
import ReturnRefund from "./components/ReturnRefund";

import CreateNavbarScreen from "./screens/CreateNavbarScreen";
import CreateOfferScreen from "./screens/CreateOfferScreen";

import ProductEvaluationScreen from "./screens/ProductEvaluationScreen";
import EvaluationDetailsScreen from "./screens/EvaluationDetailsScreen";

import NotFoundScreen from "./screens/NotFoundScreen";

const App = () => {
  return (
    <>
      <Router>
        <Header1 />
        <main className="main" style={{ minHeight: "65vh" }}>
          <Container fluid className="px-0">
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/dashboard" element={<DashboardScreen />} />
              <Route path="/userDashboard" element={<UserDashboardScreen />} />
              <Route
                path="/sellSmartphone"
                element={<SellSmartphoneScreen />}
              />
              <Route path="/sellTablets" element={<SellTabletScreen />} />
              <Route path="/sellWearable" element={<SellWearableScreeen />} />

              <Route
                path="/productEvaluation/:id"
                element={<ProductEvaluationScreen />}
              />

              <Route
                path="/evaluationDetails/:id"
                element={<EvaluationDetailsScreen />}
              />

              <Route path="/smartphoneScreen" element={<SmartphoneScreen />} />
              <Route path="/tabletScreen" element={<TabletScreen />} />
              <Route path="/wearableScreen" element={<WearableScreen />} />
              <Route path="/gadgetScreen" element={<GadgetScreen />} />

              <Route
                path="/smartPhonesList/:brand"
                element={<SmartPhonesListScreen />}
              />
              <Route
                path="/tabletsList/:brand"
                element={<TabletsListScreen />}
              />
              <Route
                path="/wearablesList/:brand"
                element={<WearablesListScreen />}
              />
              <Route path="/gadgetsList" element={<GadgetsListScreen />} />

              <Route
                path="/smartPhoneDetails/:id"
                element={<SmartPhoneDetailsScreen />}
              />
              <Route
                path="/tabletDetails/:id"
                element={<TabletDetailsScreen />}
              />
              <Route
                path="/wearableDetails/:id"
                element={<WearableDetailsScreen />}
              />
              <Route
                path="/gadgetDetails/:id"
                element={<GadgetDetailsScreen />}
              />

              <Route
                path="/smartphoneAddRequestDetails/:id"
                element={<SmartPhoneAddRequestDetails />}
              />
              <Route
                path="/tabletAddRequestDetails/:id"
                element={<TabletAddRequestDetails />}
              />
              <Route
                path="/wearablesAddRequestDetails/:id"
                element={<WearablesAddRequestDetails />}
              />

              <Route path="/buySmartPhone" element={<BuySmartPhoneScreen />} />
              <Route path="/buyTablet" element={<BuyTabletScreen />} />
              <Route path="/buyWearable" element={<BuyWearableScreen />} />
              <Route path="/buyGadget" element={<BuyGadgetScreen />} />

              <Route
                path="/createSmartPhone"
                element={<CreateSmartPhoneScreen />}
              />
              <Route path="/createTablet" element={<CreateTabletScreen />} />
              <Route
                path="/createWearables"
                element={<CreateWearableScreen />}
              />
              <Route path="/createGadget" element={<CreateGadgetScreen />} />

              <Route
                path="/updateSmartphone/:id"
                element={<UpdateSmartphoneScreen />}
              />
              <Route
                path="/updateTablet/:id"
                element={<UpdateTabletScreen />}
              />
              <Route
                path="/updateWearable/:id"
                element={<UpdateWearableScreen />}
              />
              <Route
                path="/updateGadget/:id"
                element={<UpdateGadgetScreen />}
              />

              <Route path="/updateProfile" element={<UpdateProfileScreen />} />

              <Route
                path="/addSmartPhone"
                element={<CustomizedSmartPhoneAddRequest />}
              />
              <Route
                path="/addTablet"
                element={<CustomizedTabletAddRequest />}
              />
              <Route
                path="/addWearable"
                element={<CustomizedWearablesAddRequest />}
              />

              <Route path="/createNavbar" element={<CreateNavbarScreen />} />
              <Route path="/createOffer" element={<CreateOfferScreen />} />

              <Route path="/aboutUs" element={<AboutUs />} />
              <Route path="/whyST" element={<WhyST />} />
              <Route
                path="/termsAndCondition"
                element={<TermsAndCondition />}
              />
              <Route path="/returnRefund" element={<ReturnRefund />} />

              <Route path="*" element={<NotFoundScreen />} />
            </Routes>
          </Container>
        </main>

        <UsefulLinks />
        <Footer />
      </Router>
      <ToastContainer />
    </>
  );
};

export default App;
