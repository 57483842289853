import axios from 'axios'

//create tablet
const createTablet = async (tablet, token) => {
  const response = await axios.post('/api/v1/tablets', tablet, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

//get all tablets
const getAllTablets = async (token) => {
  const response = await axios.get('/api/v1/tablets?sort=-createdAt')
  return response.data.data
}

//get tablet by id
const getTabletById = async (id, token) => {
  const response = await axios.get(`/api/v1/tablets/${id}`)
  return response.data.data
}

//update tablet
const updateTablet = async (id, tablet, token) => {
  const response = await axios.patch(`/api/v1/tablets/${id}`, tablet, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

//delete tablet
const deleteTablet = async (id, token) => {
  const response = await axios.delete(`/api/v1/tablets/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

const tabletService = {
  createTablet,
  getAllTablets,
  getTabletById,
  updateTablet,
  deleteTablet,
}

export default tabletService
