import React, {useEffect} from "react";
import { motion } from "framer-motion";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const SellSmartphone = () => {
  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <Container>
        <h2
          style={{ fontWeight: "bold" }}
          className="h1-responsive my-5 pt-3 text-center"
        >
          Popular Smartphone Brands
        </h2>
        <Row className='pb-5'>
          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Apple">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/apple.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Oneplus">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/oneplus.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Samsung">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/samsung.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Xiaomi">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/xiaomi.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Nothing">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/nothing.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Huawei">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/huawei.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Oppo">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/oppo.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Vivo">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/vivo.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Realme">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/realme.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Asus">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/asus.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Google">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/google.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Nokia">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/nokia.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Sony">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/sony.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/LG">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/lg.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Motorola">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/motorola.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Blackberry">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/blackberry.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Lenovo">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/lenovo.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>

          <Col xs={6} md={3} lg={2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <LinkContainer to="/smartPhonesList/Infinix">
                <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                  <Card.Img
                    style={{ height: "80px", width: "80px" }}
                    cascade
                    className="img-fluid"
                    src="./img/smartphoneBrands/infinix.png"
                  />
                </Card>
              </LinkContainer>
            </motion.div>
          </Col>
        </Row>
      </Container>
    )
  );
};

export default SellSmartphone;
