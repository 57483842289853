import React from 'react'
import SellSmartphone from '../components/smartphones/SellSmartphone'
import CategoriesToSellNav from '../components/CategoriesToSellNav'
import CategoriesToBuyNav from '../components/CategoriesToBuyNav'

const SellItemsScreen = () => {
  return (
    <div>
        <CategoriesToBuyNav/>
        <SellSmartphone/>
    </div>
  )
}

export default SellItemsScreen