import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";

//get user from local storage
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

//initial state
const initialState = {
  userInfo: userInfo ? userInfo : null,

  // isSignupError: false,
  // isSignupSuccess: false,
  // isSignupLoading: false,
  // signupErrorMessage: "",

  // isSigninError: false,
  // isSigninSuccess: false,
  // isSigninLoading: false,
  // signinErrorMessage: "",

  isSendOTPError: false,
  isSendOTPSuccess: false,
  isSendOTPLoading: false,
  sendOTPErrorMessage: "",

  isVerifyOTPError: false,
  isVerifyOTPSuccess: false,
  isVerifyOTPLoading: false,
  verifyOTPErrorMessage: "",

  isUpdateNewUserError: false,
  isUpdateNewUserSuccess: false,
  isUpdateNewUserLoading: false,
  updateNewUserErrorMessage: "",

  isUpdateAuthedUserError: false,
  isUpdateAuthedUserSuccess: false,
  isUpdateAuthedUserLoading: false,
  updateAuthedUserErrorMessage: "",
};

//signin user Locally
// export const signinLocal = createAsyncThunk(
//   "auth/signinLocal",
//   async (userData, thunkAPI) => {
//     try {
//       const response = await authService.signinLocal(userData);
//       return response;
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

//send OTP
export const sendOTP = createAsyncThunk(
  "auth/sendOTP",
  async (userData, thunkAPI) => {
    try {
      const response = await authService.sendOTP(userData);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//verify OTP
export const verifyOTP = createAsyncThunk(
  "auth/verifyOTP",
  async (userData, thunkAPI) => {
    try {
      const response = await authService.verifyOTP(userData);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update new user
export const updateNewUser = createAsyncThunk(
  "auth/updateMe",
  async (userData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const response = await authService.updateNewUser(userData, token);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// update authed user
export const updateAuthedUser = createAsyncThunk(
  "auth/updateAuthedUser",
  async (data, thunkAPI) => {
    const { id, userData } = data;
    try {
      return await authService.updateAuthedUser(id, userData);
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // resetSignup: (state) => {
    //   state.isSignupError = false;
    //   state.isSignupSuccess = false;
    //   state.isSignupLoading = false;
    //   state.signupErrorMessage = "";
    // },
    // resetSignin: (state) => {
    //   state.isSigninError = false;
    //   state.isSigninSuccess = false;
    //   state.isSigninLoading = false;
    //   state.signinErrorMessage = "";
    // },
    resetSendOTP: (state) => {
      state.isSendOTPError = false;
      state.isSendOTPSuccess = false;
      state.isSendOTPLoading = false;
      state.sendOTPErrorMessage = "";
    },
    resetVerifyOTP: (state) => {
      state.isVerifyOTPError = false;
      state.isVerifyOTPSuccess = false;
      state.isVerifyOTPLoading = false;
      state.verifyOTPErrorMessage = "";
    },
    resetUpdateNewUser: (state) => {
      state.isUpdateNewUserError = false;
      state.isUpdateNewUserSuccess = false;
      state.isUpdateNewUserLoading = false;
      state.updateNewUserErrorMessage = "";
    },
    resetUpdateAuthedUser: (state) => {
      state.isUpdateAuthedUserError = false;
      state.isUpdateAuthedUserSuccess = false;
      state.isUpdateAuthedUserLoading = false;
      state.updateAuthedUserErrorMessage = "";
    },
    logout: (state) => {
      state.userInfo = null;
      localStorage.removeItem("userInfo");
      document.location.href = "/";
    },
  },

  extraReducers: (builder) => {
    builder
      // .addCase(signinLocal.pending, (state) => {
      //   state.isSigninLoading = true;
      // })
      // .addCase(signinLocal.fulfilled, (state, action) => {
      //   state.isSigninLoading = false;
      //   state.isSigninSuccess = true;
      //   state.userInfo = action.payload;
      // })
      // .addCase(signinLocal.rejected, (state, action) => {
      //   state.isSigninLoading = false;
      //   state.isSigninError = true;
      //   state.signinErrorMessage = action.payload;
      //   state.userInfo = null;
      // });
      .addCase(sendOTP.pending, (state) => {
        state.isSendOTPLoading = true;
        state.isSendOTPSuccess = false;
        state.isSendOTPError = false;
        state.sendOTPErrorMessage = "";
      })
      .addCase(sendOTP.fulfilled, (state, action) => {
        state.isSendOTPLoading = false;
        state.isSendOTPSuccess = true;
        state.isSendOTPError = false;
        state.sendOTPErrorMessage = "";
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.isSendOTPLoading = false;
        state.isSendOTPSuccess = false;
        state.isSendOTPError = true;
        state.sendOTPErrorMessage = action.payload;
      })
      .addCase(verifyOTP.pending, (state) => {
        state.isVerifyOTPLoading = true;
        state.isVerifyOTPSuccess = false;
        state.isVerifyOTPError = false;
        state.verifyOTPErrorMessage = "";
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.isVerifyOTPLoading = false;
        state.isVerifyOTPSuccess = true;
        state.isVerifyOTPError = false;
        state.verifyOTPErrorMessage = "";
        state.userInfo = action.payload;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.isVerifyOTPLoading = false;
        state.isVerifyOTPSuccess = false;
        state.isVerifyOTPError = true;
        state.verifyOTPErrorMessage = action.payload;
      })
      .addCase(updateNewUser.pending, (state) => {
        state.isUpdateNewUserLoading = true;
        state.isUpdateNewUserSuccess = false;
        state.isUpdateNewUserError = false;
        state.updateNewUserErrorMessage = "";
      })
      .addCase(updateNewUser.fulfilled, (state, action) => {
        state.isUpdateNewUserLoading = false;
        state.isUpdateNewUserSuccess = true;
        state.isUpdateNewUserError = false;
        state.updateNewUserErrorMessage = "";
        state.userInfo = action.payload;
      })
      .addCase(updateNewUser.rejected, (state, action) => {
        state.isUpdateNewUserLoading = false;
        state.isUpdateNewUserSuccess = false;
        state.isUpdateNewUserError = true;
        state.updateNewUserErrorMessage = action.payload;
      })
      .addCase(updateAuthedUser.pending, (state) => {
        state.isUpdateAuthedUserLoading = true;
        state.isUpdateAuthedUserSuccess = false;
        state.isUpdateAuthedUserError = false;
        state.updateAuthedUserErrorMessage = "";
      })
      .addCase(updateAuthedUser.fulfilled, (state, action) => {
        state.isUpdateAuthedUserLoading = false;
        state.isUpdateAuthedUserSuccess = true;
        state.isUpdateAuthedUserError = false;
        state.updateAuthedUserErrorMessage = "";
        state.userInfo = action.payload;
      })
      .addCase(updateAuthedUser.rejected, (state, action) => {
        state.isUpdateAuthedUserLoading = false;
        state.isUpdateAuthedUserSuccess = false;
        state.isUpdateAuthedUserError = true;
        state.updateAuthedUserErrorMessage = action.payload;
      });
  },
});

export const { logout, resetSendOTP, resetVerifyOTP, resetUpdateNewUser, resetUpdateAuthedUser } =
  authSlice.actions;
export default authSlice.reducer;
