import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, CardGroup, Form } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getAllSmartphones,
  resetSmartphoneList,
} from '../../features/smartphone/smartphoneSlice'
import { toast } from 'react-toastify'
import Loader from '../Loader'
import Message from '../Message'
import { TbCurrencyTaka } from 'react-icons/tb'
import { motion } from 'framer-motion'

const BuySmartPhone = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [allSmartPhones, setAllSmartPhones] = useState([])

  const [minPrice, setMinPrice] = useState(0)
  const [maxPrice, setMaxPrice] = useState(0)
  const [productType, setProductType] = useState('')
  const [selectedBrands, setSelectedBrands] = useState([])

  const [expandPrice, setExpandPrice] = useState(false)
  const [expandType, setExpandType] = useState(false)
  const [expandBrand, setExpandBrand] = useState(false)

  const [searchSmartPhones, setSearchSmartPhones] = useState('')

  const [populatedBrands, setPopulatedBrands] = useState([])

  const {
    smartphones,
    isListLoading,
    isListError,
    listErrorMessage,
    isListSuccess,
  } = useSelector((state) => state.smartphone)

  useEffect(() => {
    if (isListError) {
      // toast.error(listErrorMessage, { position: "top-center" });
    } else if (isListSuccess) {
      const brands = []
      smartphones.map((smartphone) => {
        if (!brands.includes(smartphone.brand)) {
          brands.push(smartphone.brand)
        }
      })
      setPopulatedBrands(brands)

      const filteredSmartphones = smartphones
        .filter((smartphone) => {
          if (minPrice === 0) {
            return smartphone
          } else if (smartphone.price >= minPrice) {
            return smartphone
          }
        })
        .filter((smartphone) => {
          if (maxPrice === 0) {
            return smartphone
          } else if (smartphone.price <= maxPrice) {
            return smartphone
          }
        })
        .filter((smartphone) => {
          if (productType === '') {
            return smartphone
          } else if (smartphone.productType === productType) {
            return smartphone
          }
        })
        .filter((smartphone) => {
          if (searchSmartPhones === '') {
            return smartphone
          } else {
            return (
              smartphone.model.toLowerCase().includes(searchSmartPhones) ||
              smartphone.brand.toLowerCase().includes(searchSmartPhones)
            )
          }
        })
        .filter((smartphone) => {
          if (selectedBrands.length === 0) {
            return smartphone
          } else if (selectedBrands.includes(smartphone.brand)) {
            return smartphone
          }
        })
      setAllSmartPhones(filteredSmartphones)
    } else {
      dispatch(getAllSmartphones())
    }
  }, [
    dispatch,
    isListError,
    listErrorMessage,
    isListSuccess,
    smartphones,
    minPrice,
    maxPrice,
    productType,
    searchSmartPhones,
    selectedBrands,
  ])

  useEffect(() => {
    return () => {
      dispatch(resetSmartphoneList())
    }
  }, [dispatch])

  const handleCheckedBrand = (e) => {
    if (e.target.checked) {
      setSelectedBrands([...selectedBrands, e.target.value])
    } else {
      setSelectedBrands(
        selectedBrands.filter((brand) => brand !== e.target.value)
      )
    }
  }

  return (
    useEffect(() => {
      window.scrollTo(0, 0)
    }, []),
    (
      <Container>
        <h2
          style={{ fontWeight: 'bold' }}
          className='h1-responsive my-5 pt-3 text-center'
        >
          Choose Your Preferred Smart Phone To Buy
        </h2>
        <Row className='mb-5'>
          <Col sm={12} md={12} lg={3} className='pb-2'>
            <Card className='shadow p-3 rounded'>
              <Card.Title as='h3' className='text-dark text-center'>
                {' '}
                Filters{' '}
              </Card.Title>
              <Card.Title
                className='py-2 text-dark cursorAll'
                onClick={() => setExpandPrice(!expandPrice)}
              >
                Price
              </Card.Title>
              {expandPrice && (
                <Row className='pb-4'>
                  <Col sm={12} md={12} lg={6}>
                    <Form.Control
                      className='rounded'
                      type='text'
                      placeholder='Min Price'
                      value={minPrice}
                      onChange={(e) => setMinPrice(e.target.value)}
                    ></Form.Control>
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <Form.Control
                      className='rounded'
                      type='text'
                      placeholder='Max Price'
                      value={maxPrice}
                      onChange={(e) => setMaxPrice(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
              )}
              <Card.Title
                className='pb-2 text-dark cursorAll'
                onClick={() => setExpandType(!expandType)}
              >
                Product Type
              </Card.Title>
              {expandType && (
                <Form.Control
                  className='rounded mb-4'
                  type='select'
                  as='select'
                  placeholder='Select Product Type'
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                >
                  <option value=' '>Select Product Type</option>
                  <option value='New'>New</option>
                  <option value='Pre-Owned'>Pre-Owned</option>
                </Form.Control>
              )}

              <Card.Title
                className='text-dark cursorAll'
                onClick={() => setExpandBrand(!expandBrand)}
              >
                Brands
              </Card.Title>
              {expandBrand && (
                <>
                  {populatedBrands.map((brand) => (
                    <Form.Check
                      name={brand}
                      value={brand}
                      label={brand}
                      onChange={(e) => handleCheckedBrand(e)}
                      className='mb-2'
                    />
                  ))}
                </>
              )}
            </Card>
          </Col>

          <Col sm={12} md={12} lg={9}>
            <Card className='shadow p-3 rounded'>
              <Row className='mb-4'>
                <Col sm={12} md={12} lg={12}>
                  <Form>
                    <Form.Control
                      type='text'
                      placeholder='Search Smart Phones'
                      name='searchSmartPhones'
                      onChange={(e) => setSearchSmartPhones(e.target.value)}
                      style={{ border: '1px solid black' }}
                      className='shadow rounded'
                    ></Form.Control>
                  </Form>
                </Col>
              </Row>

              <Row>
                {isListLoading ? (
                  <Loader />
                ) : allSmartPhones.length <= 0 ? (
                  <Message variant='danger'>No Smart Phones Found</Message>
                ) : (
                  <>
                    {allSmartPhones.map((smartphone) => (
                      <Col sm={12} md={4} lg={3}>
                        <motion.div
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.9 }}
                        >
                          <LinkContainer
                            to={`/smartPhoneDetails/${smartphone._id}`}
                          >
                            <CardGroup>
                              <Card
                                key={smartphone._id}
                                className='shadow rounded mb-3 cursorAll'
                              >
                                <Card.Img
                                  cascade
                                  className='img-fluid mb-3 rounded p-2 buyCard'
                                  src={smartphone.coverImage}
                                />
                                <Card.Body className='buyCardBody'>
                                  <Card.Text className='text-center mb-2 px-2'>
                                    <h5>
                                      {smartphone.brand} {smartphone.model}
                                    </h5>
                                  </Card.Text>
                                  <Card.Text className='text-center text-muted'>
                                    <h5>{smartphone.stock}</h5>
                                  </Card.Text>
                                  <Card.Text className='text-center'>
                                    <h5>
                                      Price: {smartphone.price || smartphone.variants[0]?.price}
                                      <TbCurrencyTaka
                                        className='mb-2'
                                        size={20}
                                      />
                                    </h5>
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </CardGroup>
                          </LinkContainer>
                        </motion.div>
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  )
}

export default BuySmartPhone
