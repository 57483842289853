import React from 'react'

const NotFoundScreen = () => {
  return (
    <div>
      <h1 className='text-center'>404: Page Not Found</h1>
      <p className='text-center'>
        We're sorry, the page you requested could not be found. Please go back
        to the homepage.
      </p>
    </div>
  )
}

export default NotFoundScreen
