import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import offersService from "./offersService";

const initialState = {
  offers: [],
  offer: null,

  isListError: false,
  isListLoading: false,
  isListSuccess: false,
  listErrorMessage: "",

  isCreateError: false,
  isCreateLoading: false,
  isCreateSuccess: false,
  createErrorMessage: "",

  isDeleteError: false,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  deleteErrorMessage: "",
};

//create offer
export const createOffer = createAsyncThunk(
  "offer/createOffer",
  async (offer, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const createdOffer = await offersService.createOffer(offer, token);
      return createdOffer;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all offers
export const getAllOffers = createAsyncThunk(
  "offer/getAllOffers",
  async (_, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const offers = await offersService.getAllOffers();
      return offers;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get offer by id
export const getOfferById = createAsyncThunk(
  "offer/getOfferById",
  async (id, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const offer = await offersService.getOfferById(id);
      return offer;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete offer
export const deleteOffer = createAsyncThunk(
  "offer/deleteOffer",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const deletedOffer = await offersService.deleteOffer(id, token);
      return deletedOffer;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    resetOfferCreate: (state) => {
      state.isCreateError = false;
      state.isCreateLoading = false;
      state.isCreateSuccess = false;
      state.createErrorMessage = "";
    },
    resetOfferList: (state) => {
      state.offers = [];
      state.isListError = false;
      state.isListLoading = false;
      state.isListSuccess = false;
      state.listErrorMessage = "";
    },
    resetOfferDelete: (state) => {
      state.isDeleteError = false;
      state.isDeleteLoading = false;
      state.isDeleteSuccess = false;
      state.deleteErrorMessage = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createOffer.pending, (state) => {
        state.isCreateLoading = true;
        state.isCreateSuccess = false;
        state.isCreateError = false;
        state.createErrorMessage = "";
      })
      .addCase(createOffer.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateSuccess = true;
        state.isCreateError = false;
        state.createErrorMessage = "";
        state.offers.push(action.payload);
        state.offer = action.payload;
      })
      .addCase(createOffer.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateSuccess = false;
        state.isCreateError = true;
        state.createErrorMessage = action.payload;
      })
      .addCase(getAllOffers.pending, (state) => {
        state.isListLoading = true;
        state.isListSuccess = false;
        state.isListError = false;
        state.listErrorMessage = "";
      })
      .addCase(getAllOffers.fulfilled, (state, action) => {
        state.isListLoading = false;
        state.isListSuccess = true;
        state.isListError = false;
        state.listErrorMessage = "";
        state.offers = action.payload;
      })
      .addCase(getAllOffers.rejected, (state, action) => {
        state.isListLoading = false;
        state.isListSuccess = false;
        state.isListError = true;
        state.listErrorMessage = action.payload;
      })
      .addCase(deleteOffer.pending, (state) => {
        state.isDeleteLoading = true;
        state.isDeleteSuccess = false;
        state.isDeleteError = false;
        state.deleteErrorMessage = "";
      })
      .addCase(deleteOffer.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = true;
        state.isDeleteError = false;
        state.deleteErrorMessage = "";
        state.offers = state.offers.filter(
          (offer) => offer._id !== action.payload
        );
        state.offer = null;
      })
      .addCase(deleteOffer.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteSuccess = false;
        state.isDeleteError = true;
        state.deleteErrorMessage = action.payload;
      });
  },
});

export const { resetOfferCreate, resetOfferList, resetOfferDelete } =
  offersSlice.actions;

export default offersSlice.reducer;
