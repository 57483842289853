import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tabletAddRequestService from "./tabletAddRequestService";

const initialState = {
  tabletAddRequests: [],
  tabletAddRequest: null,

  isListError: false,
  isListLoading: false,
  isListSuccess: false,
  listErrorMessage: "",

  isCreateError: false,
  isCreateLoading: false,
  isCreateSuccess: false,
  createErrorMessage: "",

  isDetailsError: false,
  isDetailsLoading: false,
  isDetailsSuccess: false,
  detailsErrorMessage: "",

  isUpdateError: false,
  isUpdateLoading: false,
  isUpdateSuccess: false,
  updateErrorMessage: "",

  isDeleteError: false,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  deleteErrorMessage: "",
};

//create tablet add request
export const createTabletAddRequest = createAsyncThunk(
  "tabletAddRequest/createTabletAddRequest",
  async (tabletAddRequest, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const createdTabletAddRequest =
        await tabletAddRequestService.createTabletAddRequest(
          tabletAddRequest,
          token
        );
      return createdTabletAddRequest;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all tablet add requests
export const getAllTabletAddRequests = createAsyncThunk(
  "tabletAddRequest/getAllTabletAddRequests",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const tabletAddRequests =
        await tabletAddRequestService.getAllTabletAddRequests(token);
      return tabletAddRequests;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get tablet add request by id
export const getTabletAddRequestById = createAsyncThunk(
  "tabletAddRequest/getTabletAddRequestById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const tabletAddRequest =
        await tabletAddRequestService.getTabletAddRequestById(id, token);
      return tabletAddRequest;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update tablet add request
export const updateTabletAddRequest = createAsyncThunk(
  "tabletAddRequest/updateTabletAddRequest",
  async (tabletAddRequest, thunkAPI) => {
    const { id, tabletAddRequestData } = tabletAddRequest;
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const updatedTabletAddRequest =
        await tabletAddRequestService.updateTabletAddRequest(
          id, 
          tabletAddRequestData,
          token
        );
      return updatedTabletAddRequest;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete tablet add request
export const deleteTabletAddRequest = createAsyncThunk(
  "tabletAddRequest/deleteTabletAddRequest",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const deletedTabletAddRequest =
        await tabletAddRequestService.deleteTabletAddRequest(id, token);
      return deletedTabletAddRequest;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const tabletAddRequestSlice = createSlice({
  name: "tabletAddRequest",
  initialState,
  reducers: {
    resetTabletAddRequestCreate: (state) => {
      state.isCreateError = false;
      state.isCreateLoading = false;
      state.isCreateSuccess = false;
      state.createErrorMessage = "";
    },
    resetTabletAddRequestList: (state) => {
      state.tabletAddRequests = [];
      state.isListError = false;
      state.isListLoading = false;
      state.isListSuccess = false;
      state.listErrorMessage = "";
    },
    resetTabletAddRequestDetails: (state) => {
      state.tabletAddRequest = null;
      state.isDetailsError = false;
      state.isDetailsLoading = false;
      state.isDetailsSuccess = false;
      state.detailsErrorMessage = "";
    },
    resetTabletAddRequestUpdate: (state) => {
      state.isUpdateError = false;
      state.isUpdateLoading = false;
      state.isUpdateSuccess = false;
      state.updateErrorMessage = "";
    },
    resetTabletAddRequestDelete: (state) => {
      state.isDeleteError = false;
      state.isDeleteLoading = false;
      state.isDeleteSuccess = false;
      state.deleteErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTabletAddRequest.pending, (state) => {
        state.isCreateLoading = true;
        state.isCreateError = false;
        state.isCreateSuccess = false;
        state.createErrorMessage = "";
      })
      .addCase(createTabletAddRequest.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = false;
        state.isCreateSuccess = true;
        state.createErrorMessage = "";
        state.tabletAddRequests.push(action.payload);
        state.tabletAddRequest = action.payload;
      })
      .addCase(createTabletAddRequest.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = true;
        state.isCreateSuccess = false;
        state.createErrorMessage = action.payload;
      })
      .addCase(getAllTabletAddRequests.pending, (state) => {
        state.isListLoading = true;
        state.isListError = false;
        state.isListSuccess = false;
        state.listErrorMessage = "";
      })
      .addCase(getAllTabletAddRequests.fulfilled, (state, action) => {
        state.isListLoading = false;
        state.isListError = false;
        state.isListSuccess = true;
        state.listErrorMessage = "";
        state.tabletAddRequests = action.payload;
      })
      .addCase(getAllTabletAddRequests.rejected, (state, action) => {
        state.isListLoading = false;
        state.isListError = true;
        state.isListSuccess = false;
        state.listErrorMessage = action.payload;
      })
      .addCase(getTabletAddRequestById.pending, (state) => {
        state.isDetailsLoading = true;
        state.isDetailsError = false;
        state.isDetailsSuccess = false;
        state.detailsErrorMessage = "";
      })
      .addCase(getTabletAddRequestById.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsError = false;
        state.isDetailsSuccess = true;
        state.detailsErrorMessage = "";
        state.tabletAddRequest = action.payload;
      })
      .addCase(getTabletAddRequestById.rejected, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsError = true;
        state.isDetailsSuccess = false;
        state.detailsErrorMessage = action.payload;
      })
      .addCase(updateTabletAddRequest.pending, (state) => {
        state.isUpdateLoading = true;
        state.isUpdateError = false;
        state.isUpdateSuccess = false;
        state.updateErrorMessage = "";
      })
      .addCase(updateTabletAddRequest.fulfilled, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = false;
        state.isUpdateSuccess = true;
        state.updateErrorMessage = "";
        state.tabletAddRequests = state.tabletAddRequests.map(
          (tabletAddRequest) =>
            tabletAddRequest.id === action.payload.id
              ? action.payload
              : tabletAddRequest
        );
        state.tabletAddRequest = action.payload;
      })
      .addCase(updateTabletAddRequest.rejected, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = true;
        state.isUpdateSuccess = false;
        state.updateErrorMessage = action.payload;
      })
      .addCase(deleteTabletAddRequest.pending, (state) => {
        state.isDeleteLoading = true;
        state.isDeleteError = false;
        state.isDeleteSuccess = false;
        state.deleteErrorMessage = "";
      })
      .addCase(deleteTabletAddRequest.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = false;
        state.isDeleteSuccess = true;
        state.deleteErrorMessage = "";
        state.tabletAddRequests = state.tabletAddRequests.filter(
          (tabletAddRequest) => tabletAddRequest.id !== action.payload.id
        );
        state.tabletAddRequest = null;
      })
      .addCase(deleteTabletAddRequest.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.isDeleteSuccess = false;
        state.deleteErrorMessage = action.payload;
      });
  },
});

export const {
    resetTabletAddRequestCreate,
    resetTabletAddRequestList,
    resetTabletAddRequestDetails,
    resetTabletAddRequestUpdate,
    resetTabletAddRequestDelete,
} = tabletAddRequestSlice.actions;


export default tabletAddRequestSlice.reducer;