import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
  Carousel,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import {
  updateWearable,
  getWearableById,
  deleteWearable,
  resetWearableUpdate,
  resetWearableDelete,
  resetWearableDetails,
} from "../features/wearable/wearableSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { AiTwotoneDelete, AiFillEdit } from "react-icons/ai";

const UpdateWearableScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { userInfo } = useSelector((state) => state.auth);

  const {
    wearable,

    isDetailsLoading,
    isDetailsError,
    isDetailsSuccess,
    detailsErrorMessage,

    isUpdateLoading,
    isUpdateError,
    isUpdateSuccess,
    updateErrorMessage,

    isDeleteLoading,
    isDeleteError,
    isDeleteSuccess,
    deleteErrorMessage,
  } = useSelector((state) => state.wearable);

  const [wearableBrand, setWearableBrand] = useState("");
  const [wearableModel, setWearableModel] = useState("");
  const [wearableSpecifications, setWearableSpecifications] = useState("");
  const [wearablePrice, setWearablePrice] = useState(0);
  const [wearableStock, setWearableStock] = useState("");
  const [wearableProductType, setWearableProductType] = useState("");
  const [wearableCoverImage, setWearableCoverImage] = useState("");
  const [wearableImages, setWearableImages] = useState([]);

  const [imagesToDelete, setImagesToDelete] = useState([]); // Images to be deleted from the server

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else if (userInfo.userType !== "admin") {
      navigate("/");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (isDetailsError) {
      toast.error(detailsErrorMessage, { position: "top-center" });
    } else if (isDetailsSuccess) {
      setWearableBrand(wearable.brand);
      setWearableModel(wearable.model);
      setWearableSpecifications(wearable.specifications);
      setWearablePrice(wearable.price);
      setWearableStock(wearable.stock);
      setWearableProductType(wearable.productType);
      setWearableCoverImage(wearable.coverImage);
      setWearableImages(wearable.images);
    } else {
      dispatch(getWearableById(params.id));
    }
  }, [isDetailsError, detailsErrorMessage, isDetailsSuccess]);

  useEffect(() => {
    if (isUpdateError) {
      toast.error(updateErrorMessage, { position: "top-center" });
    } else if (isUpdateSuccess) {
      toast.success("Wearable updated successfully", {
        position: "top-center",
      });
      navigate("/buyWearable");
    }
  }, [isUpdateError, updateErrorMessage, isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteError) {
      toast.error(deleteErrorMessage, { position: "top-center" });
    } else if (isDeleteSuccess) {
      toast.success("Wearable deleted successfully", {
        position: "top-center",
      });
      navigate("/buyWearable");
    }
  }, [isDeleteError, deleteErrorMessage, isDeleteSuccess]);

  useEffect(() => {
    return () => {
      dispatch(resetWearableDetails());
      dispatch(resetWearableUpdate());
      dispatch(resetWearableDelete());
    };
  }, [dispatch]);

  const updateHandler = () => {
    // If any images are to be deleted, remove them from the server
    if (imagesToDelete.length > 0) {
      imagesToDelete.forEach(async (image) => {
        await axios.post(`/api/v1/upload/delete/${image.slice(8)}`);
      });
    }

    const wearableData = {
      brand: wearableBrand,
      model: wearableModel,
      specifications: wearableSpecifications,
      price: wearablePrice,
      stock: wearableStock,
      productType: wearableProductType,
      coverImage: wearableCoverImage,
      images: wearableImages,
    };
    dispatch(updateWearable({ id: params.id, wearableData }));
  };
  const deleteHandler = () => {
    if (window.confirm("Are you sure you want to delete this device?")) {
      dispatch(deleteWearable(params.id));
    }
  };

  // Cover Image Upload
  const uploadCoverImageFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `/api/v1/upload${
          wearableCoverImage ? `/${wearableCoverImage.slice(8)}` : ""
        }`,
        formData,
        config
      );
      setWearableCoverImage(data);
    } catch (error) {
      console.error(error);
    }
  };

  // Multiple Image Upload
  const uploadImageFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(`/api/v1/upload/`, formData, config);

      setWearableImages([...wearableImages, data]);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteImageHandler = async (image) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      setImagesToDelete([...imagesToDelete, image]);

      setWearableImages(wearableImages.filter((img) => img !== image));
    }
  };

  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <>
        {isDetailsLoading || isUpdateLoading ? (
          <Loader />
        ) : (
          <Container>
            <Row className="py-5">
              <Card.Text as="h2" className="text-center">
                Update Wearable
              </Card.Text>
            </Row>

            <Form>
              <Row>
                <Col sm={12} md={4} lg={3}>
                  <Card className="mb-4 rounded shadow">
                    <Card.Header as="h4" className="p-3 text-center">
                      Cover Image
                    </Card.Header>
                    <Card.Body className="text-center">
                      <Card.Img
                        cascade
                        className="img-fluid rounded"
                        src={
                          wearableCoverImage !== ""
                            ? wearableCoverImage
                            : "https://via.placeholder.com/150"
                        }
                        style={{ height: "20vh", objectFit: "cover" }}
                      />
                      <Form.Group controlId="image 1">
                        <Form.Label className="my-2">
                          Upload Cover Image
                        </Form.Label>
                        <Form.Control
                          required
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Cover Image For Wearables"
                          controlId="wearableCoverImage"
                          onChange={uploadCoverImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Card className="mb-4 rounded shadow">
                    <Card.Header as="h4" className="p-3 text-center">
                      Other Images
                    </Card.Header>
                    <Card.Body className="text-center">
                      {wearableImages.length <= 0 ? (
                        <Card.Img
                          cascade
                          className="img-fluid rounded"
                          src="https://via.placeholder.com/150"
                          style={{ height: "20vh", objectFit: "cover" }}
                        />
                      ) : (
                        <Carousel indicators={false}>
                          {wearableImages.map((image, index) => (
                            <Carousel.Item>
                              <img
                                className="d-block w-100 rounded"
                                src={image}
                                alt={`Image-${index}`}
                                style={{
                                  maxHeight: "20vh",
                                  objectFit: "cover",
                                }}
                              />
                              {/* Image Delete Button */}
                              <Button
                                variant="outline-danger"
                                className="btn-sm mt-3 rounded"
                                onClick={() => deleteImageHandler(image)}
                              >
                                Delete Image
                              </Button>
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      )}

                      <Form.Group controlId="image 1">
                        <Form.Label className="my-2">
                          Upload New Images
                        </Form.Label>
                        <Form.Control
                          className="mb-3 rounded"
                          type="file"
                          id="image-file"
                          label="Images"
                          onChange={uploadImageFileHandler}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={12} md={8} lg={9}>
                  <Card className="mb-4 rounded shadow">
                    <Card.Header as="h4" className="p-3 text-center">
                      Wearable Details
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="wearableBrand"
                          >
                            <Form.Label className="small mb-1">
                              Wearable Brand
                            </Form.Label>
                            <Form.Control
                              required
                              className="rounded"
                              type="text"
                              placeholder={
                                wearableBrand === ""
                                  ? "Enter Wearable Brand"
                                  : "Brand Name is Required"
                              }
                              value={wearableBrand}
                              onChange={(e) => setWearableBrand(e.target.value)}
                            />
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="wearableModel"
                          >
                            <Form.Label className="small mb-1">
                              Wearable Model
                            </Form.Label>
                            <Form.Control
                              required
                              className="rounded"
                              type="text"
                              placeholder={
                                wearableModel === ""
                                  ? "Model Name is Required"
                                  : "Enter Wearable Model Name"
                              }
                              value={wearableModel}
                              onChange={(e) => setWearableModel(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="specifications"
                          >
                            <Form.Label className="small mb-1">
                              Other Specifications
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              type="textarea"
                              rows="5"
                              placeholder="Other Specifications"
                              value={wearableSpecifications}
                              onChange={(e) =>
                                setWearableSpecifications(e.target.value)
                              }
                              className="rounded"
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="wearablePrice"
                          >
                            <Form.Label className="small mb-1">
                              Price
                            </Form.Label>
                            <Form.Control
                              required
                              className="rounded"
                              type="number"
                              placeholder={
                                wearablePrice === ""
                                  ? "Price is Required"
                                  : "Enter Wearable Price"
                              }
                              value={wearablePrice}
                              onChange={(e) => setWearablePrice(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="wearableType">
                            <Form.Label className="small mb-1">
                              Product Type
                            </Form.Label>
                            <Form.Control
                              className="rounded"
                              required
                              as="select"
                              type="select"
                              placeholder={
                                wearableProductType === ""
                                  ? "Type is Required"
                                  : "Select Wearable Type"
                              }
                              value={wearableProductType}
                              onChange={(e) =>
                                setWearableProductType(e.target.value)
                              }
                            >
                              <option disabled value="">
                                Select Type
                              </option>
                              <option value="New">New</option>
                              <option value="Pre-Owned">Pre-Owned</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="wearableStock"
                          >
                            <Form.Label className="small mb-1">
                              Status
                            </Form.Label>
                            <Form.Control
                              required
                              className="rounded"
                              as="select"
                              type="select"
                              placeholder={
                                wearableStock === ""
                                  ? "Status is Required"
                                  : "Select Wearable Status"
                              }
                              value={wearableStock}
                              onChange={(e) => setWearableStock(e.target.value)}
                            >
                              <option disabled value="">
                                Select Status
                              </option>
                              <option value="In Stock">In Stock</option>
                              <option value="Out of Stock">Out of Stock</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="px-2 py-2">
                        <Col lg={6} md={6} sm={12}>
                          <Button
                            variant="outline-dark rounded"
                            onClick={deleteHandler}
                          >
                            <AiTwotoneDelete className="mb-1" size={18} />
                            Delete Wearable
                          </Button>
                        </Col>
                        <Col
                          lg={6}
                          md={6}
                          sm={12}
                          className="d-flex justify-content-end"
                        >
                          <Button
                            variant="outline-dark rounded"
                            onClick={updateHandler}
                          >
                            <AiFillEdit className="mb-1" size={18} />
                            Update Wearable
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      </>
    )
  );
};

export default UpdateWearableScreen;
