import React from "react";
import { motion } from "framer-motion";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const CategoriesToBuy = () => {
  return (
    <Container className="my-5">
      <h1 className="text-center mb-3">Check Our Available Products</h1>
      <Row>
        <Col lg={3} md={3} sm={12} className="py-2">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/buySmartPhone">
              <Card className="rounded cursorAll">
                <Card.Img
                  style={{ borderRadius: "12px" }}
                  cascade
                  className="img-fluid p-2"
                  src="/img/buysell/buyphone.png"
                />
                <Card.Body className="d-flex justify-content-center">
                  <Card.Title className="text-center">
                    BUY SMARTPHONES
                  </Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>

        <Col lg={3} md={3} sm={12} className="py-2">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/buyTablet">
              <Card className="rounded cursorAll">
                <Card.Img
                  style={{ borderRadius: "12px" }}
                  cascade
                  className="img-fluid p-2"
                  src="/img/buysell/buytablet.png"
                />
                <Card.Body cascade className="d-flex justify-content-center">
                  <Card.Title className="text-center">BUY TABLET</Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>

        <Col lg={3} md={3} sm={12} className="py-2">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/buyWearable">
              <Card className="rounded cursorAll">
                <Card.Img
                  style={{ borderRadius: "12px" }}
                  cascade
                  className="img-fluid p-2"
                  src="/img/buysell/buywearable.png"
                />
                <Card.Body className="d-flex justify-content-center">
                  <Card.Title className="text-center">BUY WEARABLES</Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>

        <Col lg={3} md={3} sm={12} className="py-2">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/buyGadget">
              <Card className="rounded cursorAll">
                <Card.Img
                  style={{ borderRadius: "12px" }}
                  cascade
                  className="img-fluid p-2"
                  src="/img/buysell/buygadget.png"
                />
                <Card.Body className="d-flex justify-content-center">
                  <Card.Title className="text-center">BUY GADGETS</Card.Title>
                </Card.Body>
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export default CategoriesToBuy;
