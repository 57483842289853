import axios from "axios";

//create tablet add request
const createTabletAddRequest = async (tabletAddRequest, token) => {
  const response = await axios.post(
    "/api/v1/tablets/add-request",
    tabletAddRequest,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data;
};

//get all tablet add requests
const getAllTabletAddRequests = async (token) => {
  const response = await axios.get(
    "/api/v1/tablets/add-request?sort=-createdAt",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data;
};

//get tablet add request by id
const getTabletAddRequestById = async (id, token) => {
  const response = await axios.get(`/api/v1/tablets/add-request/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

//update tablet add request
const updateTabletAddRequest = async (id, tabletAddRequestData, token) => {
  const response = await axios.patch(
    `/api/v1/tablets/add-request/${id}`,
    tabletAddRequestData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data;
};

//delete tablet add request
const deleteTabletAddRequest = async (id, token) => {
  const response = await axios.delete(`/api/v1/tablets/add-request/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

const tabletAddRequestService = {
  createTabletAddRequest,
  getAllTabletAddRequests,
  getTabletAddRequestById,
  updateTabletAddRequest,
  deleteTabletAddRequest,
};

export default tabletAddRequestService;
