import axios from "axios";

//create wearable add request
const createWearableAddRequest = async (wearableAddRequest, token) => {
  const response = await axios.post(
    "/api/v1/wearables/add-request",
    wearableAddRequest,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data;
};

//get all wearable add requests
const getAllWearableAddRequests = async (token) => {
  const response = await axios.get(
    "/api/v1/wearables/add-request?sort=-createdAt",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data;
};

//get wearable add request by id
const getWearableAddRequestById = async (id, token) => {
  const response = await axios.get(`/api/v1/wearables/add-request/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

//update wearable add request
const updateWearableAddRequest = async (id, wearableAddRequestData, token) => {
  const response = await axios.patch(
    `/api/v1/wearables/add-request/${id}`,
    wearableAddRequestData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data;
};

//delete wearable add request
const deleteWearableAddRequest = async (id, token) => {
  const response = await axios.delete(`/api/v1/wearables/add-request/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

const wearableAddRequestService = {
  createWearableAddRequest,
  getAllWearableAddRequests,
  getWearableAddRequestById,
  updateWearableAddRequest,
  deleteWearableAddRequest,
};

export default wearableAddRequestService;
