import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import navbarService from './navbarService'

const initialState = {
  navbars: [],
  navbar: null,

  isListError: false,
  isListLoading: false,
  isListSuccess: false,
  listErrorMessage: '',

  isCreateError: false,
  isCreateLoading: false,
  isCreateSuccess: false,
  createErrorMessage: '',

  isDeleteError: false,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  deleteErrorMessage: '',
}

//create navbar
export const createNavbar = createAsyncThunk(
  'navbar/createNavbar',
  async (navbar, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token
      const createdNavbar = await navbarService.createNavbar(navbar, token)
      return createdNavbar
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//get all navbars
export const getAllNavbars = createAsyncThunk(
  'navbar/getAllNavbars',
  async (_, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const navbars = await navbarService.getAllNavbars()
      return navbars
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//get navbar by id
export const getNavbarById = createAsyncThunk(
  'navbar/getNavbarById',
  async (id, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const navbar = await navbarService.getNavbarById(id)
      return navbar
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//delete navbar
export const deleteNavbar = createAsyncThunk(
  'navbar/deleteNavbar',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token
      const deletedNavbar = await navbarService.deleteNavbar(id, token)
      return deletedNavbar
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    resetNavbarCreate: (state) => {
      state.isCreateError = false
      state.isCreateLoading = false
      state.isCreateSuccess = false
      state.createErrorMessage = ''
    },
    resetNavbarList: (state) => {
      state.navbars = []
      state.isListError = false
      state.isListLoading = false
      state.isListSuccess = false
      state.listErrorMessage = ''
    },
    resetNavbarDelete: (state) => {
      state.isDeleteError = false
      state.isDeleteLoading = false
      state.isDeleteSuccess = false
      state.deleteErrorMessage = ''
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createNavbar.pending, (state) => {
        state.isCreateError = false
        state.isCreateLoading = true
        state.isCreateSuccess = false
        state.createErrorMessage = ''
      })
      .addCase(createNavbar.fulfilled, (state, action) => {
        state.isCreateError = false
        state.isCreateLoading = false
        state.isCreateSuccess = true
        state.createErrorMessage = ''
        state.navbars.push(action.payload)
        state.navbar = action.payload
      })
      .addCase(createNavbar.rejected, (state, action) => {
        state.isCreateError = true
        state.isCreateLoading = false
        state.isCreateSuccess = false
        state.createErrorMessage = action.payload
      })
      .addCase(getAllNavbars.pending, (state) => {
        state.isListError = false
        state.isListLoading = true
        state.isListSuccess = false
        state.listErrorMessage = ''
      })
      .addCase(getAllNavbars.fulfilled, (state, action) => {
        state.isListError = false
        state.isListLoading = false
        state.isListSuccess = true
        state.listErrorMessage = ''
        state.navbars = action.payload
      })
      .addCase(getAllNavbars.rejected, (state, action) => {
        state.isListError = true
        state.isListLoading = false
        state.isListSuccess = false
        state.listErrorMessage = action.payload
      })
      .addCase(deleteNavbar.pending, (state) => {
        state.isDeleteError = false
        state.isDeleteLoading = true
        state.isDeleteSuccess = false
        state.deleteErrorMessage = ''
      })
      .addCase(deleteNavbar.fulfilled, (state, action) => {
        state.isDeleteError = false
        state.isDeleteLoading = false
        state.isDeleteSuccess = true
        state.deleteErrorMessage = ''
        state.navbars = state.navbars.filter(
          (navbar) => navbar._id !== action.payload
        )
        state.navbar = null
      })
      .addCase(deleteNavbar.rejected, (state, action) => {
        state.isDeleteError = true
        state.isDeleteLoading = false
        state.isDeleteSuccess = false
        state.deleteErrorMessage = action.payload
      })
  },
})

export const { resetNavbarCreate, resetNavbarList, resetNavbarDelete } =
  navbarSlice.actions

export default navbarSlice.reducer
