import React,{useEffect} from 'react'
import { Container, Row, ListGroup } from "react-bootstrap";


const ReturnRefund = () => {
  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <>
        <Container id="about" fluid className="text-center pb-5">
          <Row
            style={{
              backgroundColor: "#4AA24A",
              paddingBottom: "15px",
              paddingTop: "20px",
            }}
          >
            <h2>Return & Refund Policy</h2>
          </Row>
          <Container>
            <Row className="mt-3">
              <ListGroup style={{ textAlign: "justify" }}>
                At Smart Think, we strive to provide excellent customer service
                and want you to be completely satisfied with your purchase. If
                for any reason you are not satisfied with your product, we offer
                a hassle-free return and refund policy.
                <ListGroup.Item variant="danger" className="m-2">
                  Returns: You may return any product within 14 days of
                  receiving it for a full refund or exchange. To initiate a
                  return, please contact our customer service team at
                  support@smartthink.com. You will be provided with a return
                  shipping label and instructions on how to return your product.{" "}
                  <br /> <br />
                  To be eligible for a return, your product must be in its
                  original condition and packaging, with all accessories
                  included. We reserve the right to refuse a return if the
                  product is damaged, used, or missing accessories.
                </ListGroup.Item>
                <ListGroup.Item variant="danger" className="m-2">
                  Refunds: Once we receive your returned product, we will
                  inspect it and process your refund within 3-5 business days.
                  Refunds will be issued to the original payment method used for
                  the purchase.
                  <br /> <br />
                  Please note that shipping fees are non-refundable, and you
                  will be responsible for any return shipping costs unless the
                  product was defective or damaged upon receipt.
                </ListGroup.Item>
                <ListGroup.Item variant="danger" className="m-2">
                  Exchanges: If you would like to exchange your product for a
                  different model or color, please contact our customer service
                  team at support@smartthink.com. We will provide you with
                  instructions on how to return your product and process the
                  exchange.
                </ListGroup.Item>
                <ListGroup.Item variant="danger" className="m-2">
                  Warranty: All of our products come with a manufacturer's
                  warranty, which covers defects in materials and workmanship.
                  If you experience any issues with your product during the
                  warranty period, please contact our customer service team for
                  assistance.
                  <br /> <br />
                  If the issue cannot be resolved, we will provide you with
                  instructions on how to return the product for a replacement or
                  refund.
                </ListGroup.Item>
                If you have any questions or concerns about our return and
                refund policy, please contact us at support@smartthink.com.
                We're always here to help.
              </ListGroup>
            </Row>
          </Container>
        </Container>
      </>
    )
  );
}

export default ReturnRefund