import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";

import {
  getAllSmartphones,
  resetSmartphoneList,
} from "../features/smartphone/smartphoneSlice";
import { getAllTablets, resetTabletList } from "../features/tablet/tabletSlice";
import {
  getAllWearables,
  resetWearableList,
} from "../features/wearable/wearableSlice";
import { getAllGadgets, resetGadgetList } from "../features/gadget/gadgetSlice";

import { toast } from "react-toastify";
import Loader from "./Loader";
import Message from "./Message";
import { TbCurrencyTaka } from "react-icons/tb";

const NewAvailableProduct = () => {
  const dispatch = useDispatch();

  const [allProducts, setAllProducts] = useState([]);

  const {
    smartphones,
    isListLoading: smartPhoneListLoading,
    isListError: smartphoneListError,
    ListErrorMessage: smartphoneListErrorMessage,
    isListSuccess: smartphoneListSuccess,
  } = useSelector((state) => state.smartphone);

  const {
    tablets,
    isListLoading: tabletListLoading,
    isListError: tabletListError,
    ListErrorMessage: tabletErrorMessage,
    isListSuccess: tabletListSuccess,
  } = useSelector((state) => state.tablet);

  const {
    wearables,
    isListLoading: wearableListLoading,
    isListError: wearableListError,
    ListErrorMessage: wearableErrorMessage,
    isListSuccess: wearableListSuccess,
  } = useSelector((state) => state.wearable);

  const {
    gadgets,
    isListLoading: gadgetListLoading,
    isListError: gadgetListError,
    ListErrorMessage: gadgetErrorMessage,
    isListSuccess: gadgetListSuccess,
  } = useSelector((state) => state.gadget);

  useEffect(() => {
    if (smartphoneListError) {
      // toast.error(smartphoneListErrorMessage, { position: "top-center" });
    } else if (smartphoneListSuccess) {
      setAllProducts([...allProducts, ...smartphones]);
    } else {
      dispatch(getAllSmartphones());
    }
  }, [
    dispatch,
    smartphones,
    smartPhoneListLoading,
    smartphoneListError,
    smartphoneListErrorMessage,
  ]);

  useEffect(() => {
    if (tabletListError) {
      // toast.error(tabletErrorMessage, { position: "top-center" });
    } else if (tabletListSuccess) {
      setAllProducts([...allProducts, ...tablets]);
    } else {
      dispatch(getAllTablets());
    }
  }, [
    dispatch,
    tablets,
    tabletListLoading,
    tabletListError,
    tabletErrorMessage,
  ]);

  useEffect(() => {
    if (wearableListError) {
      // toast.error(wearableErrorMessage, { position: "top-center" });
    } else if (wearableListSuccess) {
      setAllProducts([...allProducts, ...wearables]);
    } else {
      dispatch(getAllWearables());
    }
  }, [
    dispatch,
    wearables,
    wearableListLoading,
    wearableListError,
    wearableErrorMessage,
  ]);

  useEffect(() => {
    if (gadgetListError) {
      // toast.error(gadgetErrorMessage, { position: "top-center" });
    } else if (gadgetListSuccess) {
      setAllProducts([...allProducts, ...gadgets]);
    } else {
      dispatch(getAllGadgets());
    }
  }, [
    dispatch,
    gadgets,
    gadgetListLoading,
    gadgetListError,
    gadgetErrorMessage,
  ]);

  useEffect(() => {}, [allProducts]);

  useEffect(() => {
    return () => {
      dispatch(resetSmartphoneList());
      dispatch(resetTabletList());
      dispatch(resetWearableList());
      dispatch(resetGadgetList());
    };
  }, [dispatch]);

  return (
    <Container className="my-5 pt-3">
      <h1 className="text-center mb-4">New Available Products</h1>
      <Row>
        {allProducts.length <= 0 ? (
          <Message variant="danger">No Products Found</Message>
        ) : (
          <>
            {allProducts.map(
              (product, index) =>
                index < 8 && (
                  <Col lg={3} md={4} sm={12}>
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <LinkContainer
                        to={
                          product.deviceType === "SmartPhone"
                            ? `/smartPhoneDetails/${product._id}`
                            : product.deviceType === "Tablet"
                            ? `/tabletDetails/${product._id}`
                            : product.deviceType === "Wearable"
                            ? `/wearableDetails/${product._id}`
                            : product.deviceType === "Gadget"
                            ? `/gadgetDetails/${product._id}`
                            : ""
                        }
                      >
                        <Card
                          key={product._id}
                          className="mb-3 shadow rounded cursorAll"
                        >
                          <Card.Img
                            cascade
                            className="img-fluid p-2 newAvailableProducts"
                            src={product.coverImage}
                          />
                          <Card.Body className="newAvailableProductsBody">
                            <Card.Text className="text-center mb-2 px-2">
                              <h4>
                                {product.brand !== undefined &&
                                product.model !== undefined
                                  ? product.brand + " " + product.model
                                  : product.gadgetName !== ""
                                  ? product.gadgetName
                                  : ""}
                              </h4>
                            </Card.Text>
                            <Card.Text className="text-center text-muted">
                              <h5>{product.stock}</h5>
                            </Card.Text>
                            <Card.Text className="text-center">
                              <h5>
                                Price : {product.price}
                                <TbCurrencyTaka className="mb-2" size={20} />
                              </h5>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </LinkContainer>
                    </motion.div>
                  </Col>
                )
            )}
          </>
        )}
      </Row>

      {/* <Row className="py-2 px-2">
        <LinkContainer to="/">
          <Button variant="primary" size="sm" className="rounded">
            <b>All New Available Products</b>
          </Button>
        </LinkContainer>
      </Row> */}
    </Container>
  );
};

export default NewAvailableProduct;
