import axios from 'axios'

// create offer
const createOffer = async (offer, token) => {
  const response = await axios.post('/api/v1/offers', offer, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

// get all offers
const getAllOffers = async () => {
  const response = await axios.get('/api/v1/offers?sort=-createdAt')
  return response.data.data
}

// get offer by id
const getOfferById = async (id) => {
  const response = await axios.get(`/api/v1/offers/${id}`)
  return response.data.data
}

// delete offer
const deleteOffer = async (id, token) => {
  const response = await axios.delete(`/api/v1/offers/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}

const offerService = {
  createOffer,
  getAllOffers,
  getOfferById,
  deleteOffer,
}

export default offerService
