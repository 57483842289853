import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tabletService from "./tabletService";

const initialState = {
  tablets: [],
  tablet: null,

  isListError: false,
  isListLoading: false,
  isListSuccess: false,
  listErrorMessage: "",

  isCreateError: false,
  isCreateLoading: false,
  isCreateSuccess: false,
  createErrorMessage: "",

  isDetailsError: false,
  isDetailsLoading: false,
  isDetailsSuccess: false,
  detailsErrorMessage: "",

  isUpdateError: false,
  isUpdateLoading: false,
  isUpdateSuccess: false,
  updateErrorMessage: "",

  isDeleteError: false,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  deleteErrorMessage: "",
};

//create tablet
export const createTablet = createAsyncThunk(
  "tablet/createTablet",
  async (tablet, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const createdTablet = await tabletService.createTablet(tablet, token);
      return createdTablet;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all tablets
export const getAllTablets = createAsyncThunk(
  "tablet/getAllTablets",
  async (_, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const tablets = await tabletService.getAllTablets();
      return tablets;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get tablet by id
export const getTabletById = createAsyncThunk(
  "tablet/getTabletById",
  async (id, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const tablet = await tabletService.getTabletById(id);
      return tablet;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update tablet
export const updateTablet = createAsyncThunk(
  "tablet/updateTablet",
  async (tablet, thunkAPI) => {
    const { id, tabletData } = tablet;
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const updatedTablet = await tabletService.updateTablet(
        id,
        tabletData,
        token
      );
      return updatedTablet;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete tablet
export const deleteTablet = createAsyncThunk(
  "tablet/deleteTablet",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const deletedTablet = await tabletService.deleteTablet(id, token);
      return deletedTablet;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const tabletSlice = createSlice({
  name: "tablet",
  initialState,
  reducers: {
    resetTabletCreate: (state) => {
      state.isCreateError = false;
      state.isCreateLoading = false;
      state.isCreateSuccess = false;
      state.createErrorMessage = "";
    },
    resetTabletList: (state) => {
      state.tablets = [];
      state.isListError = false;
      state.isListLoading = false;
      state.isListSuccess = false;
      state.listErrorMessage = "";
    },
    resetTabletDetails: (state) => {
      state.tablet = null;
      state.isDetailsError = false;
      state.isDetailsLoading = false;
      state.isDetailsSuccess = false;
      state.detailsErrorMessage = "";
    },
    resetTabletUpdate: (state) => {
      state.isUpdateError = false;
      state.isUpdateLoading = false;
      state.isUpdateSuccess = false;
      state.updateErrorMessage = "";
    },
    resetTabletDelete: (state) => {
      state.isDeleteError = false;
      state.isDeleteLoading = false;
      state.isDeleteSuccess = false;
      state.deleteErrorMessage = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createTablet.pending, (state) => {
        state.isCreateLoading = true;
        state.isCreateError = false;
        state.isCreateSuccess = false;
        state.createErrorMessage = "";
      })
      .addCase(createTablet.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = false;
        state.isCreateSuccess = true;
        state.createErrorMessage = "";
        state.tablets.push(action.payload);
        state.tablet = action.payload;
      })
      .addCase(createTablet.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = true;
        state.isCreateSuccess = false;
        state.createErrorMessage = action.payload;
      })
      .addCase(getAllTablets.pending, (state) => {
        state.isListLoading = true;
        state.isListError = false;
        state.isListSuccess = false;
        state.listErrorMessage = "";
      })
      .addCase(getAllTablets.fulfilled, (state, action) => {
        state.isListLoading = false;
        state.isListError = false;
        state.isListSuccess = true;
        state.listErrorMessage = "";
        state.tablets = action.payload;
      })
      .addCase(getAllTablets.rejected, (state, action) => {
        state.isListLoading = false;
        state.isListError = true;
        state.isListSuccess = false;
        state.listErrorMessage = action.payload;
      })
      .addCase(getTabletById.pending, (state) => {
        state.isDetailsLoading = true;
        state.isDetailsError = false;
        state.isDetailsSuccess = false;
        state.detailsErrorMessage = "";
      })
      .addCase(getTabletById.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsError = false;
        state.isDetailsSuccess = true;
        state.detailsErrorMessage = "";
        state.tablet = action.payload;
      })
      .addCase(getTabletById.rejected, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsError = true;
        state.isDetailsSuccess = false;
        state.detailsErrorMessage = action.payload;
      })
      .addCase(updateTablet.pending, (state) => {
        state.isUpdateLoading = true;
        state.isUpdateError = false;
        state.isUpdateSuccess = false;
        state.updateErrorMessage = "";
      })
      .addCase(updateTablet.fulfilled, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = false;
        state.isUpdateSuccess = true;
        state.updateErrorMessage = "";
        state.tablets = state.tablets.map((tablet) => {
          if (tablet._id === action.payload._id) {
            return action.payload;
          }
          return tablet;
        });
        state.tablet = action.payload;
      })
      .addCase(updateTablet.rejected, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = true;
        state.isUpdateSuccess = false;
        state.updateErrorMessage = action.payload;
      })
      .addCase(deleteTablet.pending, (state) => {
        state.isDeleteLoading = true;
        state.isDeleteError = false;
        state.isDeleteSuccess = false;
        state.deleteErrorMessage = "";
      })
      .addCase(deleteTablet.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = false;
        state.isDeleteSuccess = true;
        state.deleteErrorMessage = "";
        state.tablets = state.tablets.filter(
          (tablet) => tablet._id !== action.payload
        );
        state.tablet = null;
      })
      .addCase(deleteTablet.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.isDeleteSuccess = false;
        state.deleteErrorMessage = action.payload;
      });
  },
});

export const {
  resetTabletCreate,
  resetTabletList,
  resetTabletDetails,
  resetTabletUpdate,
  resetTabletDelete,
} = tabletSlice.actions;

export default tabletSlice.reducer;
