import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  ListGroup,
  Carousel,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createWearableAddRequest,
  resetWearableAddRequestCreate,
} from "../features/wearableaddrequest/wearableAddRequestSlice";

const CustomizedWearablesAddRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);

  const {
    wearableAddRequest,
    isCreateError,
    isCreateLoading,
    isCreateSuccess,
    createErrorMessage,
  } = useSelector((state) => state.wearableAddRequest);

  //States
  const [wearableBrand, setWearableBrand] = useState("");
  const [wearableModel, setWearableModel] = useState("");
  // const [used, setUsed] = useState(0);
  const [comments, setComments] = useState("");

  // const [wearableImage, setWearableImage] = useState("");

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
      toast.error("You Need To Login To Create a Customized Wearable Device Add Request", {position : 'top-center'})
    }
  })

  useEffect(() => {
    if (isCreateSuccess) {
      toast.success("Wearable Add Request Created Successfully", {
        position: "top-center",
      });
      navigate("/userDashboard");
    } else if (isCreateError) {
      toast.error(createErrorMessage, { position: "top-center" });
    }
  }, [isCreateSuccess, isCreateError, createErrorMessage, navigate, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetWearableAddRequestCreate());
    };
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createWearableAddRequest({
        brand: wearableBrand,
        model: wearableModel,
        // used,
        comments,
        // wearableImage,
        user: userInfo._id,
      })
    );
  };

  return (
    <Container>
      <Row className="py-5">
        <Card.Text as="h2" className="text-center">
          Add Request For Wearables
        </Card.Text>
      </Row>

      <Form onSubmit={submitHandler}>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <Card className="mb-4 shadow rounded">
              <Card.Header className='text-center'>Product Information</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="wearableBrand">
                      <Form.Label className="small mb-1">
                        Wearable Brand
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder={
                          wearableBrand === ""
                            ? "Brand Name is Required"
                            : "Enter Wearable Brand Name"
                        }
                        value={wearableBrand}
                        onChange={(e) => setWearableBrand(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <Form.Group className="mb-3" controlId="wearableModel">
                      <Form.Label className="small mb-1">
                        Wearable Model
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder={
                          wearableModel === ""
                            ? "Model Name is Required"
                            : "Enter Wearable Model Name"
                        }
                        value={wearableModel}
                        onChange={(e) => setWearableModel(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col lg={12} md={12} sm={12}>
                    <Form.Group className="mb-3" controlId="comments">
                      <Form.Label className="small mb-1">
                        Comments Regarding The Product
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows="3"
                        placeholder="Any Comments Regarding The Product and It's Condition"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-grid px-2">
                  <Button variant="outline-dark" type="submit" className="rounded">
                    Create Wearable
                  </Button>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default CustomizedWearablesAddRequest;
