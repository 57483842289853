import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import gadgetService from './gadgetService'

const initialState = {
  gadgets: [],
  gadget: null,

  isListError: false,
  isListLoading: false,
  isListSuccess: false,
  listErrorMessage: '',

  isCreateError: false,
  isCreateLoading: false,
  isCreateSuccess: false,
  createErrorMessage: '',

  isDetailsError: false,
  isDetailsLoading: false,
  isDetailsSuccess: false,
  detailsErrorMessage: '',

  isUpdateError: false,
  isUpdateLoading: false,
  isUpdateSuccess: false,
  updateErrorMessage: '',

  isDeleteError: false,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  deleteErrorMessage: '',
}

//create gadget
export const createGadget = createAsyncThunk(
  'gadget/createGadget',
  async (gadget, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token
      const createdGadget = await gadgetService.createGadget(gadget, token)
      return createdGadget
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//get all gadgets
export const getAllGadgets = createAsyncThunk(
  'gadget/getAllGadgets',
  async (_, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const gadgets = await gadgetService.getAllGadgets()
      return gadgets
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//get gadget by id
export const getGadgetById = createAsyncThunk(
  'gadget/getGadgetById',
  async (id, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const gadget = await gadgetService.getGadgetById(id)
      return gadget
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//update gadget
export const updateGadget = createAsyncThunk(
  'gadget/updateGadget',
  async (gadget, thunkAPI) => {
    const { id, gadgetData } = gadget
    try {
      const token = thunkAPI.getState().auth.userInfo.token
      const updatedGadget = await gadgetService.updateGadget(
        id,
        gadgetData,
        token
      )
      return updatedGadget
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//delete gadget
export const deleteGadget = createAsyncThunk(
  'gadget/deleteGadget',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token
      const deletedGadget = await gadgetService.deleteGadget(id, token)
      return deletedGadget
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const gadgetSlice = createSlice({
  name: 'gadget',
  initialState,
  reducers: {
    resetGadgetCreate: (state) => {
      state.isCreateError = false
      state.isCreateLoading = false
      state.isCreateSuccess = false
      state.createErrorMessage = ''
    },
    resetGadgetList: (state) => {
      state.gadgets = []
      state.isListError = false
      state.isListLoading = false
      state.isListSuccess = false
      state.listErrorMessage = ''
    },
    resetGadgetDetails: (state) => {
      state.gadget = null
      state.isDetailsError = false
      state.isDetailsLoading = false
      state.isDetailsSuccess = false
      state.detailsErrorMessage = ''
    },
    resetGadgetUpdate: (state) => {
      state.isUpdateError = false
      state.isUpdateLoading = false
      state.isUpdateSuccess = false
      state.updateErrorMessage = ''
    },
    resetGadgetDelete: (state) => {
      state.isDeleteError = false
      state.isDeleteLoading = false
      state.isDeleteSuccess = false
      state.deleteErrorMessage = ''
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createGadget.pending, (state) => {
        state.isCreateLoading = true
        state.isCreateError = false
        state.isCreateSuccess = false
        state.createErrorMessage = ''
      })
      .addCase(createGadget.fulfilled, (state, action) => {
        state.isCreateLoading = false
        state.isCreateError = false
        state.isCreateSuccess = true
        state.createErrorMessage = ''
        state.gadgets.push(action.payload)
        state.gadget = action.payload
      })
      .addCase(createGadget.rejected, (state, action) => {
        state.isCreateLoading = false
        state.isCreateError = true
        state.isCreateSuccess = false
        state.createErrorMessage = action.payload
      })
      .addCase(getAllGadgets.pending, (state) => {
        state.isListLoading = true
        state.isListError = false
        state.isListSuccess = false
        state.listErrorMessage = ''
      })
      .addCase(getAllGadgets.fulfilled, (state, action) => {
        state.isListLoading = false
        state.isListError = false
        state.isListSuccess = true
        state.listErrorMessage = ''
        state.gadgets = action.payload
      })
      .addCase(getAllGadgets.rejected, (state, action) => {
        state.isListLoading = false
        state.isListError = true
        state.isListSuccess = false
        state.listErrorMessage = action.payload
      })
      .addCase(getGadgetById.pending, (state) => {
        state.isDetailsLoading = true
        state.isDetailsError = false
        state.isDetailsSuccess = false
        state.detailsErrorMessage = ''
      })
      .addCase(getGadgetById.fulfilled, (state, action) => {
        state.isDetailsLoading = false
        state.isDetailsError = false
        state.isDetailsSuccess = true
        state.detailsErrorMessage = ''
        state.gadget = action.payload
      })
      .addCase(getGadgetById.rejected, (state, action) => {
        state.isDetailsLoading = false
        state.isDetailsError = true
        state.isDetailsSuccess = false
        state.detailsErrorMessage = action.payload
      })
      .addCase(updateGadget.pending, (state) => {
        state.isUpdateLoading = true
        state.isUpdateError = false
        state.isUpdateSuccess = false
        state.updateErrorMessage = ''
      })
      .addCase(updateGadget.fulfilled, (state, action) => {
        state.isUpdateLoading = false
        state.isUpdateError = false
        state.isUpdateSuccess = true
        state.updateErrorMessage = ''
        state.gadgets = state.gadgets.map((gadget) => {
          if (gadget._id === action.payload._id) {
            return action.payload
          }
          return gadget
        })
        state.gadget = action.payload
      })
      .addCase(updateGadget.rejected, (state, action) => {
        state.isUpdateLoading = false
        state.isUpdateError = true
        state.isUpdateSuccess = false
        state.updateErrorMessage = action.payload
      })
      .addCase(deleteGadget.pending, (state) => {
        state.isDeleteLoading = true
        state.isDeleteError = false
        state.isDeleteSuccess = false
        state.deleteErrorMessage = ''
      })
      .addCase(deleteGadget.fulfilled, (state, action) => {
        state.isDeleteLoading = false
        state.isDeleteError = false
        state.isDeleteSuccess = true
        state.deleteErrorMessage = ''
        state.gadgets = state.gadgets.filter(
          (gadget) => gadget._id !== action.payload._id
        )
        state.gadget = null
      })
      .addCase(deleteGadget.rejected, (state, action) => {
        state.isDeleteLoading = false
        state.isDeleteError = true
        state.isDeleteSuccess = false
        state.deleteErrorMessage = action.payload
      })
  },
})

export const {
  resetGadgetCreate,
  resetGadgetList,
  resetGadgetDetails,
  resetGadgetUpdate,
  resetGadgetDelete,
} = gadgetSlice.actions

export default gadgetSlice.reducer
