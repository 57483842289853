import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import CategoriesToBuyNav from "../components/CategoriesToBuyNav";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Card,
  Button,
} from "react-bootstrap";
import {
  getSmartphoneById,
  resetSmartphoneDetails,
} from "../features/smartphone/smartphoneSlice";
import {
  getTabletById,
  resetTabletDetails,
} from "../features/tablet/tabletSlice";
import {
  createEvaluation,
  resetEvaluationCreate,
} from "../features/evaluation/evaluationSlice";

import { toast } from "react-toastify";
import Loader from "../components/Loader";

const spots = [
  {
    label: "Heavy",
    value: "Heavy",
    imageSrc: "/img/Evaluation/Smartphone/1.png",
  },
  {
    label: "Major",
    value: "Major",
    imageSrc: "/img/Evaluation/Smartphone/2.png",
  },
  {
    label: "Minor",
    value: "Minor",
    imageSrc: "/img/Evaluation/Smartphone/3.png",
  },
  {
    label: "No Spot",
    value: "No Spot",
    imageSrc: "/img/Evaluation/Smartphone/28.png",
  },
];

const lines = [
  {
    label: "Heavy",
    value: "Heavy",
    imageSrc: "/img/Evaluation/Smartphone/5.png",
  },
  {
    label: "Major",
    value: "Major",
    imageSrc: "/img/Evaluation/Smartphone/6.png",
  },
  {
    label: "Minor",
    value: "Minor",
    imageSrc: "/img/Evaluation/Smartphone/4.png",
  },
  {
    label: "None",
    value: "None",
    imageSrc: "/img/Evaluation/Smartphone/28.png",
  },
];

const screens = [
  {
    label: "Heavy Crack",
    value: "Heavy Crack",
    imageSrc: "/img/Evaluation/Smartphone/7.png",
  },
  {
    label: "Major Crack",
    value: "Major Crack",
    imageSrc: "/img/Evaluation/Smartphone/8.png",
  },
  {
    label: "Minor Crack",
    value: "Minor Crack",
    imageSrc: "/img/Evaluation/Smartphone/9.png",
  },
  {
    label: "Scratch",
    value: "Scratch",
    imageSrc: "/img/Evaluation/Smartphone/10.png",
  },
  {
    label: "Changed",
    value: "Changed",
    imageSrc: "/img/Evaluation/Smartphone/11.png",
  },
  {
    label: "Original & No Issue",
    value: "Original & No Issue",
    imageSrc: "/img/Evaluation/Smartphone/28.png",
  },
];

const bodys = [
  {
    label: "Major",
    value: "Major",
    imageSrc: "/img/Evaluation/Smartphone/14.png",
  },
  {
    label: "Minor",
    value: "Minor",
    imageSrc: "/img/Evaluation/Smartphone/15.png",
  },
  {
    label: "None",
    value: "None",
    imageSrc: "/img/Evaluation/Smartphone/26.png",
  },
];

const backPanels = [
  {
    label: "Heavy",
    value: "Heavy",
    imageSrc: "/img/Evaluation/Smartphone/17.png",
  },
  {
    label: "Major",
    value: "Major",
    imageSrc: "/img/Evaluation/Smartphone/18.png",
  },
  {
    label: "Minor",
    value: "Minor",
    imageSrc: "/img/Evaluation/Smartphone/19.png",
  },
  {
    label: "Cracked",
    value: "Cracked",
    imageSrc: "/img/Evaluation/Smartphone/20.png",
  },
  {
    label: "None",
    value: "None",
    imageSrc: "/img/Evaluation/Smartphone/27.png",
  },
];
const cameras = [
  {
    label: "Dust",
    value: "Dust",
    imageSrc: "/img/Evaluation/Smartphone/22.png",
  },
  {
    label: "Scratch",
    value: "Scratch",
    imageSrc: "/img/Evaluation/Smartphone/23.png",
  },
  {
    label: "No Issue",
    value: "No Issue",
    imageSrc: "/img/Evaluation/Smartphone/24.png",
  },
];

const ProductEvaluationScreen = () => {
  // const [deviceType, setDeviceType] = useState("");
  // const [smartPhoneev, setSmartPhone] = useState("");
  // const [tabletev, setTablet] = useState("");
  // const [wearableev, setWearable] = useState("");

  const [receiveCalls, setReceiveCalls] = useState("");
  const [availableAccessories, setAvailableAccessories] = useState([]);
  const [warranty, setWarranty] = useState("");
  const [touchScreen, setTouchScreen] = useState("");
  // const [deadPixels, setDeadPixels] = useState("");
  // const [lineOnDisplay, setLineOnDisplay] = useState("");
  // const [screenCondition, setScreenCondition] = useState("");
  // const [bodyCondition, setBodyCondition] = useState("");
  // const [dent, setDent] = useState("");
  // const [backPanel, setBackPanel] = useState("");
  // const [cameraCondition, setCameraCondition] = useState("");
  const [batteryHealth, setBatteryHealth] = useState("");
  const [otherIssues, setOtherIssues] = useState("");

  const [selectedSpot, setSelectedSpot] = useState(spots[0].value);
  const [selectedLine, setSelectedLine] = useState(lines[0].value);
  const [selectedScreen, setSelectedScreen] = useState(screens[0].value);
  const [bodysConditions, setBodysConditions] = useState(bodys[0].value);
  const [bodyBackPanel, setBodyBackPanel] = useState(backPanels[0].value);
  const [camerasCondition, setCamerasCondition] = useState(cameras[0].value);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const { userInfo } = useSelector((state) => state.auth);
  const [smartPhoneDetails, setSmartPhoneDetails] = useState(null);
  const [tabletDetails, setTabletDetails] = useState(null);

  const [searchParams] = useSearchParams();

  const {
    smartphone,

    isDetailsLoading: isSmartPhoneDetailsLoading,
    isDetailsError: isSmartPhoneDetailsError,
    isDetailsSuccess: isSmartPhoneDetailsSuccess,
    detailsErrorMessage: isSmartPhoneErrorMessage,
  } = useSelector((state) => state.smartphone);

  const {
    tablet,

    isDetailsLoading: isTabletDetailsLoading,
    isDetailsError: isTabletDetailsError,
    isDetailsSuccess: isTabletDetailsSuccess,
    detailsErrorMessage: isTabletErrorMessage,
  } = useSelector((state) => state.tablet);

  const {
    evaluation,

    isCreateError,
    isCreateSuccess,
    createErrorMessage,
    isCreateLoading,
  } = useSelector((state) => state.evaluation);

  useEffect(() => {
    if (!userInfo) {
      toast.error("You Need To Login to Perform This Action!", {
        position: "top-center",
      });
      navigate("/");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (isSmartPhoneDetailsError) {
      if (isSmartPhoneErrorMessage !== "No smartphones Found with that ID") {
        toast.error(isSmartPhoneErrorMessage, { position: "top-center" });
      }
    } else if (isSmartPhoneDetailsSuccess) {
      setSmartPhoneDetails(smartphone);
    } else {
      dispatch(getSmartphoneById(id));
    }
  }, [
    dispatch,
    id,
    isSmartPhoneDetailsError,
    isSmartPhoneDetailsSuccess,
    isSmartPhoneErrorMessage,
    smartPhoneDetails,
  ]);

  useEffect(() => {
    if (isTabletDetailsError) {
      if (isTabletErrorMessage !== "No tablets Found with that ID") {
        toast.error(isTabletErrorMessage, { position: "top-center" });
      }
    } else if (isTabletDetailsSuccess) {
      setTabletDetails(tablet);
    } else {
      dispatch(getTabletById(id));
    }
  }, [
    dispatch,
    id,
    isTabletDetailsError,
    isTabletDetailsSuccess,
    isTabletErrorMessage,
    tabletDetails,
  ]);

  useEffect(() => {
    if (isCreateSuccess) {
      toast.success(
        "Evaluation Form Submitted Successfully. If Approved, Admin Will Contact You Shortly.",
        { position: "top-center" }
      );
      navigate("/userDashboard");
    } else if (isCreateError) {
      toast.error(createErrorMessage, { position: "top-center" });
    }
  }, [
    isCreateSuccess,
    isCreateError,
    createErrorMessage,
    evaluation,
    navigate,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetSmartphoneDetails());
      dispatch(resetTabletDetails());
      dispatch(resetEvaluationCreate());
    };
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      receiveCalls !== "" &&
      availableAccessories !== "" &&
      warranty !== "" &&
      touchScreen !== "" &&
      selectedSpot !== "" &&
      selectedLine !== "" &&
      selectedScreen !== "" &&
      bodysConditions !== "" &&
      // dent === "" ||
      bodyBackPanel !== "" &&
      camerasCondition !== "" &&
      // batteryHealth !== "" &&
      otherIssues !== ""
    ) {
      // toast.error("Please Fill All The Fields", { position: "top-center" });
      dispatch(
        createEvaluation({
          deviceType: smartPhoneDetails ? "SmartPhone" : "Tablet",
          receiveCalls,
          availableAccessories,
          warranty,
          screen: {
            touchScreen,
            deadPixels: selectedSpot,
            lineOnDisplay: selectedLine,
            screenCondition: selectedScreen,
          },
          body: {
            bodyCondition: bodysConditions,
            // dent,
            backPanel: bodyBackPanel,
          },

          cameraCondition: camerasCondition,
          batteryHealth,
          otherIssues,

          smartPhone: smartPhoneDetails ? smartPhoneDetails._id : null,
          tablet: tabletDetails ? tabletDetails._id : null,

          user: userInfo._id,
        })
      );
      if (searchParams.get("requestid")) {
        dispatch();
      }
    } else {
      toast.error("Please Fill All The Fields", { position: "top-center" });
    }
  };

  const handleOptionSpot = (event) => {
    setSelectedSpot(event.target.value);
  };
  const handleOptionLine = (event) => {
    setSelectedLine(event.target.value);
  };
  const handleOptionScreen = (event) => {
    setSelectedScreen(event.target.value);
  };
  const handleOptionBody = (event) => {
    setBodysConditions(event.target.value);
  };
  const handleOptionPanel = (event) => {
    setBodyBackPanel(event.target.value);
  };
  const handleOptionCameras = (event) => {
    setCamerasCondition(event.target.value);
  };

  // console.log(otherIssues);
  return (
    <>
      <Container fluid>
        <CategoriesToBuyNav />
      </Container>

      <Container>
        <Row>
          {isSmartPhoneDetailsLoading || isTabletDetailsLoading ? (
            <Loader />
          ) : (
            <>
              {!tabletDetails && smartPhoneDetails ? (
                <Col sm={12} md={12} lg={3}>
                  <Row
                    className="my-5 shadow rounded border-0"
                    key={smartPhoneDetails._id}
                  >
                    <Card className="border-0">
                      <Card.Img
                        cascade
                        className="img-fluid p-3 rounded border-0"
                        src={smartPhoneDetails.coverImage}
                        // style={{ height: "15vh", width: "15vh" }}
                      />

                      {/* <Col lg={9} md={9} sm={10}> */}
                      <Row className="p-3">
                        <Card.Text as="h4" className="mb-3 text-center">
                          {smartPhoneDetails.brand} {smartPhoneDetails.model}
                        </Card.Text>
                        <Card.Text as="h5">
                          RAM : {smartPhoneDetails.variants[0].RAM} GB
                        </Card.Text>
                        <Card.Text as="h5">
                          ROM : {smartPhoneDetails.variants[0].ROM} GB
                        </Card.Text>
                        <Card.Text as="h5">
                          Est. Selling Price :{" "}
                          {smartPhoneDetails.variants[0].sellingPrice} BDT
                        </Card.Text>
                      </Row>
                      {/* </Col> */}
                    </Card>
                  </Row>
                </Col>
              ) : tabletDetails && !smartPhoneDetails ? (
                <Col sm={12} md={12} lg={3}>
                  <Row
                    className="my-5 shadow rounded border-0"
                    key={tabletDetails._id}
                  >
                    <Card className="border-0">
                      <Card.Img
                        cascade
                        className="img-fluid p-3 rounded border-0"
                        src={tabletDetails.coverImage}
                        // style={{ height: "15vh", width: "15vh" }}
                      />

                      <Row className="p-3">
                        <Card.Text as="h4" className="mb-3 text-center">
                          {tabletDetails.brand} {tabletDetails.model}
                        </Card.Text>
                        <Card.Text as="h5">
                          RAM : {tabletDetails.variants[0].RAM} GB
                        </Card.Text>
                        <Card.Text as="h5">
                          ROM : {tabletDetails.variants[0].ROM} GB
                        </Card.Text>
                        <Card.Text as="h5">
                          Est. Selling Price :{" "}
                          {tabletDetails.variants[0].sellingPrice} BDT
                        </Card.Text>
                      </Row>
                    </Card>
                  </Row>
                </Col>
              ) : // </Row>
              null}
            </>
          )}

          {/* <Row> */}
          <Col lg={9} md={12} sm={12} className="my-5">
            <Card className=" mb-5 shadow rounded border-0">
              <Row className="my-5 text-center">
                <h2>Product Evaluation</h2>
              </Row>

              <Form onSubmit={submitHandler}>
                <Row className="d-grid px-3">
                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <h5 className="m-2">
                        1. Can you make and receive calls?
                      </h5>
                    </Row>
                    <InputGroup className="mb-3 mx-4" controlId="receiveCalls">
                      <Col sm={5} md={3} lg={3}>
                        <Form.Check
                          className="m-2"
                          type="radio"
                          label="Yes"
                          // name="formHorizontalRadios"
                          value="Yes"
                          checked={receiveCalls === "Yes"}
                          onChange={(e) => setReceiveCalls(e.target.value)}
                        />
                      </Col>
                      <Col sm={7} md={9} lg={9}>
                        <Form.Check
                          className="m-2"
                          type="radio"
                          label="No"
                          // name="formHorizontalRadios"
                          value="No"
                          checked={receiveCalls === "No"}
                          onChange={(e) => setReceiveCalls(e.target.value)}
                        />
                      </Col>
                    </InputGroup>
                  </Col>

                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <h5 className="m-2">
                        2. Which of the following accessories do you have with
                        your device?
                      </h5>
                    </Row>
                    <Row>
                      <InputGroup
                        className="mb-3 mx-4"
                        controlId="availableAccessories"
                      >
                        <Col sm={12} md={4} lg={4}>
                          <Form.Check
                            className="m-2"
                            type="checkbox"
                            label="Original Charger Available"
                            value="Original Charger Available"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setAvailableAccessories([
                                  ...availableAccessories,
                                  e.target.value,
                                ]);
                              } else {
                                setAvailableAccessories(
                                  availableAccessories.filter(
                                    (item) => item !== e.target.value
                                  )
                                );
                              }
                            }}
                            // name="formHorizontalCheckbox"
                            checked={availableAccessories.includes(
                              "Original Charger Available"
                            )}
                          />
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                          <Form.Check
                            className="m-2"
                            type="checkbox"
                            label="IMEI Matched Box Available"
                            value="IMEI Matched Box Available"
                            checked={availableAccessories.includes(
                              "IMEI Matched Box Available"
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setAvailableAccessories([
                                  ...availableAccessories,
                                  e.target.value,
                                ]);
                              } else {
                                setAvailableAccessories(
                                  availableAccessories.filter(
                                    (item) => item !== e.target.value
                                  )
                                );
                              }
                            }}
                            // name="formHorizontalCheckbox"
                          />
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                          <Form.Check
                            className="m-2"
                            type="checkbox"
                            label="Money Receipt Available"
                            value="Money Receipt Available"
                            checked={availableAccessories.includes(
                              "Money Receipt Available"
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setAvailableAccessories([
                                  ...availableAccessories,
                                  e.target.value,
                                ]);
                              } else {
                                setAvailableAccessories(
                                  availableAccessories.filter(
                                    (item) => item !== e.target.value
                                  )
                                );
                              }
                            }}
                            // name="formHorizontalCheckbox"
                          />
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                          <Form.Check
                            className="m-2"
                            type="checkbox"
                            label="Only Device Available"
                            value="Only Device Available"
                            checked={availableAccessories.includes(
                              "Only Device Available"
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setAvailableAccessories([
                                  ...availableAccessories,
                                  e.target.value,
                                ]);
                              } else {
                                setAvailableAccessories(
                                  availableAccessories.filter(
                                    (item) => item !== e.target.value
                                  )
                                );
                              }
                            }}
                            // name="formHorizontalCheckbox"
                          />
                        </Col>
                      </InputGroup>
                    </Row>
                  </Col>

                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <h5 className="m-2">
                        3. Warranty available of your device?
                      </h5>
                    </Row>
                    <Row>
                      <InputGroup className="mb-3 mx-4" controlId="warranty">
                        <Col sm={12} md={4} lg={4}>
                          <Form.Check
                            className="m-2"
                            type="radio"
                            label="Less Than 3 Months"
                            value="Less Than 3 Months"
                            checked={warranty === "Less Than 3 Months"}
                            onChange={(e) => setWarranty(e.target.value)}
                            // name="formHorizontalRadios"
                          />
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                          <Form.Check
                            className="m-2"
                            type="radio"
                            label="More Than 3 Months"
                            value="More Than 3 Months"
                            checked={warranty === "More Than 3 Months"}
                            onChange={(e) => setWarranty(e.target.value)}
                            // name="formHorizontalRadios"
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Check
                            className="m-2"
                            type="radio"
                            label="No Warranty Available"
                            value="No Warranty Available"
                            checked={warranty === "No Warranty Available"}
                            onChange={(e) => setWarranty(e.target.value)}
                            // name="formHorizontalRadios"
                          />
                        </Col>
                      </InputGroup>
                    </Row>
                  </Col>

                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <h5 className="m-2">
                        4. Does Your Touchscreen Works Properly?
                      </h5>
                    </Row>
                    <Row>
                      <InputGroup className="mb-3 mx-4" controlId="touchScreen">
                        <Col sm={6} md={2} lg={2}>
                          <Form.Check
                            className="m-2"
                            type="radio"
                            label="Yes"
                            value="Yes"
                            checked={touchScreen === "Yes"}
                            onChange={(e) => setTouchScreen(e.target.value)}
                            // name="formHorizontalRadios"
                          />
                        </Col>
                        <Col sm={6} md={10} lg={10}>
                          <Form.Check
                            className="m-2"
                            type="radio"
                            label="No"
                            value="No"
                            checked={touchScreen === "No"}
                            onChange={(e) => setTouchScreen(e.target.value)}
                            // name="formHorizontalRadios"
                          />
                        </Col>
                      </InputGroup>
                    </Row>
                  </Col>

                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <h5 className="m-2">5. Is there Any Spot on display?</h5>
                    </Row>
                    {spots.map((spot) => (
                      <Card
                        key={spot.value}
                        className="d-inline-block mr-3 m-3 mx-2 rounded text-center"
                        style={{
                          width: "150px",
                          borderRadius: "10px",
                          border:
                            selectedSpot === spot.value
                              ? "1px solid green"
                              : "none",
                          boxShadow:
                            selectedSpot === spot.value
                              ? "0px 0px 10px 2px green"
                              : "0px 0px 0px 1px grey",
                        }}
                        onClick={() => setSelectedSpot(spot.value)}
                      >
                        <Form.Check
                          type="radio"
                          name="imageOption"
                          label=""
                          value={spot.value}
                          checked={selectedSpot === spot.value}
                          onChange={handleOptionSpot}
                          style={{ display: "none" }}
                        />
                        <Card.Img
                          src={spot.imageSrc}
                          style={{ borderRadius: "10px" }}
                        />
                        <Card.Body>
                          <Card.Title>{spot.label}</Card.Title>
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>

                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <h5 className="m-2">6. Is there Any Line on display?</h5>
                    </Row>
                    {lines.map((line) => (
                      <Card
                        key={line.value}
                        className="d-inline-block mr-3 m-3 mx-2 rounded text-center"
                        style={{
                          width: "150px",
                          borderRadius: "10px",
                          border:
                            selectedLine === line.value
                              ? "1px solid green"
                              : "none",
                          boxShadow:
                            selectedLine === line.value
                              ? "0px 0px 10px 2px green"
                              : "0px 0px 0px 1px grey",
                        }}
                        onClick={() => setSelectedLine(line.value)}
                      >
                        <Form.Check
                          type="radio"
                          name="imageOption"
                          label=""
                          value={line.value}
                          checked={selectedLine === line.value}
                          onChange={handleOptionLine}
                          style={{ display: "none" }}
                        />
                        <Card.Img
                          src={line.imageSrc}
                          style={{ borderRadius: "10px" }}
                        />
                        <Card.Body>
                          <Card.Title>{line.label}</Card.Title>
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>

                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <h5 className="m-2">
                        7. How's the condition of the Screen?
                      </h5>
                    </Row>
                    {screens.map((screen) => (
                      <Card
                        key={screen.value}
                        className="d-inline-block mr-3 m-3 mx-2 rounded text-center"
                        style={{
                          width: "150px",
                          borderRadius: "10px",
                          border:
                            selectedScreen === screen.value
                              ? "1px solid green"
                              : "none",
                          boxShadow:
                            selectedScreen === screen.value
                              ? "0px 0px 10px 2px green"
                              : "0px 0px 0px 1px grey",
                        }}
                        onClick={() => setSelectedScreen(screen.value)}
                      >
                        <Form.Check
                          type="radio"
                          name="imageOption"
                          label=""
                          value={screen.value}
                          checked={selectedScreen === screen.value}
                          onChange={handleOptionScreen}
                          style={{ display: "none" }}
                        />
                        <Card.Img
                          src={screen.imageSrc}
                          style={{ borderRadius: "10px" }}
                        />
                        <Card.Body>
                          <Card.Title>{screen.label}</Card.Title>
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>

                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <h5 className="m-2">
                        8. Is there Any Scratch or dent on Device Body?
                      </h5>
                    </Row>
                    {bodys.map((body) => (
                      <Card
                        key={body.value}
                        className="d-inline-block mr-3 m-3 mx-2 rounded text-center"
                        style={{
                          width: "150px",
                          borderRadius: "10px",
                          border:
                            bodysConditions === body.value
                              ? "1px solid green"
                              : "none",
                          boxShadow:
                            bodysConditions === body.value
                              ? "0px 0px 10px 2px green"
                              : "0px 0px 0px 1px grey",
                        }}
                        onClick={() => setBodysConditions(body.value)}
                      >
                        <Form.Check
                          type="radio"
                          name="imageOption"
                          label=""
                          value={body.value}
                          checked={selectedLine === body.value}
                          onChange={handleOptionBody}
                          style={{ display: "none" }}
                        />
                        <Card.Img
                          src={body.imageSrc}
                          style={{ borderRadius: "10px" }}
                        />
                        <Card.Body>
                          <Card.Title>{body.label}</Card.Title>
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>

                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <h5 className="m-2">
                        9. How's the condition of side & back panel?
                      </h5>
                    </Row>
                    {backPanels.map((backAndFront) => (
                      <Card
                        key={backAndFront.value}
                        className="d-inline-block mr-3 m-3 mx-2 rounded text-center"
                        style={{
                          width: "150px",
                          borderRadius: "10px",
                          border:
                            bodyBackPanel === backAndFront.value
                              ? "1px solid green"
                              : "none",
                          boxShadow:
                            bodyBackPanel === backAndFront.value
                              ? "0px 0px 10px 2px green"
                              : "0px 0px 0px 1px grey",
                        }}
                        onClick={() => setBodyBackPanel(backAndFront.value)}
                      >
                        <Form.Check
                          type="radio"
                          name="imageOption"
                          label=""
                          value={backAndFront.value}
                          checked={selectedLine === backAndFront.value}
                          onChange={handleOptionPanel}
                          style={{ display: "none" }}
                        />
                        <Card.Img
                          src={backAndFront.imageSrc}
                          style={{ borderRadius: "10px" }}
                        />
                        <Card.Body>
                          <Card.Title>{backAndFront.label}</Card.Title>
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>

                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <h5 className="m-2">
                        10. How's physical condition of camera?
                      </h5>
                    </Row>
                    {cameras.map((camera) => (
                      <Card
                        key={camera.value}
                        className="d-inline-block mr-3 m-3 mx-2 rounded text-center"
                        style={{
                          width: "150px",
                          borderRadius: "10px",
                          border:
                            camerasCondition === camera.value
                              ? "1px solid green"
                              : "none",
                          boxShadow:
                            camerasCondition === camera.value
                              ? "0px 0px 10px 2px green"
                              : "0px 0px 0px 1px grey",
                        }}
                        onClick={() => setCamerasCondition(camera.value)}
                      >
                        <Form.Check
                          type="radio"
                          name="imageOption"
                          label=""
                          value={camera.value}
                          checked={selectedLine === camera.value}
                          onChange={handleOptionCameras}
                          style={{ display: "none" }}
                        />
                        <Card.Img
                          src={camera.imageSrc}
                          style={{ borderRadius: "10px" }}
                        />
                        <Card.Body>
                          <Card.Title>{camera.label}</Card.Title>
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>

                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <h5 className="m-2">
                        11. How's The battery Health of Your Device?
                      </h5>
                    </Row>

                    <InputGroup className="mb-3 mx-4" controlId="batteryHealth">
                      <Col sm={12} md={3} lg={3}>
                        <Form.Check
                          className="m-2"
                          type="radio"
                          label="100%"
                          value="100%"
                          checked={batteryHealth === "100%"}
                          onChange={(e) => setBatteryHealth(e.target.value)}
                          // name="formHorizontalRadios"
                        />
                      </Col>
                      <Col sm={12} md={9} lg={9}>
                        <Form.Check
                          className="m-2"
                          type="radio"
                          label="95-99%"
                          value="95-99%"
                          checked={batteryHealth === "95-99%"}
                          onChange={(e) => setBatteryHealth(e.target.value)}
                          // name="formHorizontalRadios"
                        />
                      </Col>
                      <Col sm={12} md={3} lg={3}>
                        <Form.Check
                          className="m-2"
                          type="radio"
                          label="90-94%"
                          value="90-94%"
                          checked={batteryHealth === "90-94%"}
                          onChange={(e) => setBatteryHealth(e.target.value)}
                          // name="formHorizontalRadios"
                        />
                      </Col>
                      <Col sm={12} md={9} lg={9}>
                        <Form.Check
                          className="m-2"
                          type="radio"
                          label="85-89%"
                          value="85-89%"
                          checked={batteryHealth === "85-89%"}
                          onChange={(e) => setBatteryHealth(e.target.value)}
                          // name="formHorizontalRadios"
                        />
                      </Col>
                      <Col sm={12} md={3} lg={3}>
                        <Form.Check
                          className="m-2"
                          type="radio"
                          label="80-84%"
                          value="80-84%"
                          checked={batteryHealth === "80-84%"}
                          onChange={(e) => setBatteryHealth(e.target.value)}
                          // name="formHorizontalRadios"
                        />
                      </Col>
                      <Col sm={12} md={9} lg={9}>
                        <Form.Check
                          className="m-2"
                          type="radio"
                          label="Below 80%"
                          value="Below 80%"
                          checked={batteryHealth === "Below 80%"}
                          onChange={(e) => setBatteryHealth(e.target.value)}
                          // name="formHorizontalRadios"
                        />
                      </Col>
                    </InputGroup>
                  </Col>

                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <h5 className="m-2">
                        12. Any Other Issues on your device?
                      </h5>
                    </Row>

                    <InputGroup className="mb-3 mx-4" controlId="otherIssues">
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Front Camera Not Working"
                          value="Front Camera Not Working"
                          checked={otherIssues.includes(
                            "Front Camera Not Working"
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                          // name="formHorizontalCheckbox"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Back Camera Not Working"
                          value="Back Camera Not Working"
                          checked={otherIssues.includes(
                            "Back Camera Not Working"
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                          // name="formHorizontalCheckbox"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Speaker Not Working"
                          value="Speaker Not Working"
                          checked={otherIssues.includes("Speaker Not Working")}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                          name="formHorizontalCheckbox"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Microphone Not Working"
                          value="Microphone Not Working"
                          checked={otherIssues.includes(
                            "Microphone Not Working"
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                          name="formHorizontalCheckbox"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Power/Volume Button Not Working"
                          value="Power/Volume Button Not Working"
                          checked={otherIssues.includes(
                            "Power/Volume Button Not Working"
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                          // name="formHorizontalCheckbox"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Wifi Not Working"
                          value="Wifi Not Working"
                          checked={otherIssues.includes("Wifi Not Working")}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                          // name="formHorizontalCheckbox"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Bluetooth Not Working"
                          value="Bluetooth Not Working"
                          checked={otherIssues.includes(
                            "Bluetooth Not Working"
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                          // name="formHorizontalCheckbox"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Fingerprint Not Working"
                          value="Fingerprint Not Working"
                          checked={otherIssues.includes(
                            "Fingerprint Not Working"
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}

                          // name="formHorizontalCheckbox"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Sensors Not Working"
                          value="Sensors Not Working"
                          checked={otherIssues.includes("Sensors Not Working")}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                          // name="formHorizontalCheckbox"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Earpiece Not Working"
                          value="Earpiece Not Working"
                          checked={otherIssues.includes("Earpiece Not Working")}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                          // name="formHorizontalCheckbox"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Vibration Not Working"
                          value="Vibration Not Working"
                          checked={otherIssues.includes(
                            "Vibration Not Working"
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                          // name="formHorizontalCheckbox"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Face ID Not Working"
                          value="Face ID Not Working"
                          checked={otherIssues.includes("Face ID Not Working")}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                          // name="formHorizontalCheckbox"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Changing Port Not Working"
                          value="Changing Port Not Working"
                          checked={otherIssues.includes(
                            "Changing Port Not Working"
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                          // name="formHorizontalCheckbox"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Form.Check
                          className="m-2"
                          type="checkbox"
                          label="Headphone Jack Not Working"
                          value="Headphone Jack Not Working"
                          checked={otherIssues.includes(
                            "Headphone Jack Not Working"
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOtherIssues([...otherIssues, e.target.value]);
                            } else {
                              setOtherIssues(
                                otherIssues.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                          // name="formHorizontalCheckbox"
                        />
                      </Col>
                    </InputGroup>
                  </Col>

                  <Col sm={12} md={12} lg={12} className="mt-2 mb-4">
                    <Button
                      variant="outline-dark"
                      type="submit"
                      className="shadow rounded"
                    >
                      Submit Form
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        {/* </Row> */}
      </Container>
    </>
  );
};

export default ProductEvaluationScreen;
