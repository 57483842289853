import React from 'react'
import CategoriesToSellNav from '../components/CategoriesToSellNav'
import TabletBrands from '../components/tablets/TabletBrands'
import CategoriesToBuyNav from '../components/CategoriesToBuyNav'

const SellTabletScreen = () => {
  return (
    <div>
        <CategoriesToBuyNav/>
        <TabletBrands/>
    </div>
  )
}

export default SellTabletScreen