import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Card, Button, Carousel } from "react-bootstrap";
import Loader from "../Loader";
import ImageViewer from "react-simple-image-viewer";
import { FaFacebookSquare, FaWhatsappSquare } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";

const WearableDetails = ({ deviceType, device }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <Container>
        {!device ? (
          <Loader />
        ) : (
          <>
            <Row className="my-5" key={device._id}>
              <h2 className="d-flex justify-content-center mb-5">
                Device Specifications
              </h2>

              <h4 className="d-flex h4-responsive text-center justify-content-center py-2 mb-5">
                {device.brand} {device.model}
              </h4>

              <Col sm={12} md={5} lg={4}>
                <Row>
                  <Card className="align-items-center border-0">
                    <Card.Img
                      cascade
                      className="img-fluid pb-3 rounded"
                      src={device.coverImage}
                    />
                  </Card>
                </Row>

                <Row>
                  {device.images.map((image, index) => (
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <Row className="align-items-center pb-4">
                        <img
                          src={image}
                          onClick={() => openImageViewer(index)}
                          key={index}
                          alt={`Image-${index}`}
                        />
                      </Row>
                    </Col>
                  ))}

                  {isViewerOpen && (
                    <ImageViewer
                      src={device.images}
                      currentIndex={currentImage}
                      disableScroll={false}
                      closeOnClickOutside={true}
                      onClose={closeImageViewer}
                      backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.8)",
                      }}
                    />
                  )}
                </Row>
              </Col>

              <Col sm={12} md={7} lg={8}>
                <Card className="p-4 shadow rounded">
                  <h3 className="d-flex justify-content-center">
                    Specifications
                  </h3>
                  <Card.Body>
                    <h4 className="mt-3">Details</h4>
                    <Row>
                      <Col sm={12} md={12} lg={12} className="py-3">
                        <Card.Text className="">
                          {device.specifications}
                        </Card.Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6} md={6} sm={12}>
                        <h4 className="mt-5">Status</h4>

                        <Card.Text>{device.stock}</Card.Text>
                      </Col>
                      <Col lg={6} md={6} sm={12}>
                        <h4 className="mt-5">Price</h4>

                        <Card.Text>{device.price} BDT</Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className="d-flex justify-content-end flex-column align-items-end mt-5"
                >
                  <Row className="mb-3">
                    <h4 className="">Contact To Buy</h4>
                  </Row>

                  <Row>
                    <h5 className="d-flex gap-4">
                      <a className="social-icon" href="tel:+880 1302-610088">
                        <FiPhoneCall size="28" />
                      </a>

                      <a
                        className="social-icon"
                        target="blank"
                        href="https://www.facebook.com/smartthinkRTC"
                      >
                        <FaFacebookSquare color="#3b5998" size="28" />
                      </a>

                      <a
                        className="social-icon"
                        target="blank"
                        href="https://wa.me/+8801767821115"
                      >
                        <FaWhatsappSquare color="#11ba0b" size="28" />
                      </a>
                    </h5>
                  </Row>
                </Col>
              </Row>
            </Row>
          </>
        )}
      </Container>
    )
  );
};

export default WearableDetails;
