import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import smartphoneAddRequestService from "./smartphoneAddRequestService";

const initialState = {
  smartphoneAddRequests: [],
  smartphoneAddRequest: null,

  isListError: false,
  isListLoading: false,
  isListSuccess: false,
  listErrorMessage: "",

  isCreateError: false,
  isCreateLoading: false,
  isCreateSuccess: false,
  createErrorMessage: "",

  isDetailsError: false,
  isDetailsLoading: false,
  isDetailsSuccess: false,
  detailsErrorMessage: "",

  isUpdateError: false,
  isUpdateLoading: false,
  isUpdateSuccess: false,
  updateErrorMessage: "",

  isDeleteError: false,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  deleteErrorMessage: "",
};

//create smartphone add request
export const createSmartphoneAddRequest = createAsyncThunk(
  "smartphoneAddRequest/createSmartphoneAddRequest",
  async (smartphoneAddRequest, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const createdSmartphoneAddRequest =
        await smartphoneAddRequestService.createSmartphoneAddRequest(
          smartphoneAddRequest,
          token
        );
      return createdSmartphoneAddRequest;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all smartphone add requests
export const getAllSmartphoneAddRequests = createAsyncThunk(
  "smartphoneAddRequest/getAllSmartphoneAddRequests",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const smartphoneAddRequests =
        await smartphoneAddRequestService.getAllSmartphoneAddRequests(token);
      return smartphoneAddRequests;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get smartphone add request by id
export const getSmartphoneAddRequestById = createAsyncThunk(
  "smartphoneAddRequest/getSmartphoneAddRequestById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const smartphoneAddRequest =
        await smartphoneAddRequestService.getSmartphoneAddRequestById(
          id,
          token
        );
      return smartphoneAddRequest;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update smartphone add request
export const updateSmartphoneAddRequest = createAsyncThunk(
  "smartphoneAddRequest/updateSmartphoneAddRequest",
  async (smartphoneAddRequest, thunkAPI) => {
    const { id, smartphoneAddRequestData } = smartphoneAddRequest;
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const updatedSmartphoneAddRequest =
        await smartphoneAddRequestService.updateSmartphoneAddRequest(
          id,
          smartphoneAddRequestData,
          token
        );
      return updatedSmartphoneAddRequest;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete smartphone add request
export const deleteSmartphoneAddRequest = createAsyncThunk(
  "smartphoneAddRequest/deleteSmartphoneAddRequest",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const deletedSmartphoneAddRequest =
        await smartphoneAddRequestService.deleteSmartphoneAddRequest(id, token);
      return deletedSmartphoneAddRequest;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const smartphoneAddRequestSlice = createSlice({
  name: "smartphoneAddRequest",
  initialState,
  reducers: {
    resetSmartphoneAddRequestCreate: (state) => {
      state.isCreateError = false;
      state.isCreateLoading = false;
      state.isCreateSuccess = false;
      state.createErrorMessage = "";
    },
    resetSmartphoneAddRequestList: (state) => {
      state.smartphoneAddRequests = [];
      state.isListError = false;
      state.isListLoading = false;
      state.isListSuccess = false;
      state.listErrorMessage = "";
    },
    resetSmartphoneAddRequestDetails: (state) => {
      state.smartphoneAddRequest = null;
      state.isDetailsError = false;
      state.isDetailsLoading = false;
      state.isDetailsSuccess = false;
      state.detailsErrorMessage = "";
    },
    resetSmartphoneAddRequestUpdate: (state) => {
      state.isUpdateError = false;
      state.isUpdateLoading = false;
      state.isUpdateSuccess = false;
      state.updateErrorMessage = "";
    },
    resetSmartphoneAddRequestDelete: (state) => {
      state.isDeleteError = false;
      state.isDeleteLoading = false;
      state.isDeleteSuccess = false;
      state.deleteErrorMessage = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createSmartphoneAddRequest.pending, (state) => {
        state.isCreateLoading = true;
        state.isCreateError = false;
        state.isCreateSuccess = false;
        state.createErrorMessage = "";
      })
      .addCase(createSmartphoneAddRequest.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = false;
        state.isCreateSuccess = true;
        state.createErrorMessage = "";
        state.smartphoneAddRequests.push(action.payload);
        state.smartphoneAddRequest = action.payload;
      })
      .addCase(createSmartphoneAddRequest.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = true;
        state.isCreateSuccess = false;
        state.createErrorMessage = action.payload;
      })
      .addCase(getAllSmartphoneAddRequests.pending, (state) => {
        state.isListLoading = true;
        state.isListError = false;
        state.isListSuccess = false;
        state.listErrorMessage = "";
      })
      .addCase(getAllSmartphoneAddRequests.fulfilled, (state, action) => {
        state.isListLoading = false;
        state.isListError = false;
        state.isListSuccess = true;
        state.listErrorMessage = "";
        state.smartphoneAddRequests = action.payload;
      })
      .addCase(getAllSmartphoneAddRequests.rejected, (state, action) => {
        state.isListLoading = false;
        state.isListError = true;
        state.isListSuccess = false;
        state.listErrorMessage = action.payload;
      })
      .addCase(getSmartphoneAddRequestById.pending, (state) => {
        state.isDetailsLoading = true;
        state.isDetailsError = false;
        state.isDetailsSuccess = false;
        state.detailsErrorMessage = "";
      })
      .addCase(getSmartphoneAddRequestById.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsError = false;
        state.isDetailsSuccess = true;
        state.detailsErrorMessage = "";
        state.smartphoneAddRequest = action.payload;
      })
      .addCase(getSmartphoneAddRequestById.rejected, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsError = true;
        state.isDetailsSuccess = false;
        state.detailsErrorMessage = action.payload;
      })
      .addCase(updateSmartphoneAddRequest.pending, (state) => {
        state.isUpdateLoading = true;
        state.isUpdateError = false;
        state.isUpdateSuccess = false;
        state.updateErrorMessage = "";
      })
      .addCase(updateSmartphoneAddRequest.fulfilled, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = false;
        state.isUpdateSuccess = true;
        state.updateErrorMessage = "";
        state.smartphoneAddRequests = state.smartphoneAddRequests.map(
          (smartphoneAddRequest) => {
            if (smartphoneAddRequest._id === action.payload._id) {
              return action.payload;
            }
            return smartphoneAddRequest;
          }
        );
        state.smartphoneAddRequest = action.payload;
      })
      .addCase(updateSmartphoneAddRequest.rejected, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = true;
        state.isUpdateSuccess = false;
        state.updateErrorMessage = action.payload;
      })
      .addCase(deleteSmartphoneAddRequest.pending, (state) => {
        state.isDeleteLoading = true;
        state.isDeleteError = false;
        state.isDeleteSuccess = false;
        state.deleteErrorMessage = "";
      })
      .addCase(deleteSmartphoneAddRequest.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = false;
        state.isDeleteSuccess = true;
        state.deleteErrorMessage = "";
        state.smartphoneAddRequests = state.smartphoneAddRequests.filter(
          (smartphoneAddRequest) =>
            smartphoneAddRequest._id !== action.payload._id
        );
        state.smartphoneAddRequest = null;
      })
      .addCase(deleteSmartphoneAddRequest.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.isDeleteSuccess = false;
        state.deleteErrorMessage = action.payload;
      });
  },
});

export const {
  resetSmartphoneAddRequestCreate,
  resetSmartphoneAddRequestList,
  resetSmartphoneAddRequestDetails,
  resetSmartphoneAddRequestUpdate,
  resetSmartphoneAddRequestDelete,
} = smartphoneAddRequestSlice.actions;

export default smartphoneAddRequestSlice.reducer;
