import React from "react";
import { motion } from "framer-motion";
import { Container, Row, Col, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const wearableBrands = () => {
  return (
    <Container>
      <h2
        style={{ fontWeight: "bold" }}
        className="h1-responsive my-5 pt-3 text-center"
      >
        Select Wearable Brands
      </h2>
      <Row>
        <Col sm={4} md={3} lg={2}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/wearablesList/Apple">
              <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                <Card.Img
                  style={{ height: "80px", width: "80px" }}
                  cascade
                  className="img-fluid"
                  src="./img/smartphoneBrands/apple.png"
                />
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>

        <Col sm={4} md={3} lg={2}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/wearablesList/Samsung">
              <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                <Card.Img
                  style={{ height: "80px", width: "80px" }}
                  cascade
                  className="img-fluid"
                  src="./img/smartphoneBrands/samsung.png"
                />
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>

        <Col sm={4} md={3} lg={2}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/wearablesList/Xiaomi">
              <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                <Card.Img
                  style={{ height: "80px", width: "80px" }}
                  cascade
                  className="img-fluid"
                  src="./img/smartphoneBrands/xiaomi.png"
                />
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>

        <Col sm={4} md={3} lg={2}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <LinkContainer to="/wearablesList/Huawei">
              <Card className="d-flex align-items-center mb-2 shadow mx-4 rounded-3">
                <Card.Img
                  style={{ height: "80px", width: "80px" }}
                  cascade
                  className="img-fluid"
                  src="./img/smartphoneBrands/huawei.png"
                />
              </Card>
            </LinkContainer>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export default wearableBrands;
