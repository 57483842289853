import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { updateNewUser } from '../features/auth/authSlice'
import { toast } from 'react-toastify'

const LoginProfile = ({
  showLoginProfile,
  setShowLoginProfile,
  // globalPhone,
}) => {
  const dispatch = useDispatch()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const updateNewUserHandler = () => {
    if (firstName === '' || lastName === '') {
      toast.error('First name and Last name are required', {
        position: 'top-center',
      })
      return
    }
    dispatch(updateNewUser({ firstName, lastName, email, newUser: false }))
  }

  return (
    <Modal
      backdrop='static'
      keyboard={false}
      show={showLoginProfile}
      onHide={() => setShowLoginProfile(false)}
      size='lg'
    >
      <Modal.Body>
        <h1 className='text-center mb-5'>Update Profile</h1>
        <p className='text-center'>
          Please Enter your details to complete your profile
        </p>
        <Form className='d-flex justify-content-center'>
          <Row>
            <Col lg={12} md={12} sm={12} className='pe-0'>
              <Form.Group className='mb-3'>
                <Form.Control
                  className='rounded'
                  type='text'
                  placeholder='First Name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} md={12} sm={12} className='pe-0'>
              <Form.Group className='mb-3'>
                <Form.Control
                  className='rounded'
                  type='text'
                  placeholder='Last Name'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} md={12} sm={12} className='pe-0'>
              <Form.Group className='mb-3'>
                <Form.Control
                  className='rounded'
                  type='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant='outline-primary'
          className='rounded-pill'
          onClick={() => updateNewUserHandler()}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default LoginProfile
