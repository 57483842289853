import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import wearableService from "./wearableService";

const initialState = {
  wearables: [],
  wearable: null,

  isListError: false,
  isListLoading: false,
  isListSuccess: false,
  listErrorMessage: "",

  isCreateError: false,
  isCreateLoading: false,
  isCreateSuccess: false,
  createErrorMessage: "",

  isDetailsError: false,
  isDetailsLoading: false,
  isDetailsSuccess: false,
  detailsErrorMessage: "",

  isUpdateError: false,
  isUpdateLoading: false,
  isUpdateSuccess: false,
  updateErrorMessage: "",

  isDeleteError: false,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  deleteErrorMessage: "",
};

//create wearable
export const createWearable = createAsyncThunk(
  "wearable/createWearable",
  async (wearable, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const createdWearable = await wearableService.createWearable(
        wearable,
        token
      );
      return createdWearable;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all wearables
export const getAllWearables = createAsyncThunk(
  "wearable/getAllWearables",
  async (_, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const wearables = await wearableService.getAllWearables();
      return wearables;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get wearable by id
export const getWearableById = createAsyncThunk(
  "wearable/getWearableById",
  async (id, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const wearable = await wearableService.getWearableById(id);
      return wearable;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update wearable
export const updateWearable = createAsyncThunk(
  "wearable/updateWearable",
  async (wearable, thunkAPI) => {
    const { id, wearableData } = wearable;
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const updatedWearable = await wearableService.updateWearable(
        id,
        wearableData,
        token
      );
      return updatedWearable;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete wearable
export const deleteWearable = createAsyncThunk(
  "wearable/deleteWearable",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const deletedWearable = await wearableService.deleteWearable(id, token);
      return deletedWearable;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const wearableSlice = createSlice({
  name: "wearable",
  initialState,
  reducers: {
    resetWearableCreate: (state) => {
      state.isCreateError = false;
      state.isCreateLoading = false;
      state.isCreateSuccess = false;
      state.createErrorMessage = "";
    },
    resetWearableList: (state) => {
      state.tablets = [];
      state.isListError = false;
      state.isListLoading = false;
      state.isListSuccess = false;
      state.listErrorMessage = "";
    },
    resetWearableDetails: (state) => {
      state.wearable = null;
      state.isDetailsError = false;
      state.isDetailsLoading = false;
      state.isDetailsSuccess = false;
      state.detailsErrorMessage = "";
    },
    resetWearableUpdate: (state) => {
      state.isUpdateError = false;
      state.isUpdateLoading = false;
      state.isUpdateSuccess = false;
      state.updateErrorMessage = "";
    },
    resetWearableDelete: (state) => {
      state.isDeleteError = false;
      state.isDeleteLoading = false;
      state.isDeleteSuccess = false;
      state.deleteErrorMessage = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createWearable.pending, (state) => {
        state.isCreateLoading = true;
        state.isCreateError = false;
        state.isCreateSuccess = false;
        state.createErrorMessage = "";
      })
      .addCase(createWearable.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = false;
        state.isCreateSuccess = true;
        state.createErrorMessage = "";
        state.wearables.push(action.payload);
        state.wearable = action.payload;
      })
      .addCase(createWearable.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = true;
        state.isCreateSuccess = false;
        state.createErrorMessage = action.payload;
      })
      .addCase(getAllWearables.pending, (state) => {
        state.isListLoading = true;
        state.isListError = false;
        state.isListSuccess = false;
        state.listErrorMessage = "";
      })
      .addCase(getAllWearables.fulfilled, (state, action) => {
        state.isListLoading = false;
        state.isListError = false;
        state.isListSuccess = true;
        state.listErrorMessage = "";
        state.wearables = action.payload;
      })
      .addCase(getAllWearables.rejected, (state, action) => {
        state.isListLoading = false;
        state.isListError = true;
        state.isListSuccess = false;
        state.listErrorMessage = action.payload;
      })
      .addCase(getWearableById.pending, (state) => {
        state.isDetailsLoading = true;
        state.isDetailsError = false;
        state.isDetailsSuccess = false;
        state.detailsErrorMessage = "";
      })
      .addCase(getWearableById.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsError = false;
        state.isDetailsSuccess = true;
        state.detailsErrorMessage = "";
        state.wearable = action.payload;
      })
      .addCase(getWearableById.rejected, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsError = true;
        state.isDetailsSuccess = false;
        state.detailsErrorMessage = action.payload;
      })
      .addCase(updateWearable.pending, (state) => {
        state.isUpdateLoading = true;
        state.isUpdateError = false;
        state.isUpdateSuccess = false;
        state.updateErrorMessage = "";
      })
      .addCase(updateWearable.fulfilled, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = false;
        state.isUpdateSuccess = true;
        state.updateErrorMessage = "";
        state.wearables = state.wearables.map((wearable) => {
          if (wearable._id === action.payload._id) {
            return action.payload;
          }
          return wearable;
        });
        state.wearable = action.payload;
      })
      .addCase(updateWearable.rejected, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = true;
        state.isUpdateSuccess = false;
        state.updateErrorMessage = action.payload;
      })
      .addCase(deleteWearable.pending, (state) => {
        state.isDeleteLoading = true;
        state.isDeleteError = false;
        state.isDeleteSuccess = false;
        state.deleteErrorMessage = "";
      })
      .addCase(deleteWearable.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = false;
        state.isDeleteSuccess = true;
        state.deleteErrorMessage = "";
        state.wearables = state.wearables.filter(
          (wearable) => wearable._id !== action.payload._id
        );
        state.wearable = null;
      })
      .addCase(deleteWearable.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.isDeleteSuccess = false;
        state.deleteErrorMessage = action.payload;
      });
  },
});

export const {
    resetWearableCreate,
    resetWearableList,
    resetWearableDetails,
    resetWearableUpdate,
    resetWearableDelete,
} = wearableSlice.actions;

export default wearableSlice.reducer;
