import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import {
  getAllWearables,
  resetWearableList,
} from "../../features/wearable/wearableSlice";
import { toast } from "react-toastify";
import Loader from "../Loader";
import Message from "../Message";
import { TbCurrencyTaka } from "react-icons/tb";
import { motion } from "framer-motion";

const BuyWearable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [allWearables, setAllWearables] = useState([]);

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [productType, setProductType] = useState("");
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [expandPrice, setExpandPrice] = useState(false);
  const [expandType, setExpandType] = useState(false);
  const [expandBrand, setExpandBrand] = useState(false);

  const [searchWearables, setSearchWearables] = useState("");

  const [populatedBrands, setPopulatedBrands] = useState([]);

  const {
    wearables,
    isListLoading,
    isListError,
    listErrorMessage,
    isListSuccess,
  } = useSelector((state) => state.wearable);

  useEffect(() => {
    if (isListError) {
      // toast.error(listErrorMessage, { position: "top-center" });
    } else if (isListSuccess) {
      // Populated Brands in filter
      const brands = [];
      wearables.map((wearable) => {
        if (!brands.includes(wearable.brand)) {
          brands.push(wearable.brand);
        }
      });
      setPopulatedBrands(brands);

      const filteredWearables = wearables
        .filter((wearable) => {
          if (minPrice === 0) {
            return wearable;
          } else if (wearable.price >= minPrice) {
            return wearable;
          }
        })
        .filter((wearable) => {
          if (maxPrice === 0) {
            return wearable;
          } else if (wearable.price <= maxPrice) {
            return wearable;
          }
        })
        .filter((wearable) => {
          if (productType === "") {
            return wearable;
          } else if (wearable.productType === productType) {
            return wearable;
          }
        })
        .filter((wearable) => {
          if (searchWearables === "") {
            return wearable;
          } else {
            return (
              wearable.model.toLowerCase().includes(searchWearables) ||
              wearable.brand.toLowerCase().includes(searchWearables)
            );
          }
        })
        .filter((wearable) => {
          if (selectedBrands.length === 0) {
            return wearable;
          } else if (selectedBrands.includes(wearable.brand)) {
            return wearable;
          }
        });
      setAllWearables(filteredWearables);
    } else {
      dispatch(getAllWearables());
    }
  }, [
    dispatch,
    isListError,
    listErrorMessage,
    isListSuccess,
    wearables,
    minPrice,
    maxPrice,
    productType,
    searchWearables,
    selectedBrands,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetWearableList());
    };
  }, [dispatch]);

  const handleCheckedBrand = (e) => {
    if (e.target.checked) {
      setSelectedBrands([...selectedBrands, e.target.value]);
    } else {
      setSelectedBrands(
        selectedBrands.filter((brand) => brand !== e.target.value)
      );
    }
  };

  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <Container>
        <h2
          style={{ fontWeight: "bold" }}
          className="h1-responsive my-5 pt-3 text-center"
        >
          Choose Your Preferred Smart Watches To Buy
        </h2>
        <Row className='mb-5'>
          <Col sm={12} md={12} lg={3} className="pb-2">
            <Card className="shadow p-3 rounded">
              <Card.Title as="h3" className="text-dark text-center">
                {" "}
                Filters{" "}
              </Card.Title>
              <Card.Title
                className="py-2 text-dark cursorAll"
                onClick={() => setExpandPrice(!expandPrice)}
              >
                Price
              </Card.Title>
              {expandPrice && (
                <Row className="pb-4">
                  <Col sm={12} md={12} lg={6}>
                    <Form.Control
                      className="rounded"
                      type="text"
                      placeholder="Min Price"
                      value={minPrice}
                      onChange={(e) => setMinPrice(e.target.value)}
                    ></Form.Control>
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <Form.Control
                      className="rounded"
                      type="text"
                      placeholder="Max Price"
                      value={maxPrice}
                      onChange={(e) => setMaxPrice(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
              )}
              <Card.Title
                className="pb-2 text-dark cursorAll"
                onClick={() => setExpandType(!expandType)}
              >
                Product Type
              </Card.Title>
              {expandType && (
                <Form.Control
                  type="select"
                  as="select"
                  placeholder="Select Product Type"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                  className="mb-4"
                >
                  <option value="disabled">Select Product Type</option>
                  <option value="New">New</option>
                  <option value="Pre-Owned">Pre-Owned</option>
                </Form.Control>
              )}

              <Card.Title
                className="text-dark cursorAll"
                onClick={() => setExpandBrand(!expandBrand)}
              >
                Brands
              </Card.Title>
              {expandBrand && (
                <>
                  {populatedBrands.map((brand) => (
                    <Form.Check
                      name={brand}
                      value={brand}
                      label={brand}
                      onChange={(e) => handleCheckedBrand(e)}
                      className="mb-2"
                    />
                  ))}
                </>
              )}
            </Card>
          </Col>

          <Col sm={12} md={12} lg={9}>
            <Card className="shadow p-3 rounded">
              <Row className="mb-4">
                <Col sm={12} md={12} lg={12}>
                  <Form>
                    <Form.Control
                      type="text"
                      placeholder="Search Smart Watches"
                      value={searchWearables}
                      onChange={(e) => setSearchWearables(e.target.value)}
                      style={{ border: "1px solid black" }}
                      className="shadow rounded"
                    ></Form.Control>
                  </Form>
                </Col>
              </Row>

              <Row>
                {isListLoading ? (
                  <Loader />
                ) : allWearables.length <= 0 ? (
                  <Message variant="danger">No Wearables Found</Message>
                ) : (
                  <>
                    {allWearables.map((wearable) => (
                      <Col sm={12} md={4} lg={3}>
                        <motion.div
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.9 }}
                        >
                          <LinkContainer
                            to={`/wearableDetails/${wearable._id}`}
                          >
                            <Card className="shadow rounded mb-3 cursorAll">
                              <Card.Img
                                cascade
                                className="img-fluid mb-3 rounded p-2 buyCard"
                                src={wearable.coverImage}
                                style={{
                                  objectFit: "contain",
                                  objectPosition: "center",
                                }}
                              />
                              <Card.Body className="buyCardBody">
                                <Card.Text className="text-center mb-2">
                                  <h5>
                                    {wearable.brand} {wearable.model}
                                  </h5>
                                </Card.Text>
                                <Card.Text className="text-center text-muted">
                                  <h5>{wearable.stock}</h5>
                                </Card.Text>
                                <Card.Text className="text-center">
                                  <h5>
                                    Price: {wearable.price}{" "}
                                    <TbCurrencyTaka
                                      className="mb-2"
                                      size={20}
                                    />
                                  </h5>
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </LinkContainer>
                        </motion.div>
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  );
};

export default BuyWearable;
