import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Table,
  Button,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FcViewDetails } from "react-icons/fc";
import { MdPostAdd } from "react-icons/md";
import { MdCancelPresentation } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

import {
  getAllSmartphoneAddRequests,
  resetSmartphoneAddRequestList,
  deleteSmartphoneAddRequest,
  resetSmartphoneAddRequestDelete,
  updateSmartphoneAddRequest,
  resetSmartphoneAddRequestUpdate,
} from "../features/smartphoneaddrequest/smartphoneAddRequestSlice";

import {
  getAllTabletAddRequests,
  resetTabletAddRequestList,
  updateTabletAddRequest,
  resetTabletAddRequestUpdate,
  deleteTabletAddRequest,
  resetTabletAddRequestDelete,
} from "../features/tabletaddrequest/tabletAddRequestSlice";

import {
  getAllWearableAddRequests,
  resetWearableAddRequestList,
  updateWearableAddRequest,
  resetWearableAddRequestUpdate,
  deleteWearableAddRequest,
  resetWearableAddRequestDelete,
} from "../features/wearableaddrequest/wearableAddRequestSlice";

import {
  getAllEvaluations,
  resetEvaluationList,
  updateEvaluation,
  resetEvaluationUpdate,
  deleteEvaluation,
  resetEvaluationDelete,
} from "../features/evaluation/evaluationSlice";

const DashboardScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);

  const {
    smartphoneAddRequests,
    isListError: smartphoneListError,
    isListLoading: smartphoneListLoading,
    isListSuccess: smartphoneListSuccess,
    listErrorMessage: smartphoneListErrorMessage,

    isDeleteLoading: smartphoneDeleteLoading,
    isDeleteError: smartphoneDeleteError,
    isDeleteSuccess: smartphoneDeleteSuccess,
    deleteErrorMessage: smartphoneDeleteErrorMessage,

    isUpdateLoading: smartphoneUpdateLoading,
    isUpdateError: smartphoneUpdateError,
    isUpdateSuccess: smartphoneUpdateSuccess,
    updateErrorMessage: smartphoneUpdateErrorMessage,
  } = useSelector((state) => state.smartphoneAddRequest);

  const {
    tabletAddRequests,
    isListError: tabletListError,
    isListLoading: tabletListLoading,
    isListSuccess: tabletListSuccess,
    listErrorMessage: tabletListErrorMessage,

    isUpdateLoading: tabletUpdateLoading,
    isUpdateError: tabletUpdateError,
    isUpdateSuccess: tabletUpdateSuccess,
    updateErrorMessage: tabletUpdateErrorMessage,

    isDeleteLoading: tabletDeleteLoading,
    isDeleteError: tabletDeleteError,
    isDeleteSuccess: tabletDeleteSuccess,
    deleteErrorMessage: tabletDeleteErrorMessage,
  } = useSelector((state) => state.tabletAddRequest);

  const {
    wearableAddRequests,
    isListError: wearableListError,
    isListLoading: wearableListLoading,
    isListSuccess: wearableListSuccess,
    listErrorMessage: wearableListErrorMessage,

    isUpdateLoading: wearableUpdateLoading,
    isUpdateError: wearableUpdateError,
    isUpdateSuccess: wearableUpdateSuccess,
    updateErrorMessage: wearableUpdateErrorMessage,

    isDeleteLoading: wearableDeleteLoading,
    isDeleteError: wearableDeleteError,
    isDeleteSuccess: wearableDeleteSuccess,
    deleteErrorMessage: wearableDeleteErrorMessage,
  } = useSelector((state) => state.wearableAddRequest);

  const {
    evaluations,
    isListError: evaluationListError,
    isListLoading: evaluationListLoading,
    isListSuccess: evaluationListSuccess,
    listErrorMessage: evaluationListErrorMessage,

    isUpdateLoading: evaluationUpdateLoading,
    isUpdateError: evaluationUpdateError,
    isUpdateSuccess: evaluationUpdateSuccess,
    updateErrorMessage: evaluationUpdateErrorMessage,

    isDeleteLoading: evaluationDeleteLoading,
    isDeleteError: evaluationDeleteError,
    isDeleteSuccess: evaluationDeleteSuccess,
    deleteErrorMessage: evaluationDeleteErrorMessage,
  } = useSelector((state) => state.evaluation);

  const [smartphoneAddRequestList, setSmartphoneAddRequestList] = useState([]);
  const [tabletAddRequestList, setTabletAddRequestList] = useState([]);
  const [wearableAddRequestList, setWearableAddRequestList] = useState([]);
  const [evaluationList, setEvaluationList] = useState([]);

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (userInfo.userType !== "admin") {
      toast.error("You are not authorized to view this page");
      navigate("/");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (smartphoneListError) {
      // toast.error(smartphoneListErrorMessage, { position: "top-center" });
    } else if (smartphoneListSuccess) {
      setSmartphoneAddRequestList(smartphoneAddRequests);
    } else {
      dispatch(getAllSmartphoneAddRequests());
      // dispatch(getSmartphoneAddRequestById(id));
    }
  }, [
    smartphoneListError,
    smartphoneListErrorMessage,
    smartphoneListSuccess,
    smartphoneAddRequests,
    // id,
    dispatch,
  ]);

  useEffect(() => {
    if (smartphoneUpdateError) {
      toast.error(smartphoneUpdateErrorMessage, { position: "top-center" });
    } else if (smartphoneUpdateSuccess) {
      toast.success("Add Request Updated Successfully", {
        position: "top-center",
      });
    }
  }, [
    smartphoneUpdateError,
    smartphoneUpdateErrorMessage,
    smartphoneUpdateSuccess,
  ]);

  useEffect(() => {
    if (smartphoneDeleteError) {
      toast.error(smartphoneDeleteErrorMessage, { position: "top-center" });
    } else if (smartphoneDeleteSuccess) {
      toast.success("Add Request Removed Successfully", {
        position: "top-center",
      });
    }
  }, [
    smartphoneDeleteError,
    smartphoneDeleteErrorMessage,
    smartphoneDeleteSuccess,
  ]);

  useEffect(() => {
    if (tabletListError) {
      // toast.error(tabletListErrorMessage, { position: "top-center" });
    } else if (tabletListSuccess) {
      setTabletAddRequestList(tabletAddRequests);
    } else {
      dispatch(getAllTabletAddRequests());
      // dispatch(get)
    }
  }, [
    tabletListError,
    tabletListErrorMessage,
    tabletListSuccess,
    tabletAddRequests,
    dispatch,
  ]);

  useEffect(() => {
    if (tabletUpdateError) {
      toast.error(tabletUpdateErrorMessage, { position: "top-center" });
    } else if (tabletUpdateSuccess) {
      toast.success("Add Request Updated Successfully", {
        position: "top-center",
      });
    }
  }, [tabletUpdateError, tabletUpdateErrorMessage, tabletUpdateSuccess]);

  useEffect(() => {
    if (tabletDeleteError) {
      toast.error(tabletDeleteErrorMessage, { position: "top-center" });
    } else if (tabletDeleteSuccess) {
      toast.success("Add Request Removed Successfully", {
        position: "top-center",
      });
    }
  }, [tabletDeleteError, tabletDeleteErrorMessage, tabletDeleteSuccess]);

  useEffect(() => {
    if (wearableListError) {
      // toast.error(wearableListErrorMessage, { position: "top-center" });
    } else if (wearableListSuccess) {
      setWearableAddRequestList(wearableAddRequests);
    } else {
      dispatch(getAllWearableAddRequests());
    }
  }, [
    wearableListError,
    wearableListErrorMessage,
    wearableListSuccess,
    wearableAddRequests,
    dispatch,
  ]);

  useEffect(() => {
    if (wearableUpdateError) {
      toast.error(wearableUpdateErrorMessage, { position: "top-center" });
    } else if (wearableUpdateSuccess) {
      toast.success("Add Request Updated Successfully", {
        position: "top-center",
      });
    }
  }, [wearableUpdateError, wearableUpdateErrorMessage, wearableUpdateSuccess]);

  useEffect(() => {
    if (wearableDeleteError) {
      toast.error(wearableDeleteErrorMessage, { position: "top-center" });
    } else if (wearableDeleteSuccess) {
      toast.success("Add Request Removed Successfully", {
        position: "top-center",
      });
    }
  }, [wearableDeleteError, wearableDeleteErrorMessage, wearableDeleteSuccess]);

  useEffect(() => {
    if (evaluationListError) {
      // toast.error(evaluationListErrorMessage, { position: "top-center" });
    } else if (evaluationListSuccess) {
      setEvaluationList(evaluations);
    } else {
      dispatch(getAllEvaluations());
    }
  }, [
    evaluationListError,
    evaluationListErrorMessage,
    evaluationListSuccess,
    evaluations,
    dispatch,
  ]);

  useEffect(() => {
    if (evaluationUpdateError) {
      toast.error(evaluationUpdateErrorMessage, { position: "top-center" });
    } else if (evaluationUpdateSuccess) {
      toast.success("Evaluation For Selected Device Updated Successfully", {
        position: "top-center",
      });
    }
  }, [
    evaluationUpdateError,
    evaluationUpdateErrorMessage,
    evaluationUpdateSuccess,
  ]);

  useEffect(() => {
    if (evaluationDeleteError) {
      toast.error(evaluationDeleteErrorMessage, { position: "top-center" });
    } else if (evaluationDeleteSuccess) {
      toast.success("Evaluation For Selected Device Removed Successfully", {
        position: "top-center",
      });
    }
  });

  useEffect(() => {
    return () => {
      dispatch(resetSmartphoneAddRequestList());
      dispatch(resetSmartphoneAddRequestDelete());
      dispatch(resetSmartphoneAddRequestUpdate());

      dispatch(resetTabletAddRequestList());
      dispatch(resetTabletAddRequestUpdate());
      dispatch(resetTabletAddRequestDelete());

      dispatch(resetWearableAddRequestList());
      dispatch(resetWearableAddRequestUpdate());
      dispatch(resetWearableAddRequestDelete());

      dispatch(resetEvaluationList());
      dispatch(resetEvaluationUpdate());
      dispatch(resetEvaluationDelete());
    };
  }, [dispatch]);

  // const smartPhoneDeleteHandler = (id) => {
  //   dispatch(deleteSmartphoneAddRequest(id));
  // };

  // const confirmSmartPhoneHandler = (id) => {
  //   const smartphoneAddRequestData = {
  //     status: "Approved",
  //   };
  //   dispatch(
  //     updateSmartphoneAddRequest({ id: useParams.id, smartphoneAddRequestData })
  //   );
  // };

  const declineSmartPhoneHandler = (id) => {
    // console.log(id)
    const smartphoneAddRequestData = {
      status: "Rejected",
    };
    dispatch(updateSmartphoneAddRequest({ id, smartphoneAddRequestData }));
  };

  const declineTabletHandler = (id) => {
    const tabletAddRequestData = {
      status: "Rejected",
    };
    dispatch(updateTabletAddRequest({ id, tabletAddRequestData }));
  };

  const declineWearableHandler = (id) => {
    const wearableAddRequestData = {
      status: "Rejected",
    };
    dispatch(updateWearableAddRequest({ id, wearableAddRequestData }));
  };

  const declineEvaluationHandler = (id) => {
    const evaluationData = {
      status: "Rejected",
    };
    dispatch(updateEvaluation({ id, evaluationData }));
  };

  const approveEvaluationHandler = (id) => {
    const evaluationData = {
      status: "Approved",
    };
    dispatch(updateEvaluation({ id, evaluationData }));
  };

  return (
    <Container>
      <h1 className="text-center py-4">Dashboard</h1>
      <h4 className="text-center pb-4">Device Add Requests</h4>
      <Row>
        <Col sm={12} md={6} lg={6} className="mb-5">
          <Card className="shadow rounded" style={{ maxHeight: "60vh" }}>
            <Card.Header className="text-center py-3" as="h4">
              New Smart Phone Add Request
            </Card.Header>
            {smartphoneListLoading ? (
              <Loader />
            ) : smartphoneAddRequestList.length <= 0 ? (
              <h6 className="text-center my-3">No New Smartphone Add Requests</h6>
            ) : (
              <>
                <Table
                  rounded
                  bordered
                  hover
                  responsive
                  className="table-sm overflow-auto"
                  style={{ maxHeight: "20vh" }}
                >
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Details</th>
                      <th>Add</th>
                      <th>Cancel</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {smartphoneAddRequestList.map((smartphoneAddRequest) => (
                      <tr key={smartphoneAddRequest._id}>
                        <td>
                          {smartphoneAddRequest.user.firstName}{" "}
                          {smartphoneAddRequest.user.lastName}
                        </td>
                        <td>{smartphoneAddRequest.brand}</td>
                        <td>{smartphoneAddRequest.model}</td>
                        <td>
                          {/* {smartphoneAddRequest?.status === "Pending" ? ()} */}
                          <LinkContainer
                            to={`/smartphoneAddRequestDetails/${smartphoneAddRequest._id}`}
                          >
                            <Button
                              className="rounded"
                              variant="dark"
                              size="sm"
                            >
                              <FcViewDetails size="20" />
                            </Button>
                          </LinkContainer>
                        </td>
                        <td>
                          {smartphoneAddRequest?.status !== "Approved" ? (
                            <LinkContainer
                              to={{
                                pathname: `/createSmartPhone`,
                                search: `?requestid=${smartphoneAddRequest._id}`,
                              }}
                            >
                              <Button
                                variant="success"
                                className="rounded"
                                size="sm"
                              >
                                <MdPostAdd size="20" />
                              </Button>
                            </LinkContainer>
                          ) : (
                            <LinkContainer
                              to={{
                                pathname: `/createSmartPhone`,
                                search: `?requestid=${smartphoneAddRequest._id}`,
                              }}
                            >
                              <Button
                                variant="success"
                                className="rounded"
                                size="sm"
                                disabled
                              >
                                <MdPostAdd size="20" />
                              </Button>
                            </LinkContainer>
                          )}
                          {/* <LinkContainer
                            to={{
                              pathname: `/createSmartPhone`,
                              search: `?requestid=${smartphoneAddRequest._id}`,
                            }}
                          >
                            <Button
                              variant="success"
                              className="rounded"
                              size="sm"
                            >
                              <MdPostAdd size="20" />
                            </Button>
                          </LinkContainer> */}
                        </td>
                        <td>
                          {smartphoneAddRequest?.status === "Pending" ? (
                            <Button
                              variant="danger"
                              className="rounded"
                              size="sm"
                              onClick={() =>
                                declineSmartPhoneHandler(
                                  smartphoneAddRequest._id
                                )
                              }
                            >
                              <MdCancelPresentation size="20" />
                            </Button>
                          ) : (
                            <Button
                              variant="danger"
                              className="rounded"
                              size="sm"
                              disabled
                              onClick={() =>
                                declineSmartPhoneHandler(
                                  smartphoneAddRequest._id
                                )
                              }
                            >
                              <MdCancelPresentation size="20" />
                            </Button>
                          )}
                          {/* <Button
                            variant="danger"
                            className="rounded"
                            size="sm"
                            onClick={() =>
                              declineSmartPhoneHandler(smartphoneAddRequest._id)
                            }
                          >
                            <MdCancelPresentation size="20" />
                          </Button> */}
                        </td>
                        <td>{smartphoneAddRequest.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Card>
        </Col>

        <Col sm={12} md={6} lg={6} className="mb-5">
          <Card className="shadow rounded" style={{ maxHeight: "50vh" }}>
            <Card.Header className="text-center py-3" as="h4">
              New Tablet Add Request
            </Card.Header>
            {tabletListLoading ? (
              <Loader />
            ) : tabletAddRequestList.length <= 0 ? (
              <h6 className="text-center my-3">No New Tablet Add Requests</h6>
            ) : (
              <>
                <Table
                  bordered
                  hover
                  responsive
                  className="table-sm overflow-auto"
                  style={{ maxHeight: "20vh" }}
                >
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Details</th>
                      <th>Add</th>
                      <th>Cancel</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabletAddRequestList.map((tabletAddRequest) => (
                      <tr key={tabletAddRequest._id}>
                        <td>
                          {tabletAddRequest.user.firstName}{" "}
                          {tabletAddRequest.user.lastName}
                        </td>
                        <td>{tabletAddRequest.brand}</td>
                        <td>{tabletAddRequest.model}</td>
                        <td>
                          <LinkContainer
                            to={`/tabletAddRequestDetails/${tabletAddRequest._id}`}
                          >
                            <Button
                              className="rounded"
                              variant="dark"
                              size="sm"
                            >
                              <FcViewDetails size="20" />
                            </Button>
                          </LinkContainer>
                        </td>
                        <td>
                          {tabletAddRequest?.status !== "Approved" ? (
                            <LinkContainer
                              to={{
                                pathname: `/createTablet`,
                                search: `?requestid=${tabletAddRequest._id}`,
                              }}
                            >
                              <Button
                                className="rounded"
                                variant="success"
                                size="sm"
                              >
                                <MdPostAdd size="20" />
                              </Button>
                            </LinkContainer>
                          ) : (
                            <LinkContainer
                              to={{
                                pathname: `/createTablet`,
                                search: `?requestid=${tabletAddRequest._id}`,
                              }}
                            >
                              <Button
                                className="rounded"
                                variant="success"
                                size="sm"
                                disabled
                              >
                                <MdPostAdd size="20" />
                              </Button>
                            </LinkContainer>
                          )}
                          {/* <LinkContainer
                            to={{
                              pathname: `/createTablet`,
                              search: `?requestid=${tabletAddRequest._id}`,
                            }}
                          >
                            <Button
                              className="rounded"
                              variant="success"
                              size="sm"
                            >
                              <MdPostAdd size="20" />
                            </Button>
                          </LinkContainer> */}
                        </td>
                        <td>
                          {tabletAddRequest?.status === "Pending" ? (
                            <Button
                              className="rounded"
                              variant="danger"
                              size="sm"
                              onClick={() =>
                                declineTabletHandler(tabletAddRequest._id)
                              }
                            >
                              <MdCancelPresentation size="20" />
                            </Button>
                          ) : (
                            <Button
                              className="rounded"
                              variant="danger"
                              size="sm"
                              disabled
                              onClick={() =>
                                declineTabletHandler(tabletAddRequest._id)
                              }
                            >
                              <MdCancelPresentation size="20" />
                            </Button>
                          )}
                          {/* <Button
                            className="rounded"
                            variant="danger"
                            size="sm"
                            onClick={() =>
                              declineTabletHandler(tabletAddRequest._id)
                            }
                          >
                            <MdCancelPresentation size="20" />
                          </Button> */}
                        </td>
                        <td>{tabletAddRequest.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Card>
        </Col>

        <Col sm={12} md={6} lg={6} className="mb-5">
          <Card className="shadow rounded" style={{ maxHeight: "50vh" }}>
            <Card.Header className="text-center py-3" as="h4">
              New Wearables Add Request
            </Card.Header>
            {wearableListLoading ? (
              <Loader />
            ) : wearableAddRequestList.length <= 0 ? (
              <h6 className="text-center my-3">No New Wearables Add Requests</h6>
            ) : (
              <>
                <Table
                  bordered
                  hover
                  responsive
                  className="table-sm overflow-auto"
                  style={{ maxHeight: "20vh" }}
                >
                  <thead style={{ fontWeight: "bold" }}>
                    <tr>
                      <th>User Name</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Details</th>
                      <th>Add</th>
                      <th>Cancel</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wearableAddRequestList.map((wearableAddRequest) => (
                      <tr key={wearableAddRequest._id}>
                        <td>
                          {wearableAddRequest.user.firstName}{" "}
                          {wearableAddRequest.user.lastName}
                        </td>
                        <td>{wearableAddRequest.brand}</td>
                        <td>{wearableAddRequest.model}</td>
                        <td>
                          <LinkContainer
                            to={`/wearablesAddRequestDetails/${wearableAddRequest._id}`}
                          >
                            <Button
                              className="rounded"
                              variant="dark"
                              size="sm"
                            >
                              <FcViewDetails size="20" />
                            </Button>
                          </LinkContainer>
                        </td>
                        <td>
                          {wearableAddRequest?.status !== "Approved" ? (
                            <LinkContainer
                              to={{
                                pathname: `/createWearables`,
                                search: `?requestid=${wearableAddRequest._id}`,
                              }}
                            >
                              <Button
                                className="rounded"
                                variant="success"
                                size="sm"
                              >
                                <MdPostAdd size="20" />
                              </Button>
                            </LinkContainer>
                          ) : (
                            <LinkContainer
                              to={{
                                pathname: `/createWearables`,
                                search: `?requestid=${wearableAddRequest._id}`,
                              }}
                            >
                              <Button
                                className="rounded"
                                variant="success"
                                size="sm"
                                disabled
                              >
                                <MdPostAdd size="20" />
                              </Button>
                            </LinkContainer>
                          )}
                          {/* <LinkContainer
                            to={{
                              pathname: `/createWearables`,
                              search: `?requestid=${wearableAddRequest._id}`,
                            }}
                          >
                            <Button
                              className="rounded"
                              variant="success"
                              size="sm"
                            >
                              <MdPostAdd size="20" />
                            </Button>
                          </LinkContainer> */}
                        </td>
                        <td>
                          {wearableAddRequest?.status === "Pending" ? (
                            <Button
                              className="rounded"
                              variant="danger"
                              size="sm"
                              onClick={() =>
                                declineWearableHandler(wearableAddRequest._id)
                              }
                            >
                              <MdCancelPresentation size="20" />
                            </Button>
                          ) : (
                            <Button
                              className="rounded"
                              variant="danger"
                              size="sm"
                              disabled
                              onClick={() =>
                                declineWearableHandler(wearableAddRequest._id)
                              }
                            >
                              <MdCancelPresentation size="20" />
                            </Button>
                          )}
                          {/* <Button
                            className="rounded"
                            variant="danger"
                            size="sm"
                            onClick={() =>
                              declineWearableHandler(wearableAddRequest._id)
                            }
                          >
                            <MdCancelPresentation size="20" />
                          </Button> */}
                        </td>
                        <td>{wearableAddRequest.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Card>
        </Col>
      </Row>

      <h4 className="text-center py-4">Device Evaluation For Sell</h4>
      <Row>
        <Col sm={12} md={12} lg={12} className="mb-5">
          <Card className="shadow rounded" style={{ maxHeight: "80vh" }}>
            <Card.Header className="text-center py-3" as="h4">
              Device Evaluation
            </Card.Header>
            {evaluationListLoading ? (
              <Loader />
            ) : evaluationList.length <= 0 ? (
              <h6 className="text-center my-3">No Device Sell Requests</h6>
            ) : (
              <>
                <Table
                  bordered
                  hover
                  responsive
                  className="table-sm overflow-auto"
                  // style={{ maxHeight: "20vh" }}
                >
                  <thead style={{ fontWeight: "bold" }}>
                    <tr>
                      <th>User Name</th>
                      <th>Contact</th>
                      <th>Device Type</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Details</th>
                      <th>Approve</th>
                      <th>Reject</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {evaluationList.map((evaluation) => (
                      <tr key={evaluation._id}>
                        <td>
                          {evaluation.user.firstName} {evaluation.user.lastName}
                        </td>
                        <td>{evaluation.user.mobile}</td>
                        <td>{evaluation.deviceType}</td>
                        <td>
                          {evaluation.deviceType === "SmartPhone"
                            ? evaluation.smartPhone?.brand
                            : evaluation.deviceType === "Tablet"
                            ? evaluation.tablet?.brand
                            : null}
                        </td>
                        <td>
                          {evaluation.deviceType === "SmartPhone"
                            ? evaluation.smartPhone?.model
                            : evaluation.deviceType === "Tablet"
                            ? evaluation.tablet?.model
                            : null}
                        </td>
                        <td>
                          <LinkContainer
                            to={`/evaluationDetails/${evaluation._id}`}
                          >
                            <Button
                              className="rounded"
                              variant="dark"
                              size="sm"
                            >
                              <FcViewDetails size="20" />
                            </Button>
                          </LinkContainer>
                        </td>
                        <td>
                          {evaluation?.status !== "Approved" ? (
                            <Button
                              className="rounded"
                              variant="success"
                              size="sm"
                              onClick={() =>
                                approveEvaluationHandler(evaluation._id)
                              }
                            >
                              <MdPostAdd size="20" />
                            </Button>
                          ) : (
                            // <LinkContainer
                            //   to={{
                            //     pathname: `/createWearables`,
                            //     search: `?requestid=${evaluation._id}`,
                            //   }}
                            // >
                            <Button
                              className="rounded"
                              variant="success"
                              size="sm"
                              disabled
                            >
                              <MdPostAdd size="20" />
                            </Button>
                            // </LinkContainer>
                          )}
                        </td>
                        <td>
                          {evaluation?.status === "Pending" ? (
                            <Button
                              className="rounded"
                              variant="danger"
                              size="sm"
                              onClick={() =>
                                declineEvaluationHandler(evaluation._id)
                              }
                            >
                              <MdCancelPresentation size="20" />
                            </Button>
                          ) : (
                            <Button
                              className="rounded"
                              variant="danger"
                              size="sm"
                              disabled
                              onClick={() =>
                                declineEvaluationHandler(evaluation._id)
                              }
                            >
                              <MdCancelPresentation size="20" />
                            </Button>
                          )}
                        </td>
                        <td>{evaluation.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardScreen;
