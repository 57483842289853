import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import evaluationService from "./evaluationService";

const initialState = {
  evaluations: [],
  evaluation: null,

  isListError: false,
  isListLoading: false,
  isListSuccess: false,
  listErrorMessage: "",

  isCreateError: false,
  isCreateLoading: false,
  isCreateSuccess: false,
  createErrorMessage: "",

  isDetailsError: false,
  isDetailsLoading: false,
  isDetailsSuccess: false,
  detailsErrorMessage: "",

  // isUpdateError: false,
  // isUpdateLoading: false,
  // isUpdateSuccess: false,
  // updateErrorMessage: "",

  isDeleteError: false,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  deleteErrorMessage: "",
};

//create evaluation
export const createEvaluation = createAsyncThunk(
  "evaluation/createEvaluation",
  async (evaluation, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const createdEvaluation = await evaluationService.createEvaluation(
        evaluation,
        token
      );
      return createdEvaluation;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all evaluations
export const getAllEvaluations = createAsyncThunk(
  "evaluation/getAllEvaluations",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const evaluations = await evaluationService.getAllEvaluations(token);
      return evaluations;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get evaluation by id
export const getEvaluationById = createAsyncThunk(
  "evaluation/getEvaluationById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const evaluation = await evaluationService.getEvaluationById(id, token);
      return evaluation;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// update evaluation
export const updateEvaluation = createAsyncThunk(
  "evaluation/updateEvaluation",
  async (evaluation, thunkAPI) => {
    const { id, evaluationData } = evaluation;
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const updatedEvaluation = await evaluationService.updateEvaluation(
        id,
        evaluationData,
        token
      );
      return updatedEvaluation;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete evaluation
export const deleteEvaluation = createAsyncThunk(
  "evaluation/deleteEvaluation",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const evaluation = await evaluationService.deleteEvaluation(id, token);
      return evaluation;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const evaluationSlice = createSlice({
  name: "evaluation",
  initialState,
  reducers: {
    resetEvaluationCreate: (state) => {
      state.isCreateError = false;
      state.isCreateLoading = false;
      state.isCreateSuccess = false;
      state.createErrorMessage = "";
    },
    resetEvaluationList: (state) => {
      state.evaluations = [];
      state.isListError = false;
      state.isListLoading = false;
      state.isListSuccess = false;
      state.listErrorMessage = "";
    },
    resetEvaluationDetails: (state) => {
      state.evaluation = null;
      state.isDetailsError = false;
      state.isDetailsLoading = false;
      state.isDetailsSuccess = false;
      state.detailsErrorMessage = "";
    },
    resetEvaluationUpdate: (state) => {
      state.isUpdateError = false;
      state.isUpdateLoading = false;
      state.isUpdateSuccess = false;
      state.updateErrorMessage = "";
    },
    resetEvaluationDelete: (state) => {
      state.isDeleteError = false;
      state.isDeleteLoading = false;
      state.isDeleteSuccess = false;
      state.deleteErrorMessage = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createEvaluation.pending, (state) => {
        state.isCreateLoading = true;
        state.isCreateError = false;
        state.isCreateSuccess = false;
        state.createErrorMessage = "";
      })
      .addCase(createEvaluation.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = false;
        state.isCreateSuccess = true;
        state.createErrorMessage = "";
        state.evaluations.push(action.payload);
        state.evaluation = action.payload;
      })
      .addCase(createEvaluation.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.isCreateError = true;
        state.isCreateSuccess = false;
        state.createErrorMessage = action.payload;
      })
      .addCase(getAllEvaluations.pending, (state) => {
        state.isListLoading = true;
        state.isListError = false;
        state.isListSuccess = false;
        state.listErrorMessage = "";
      })
      .addCase(getAllEvaluations.fulfilled, (state, action) => {
        state.isListLoading = false;
        state.isListError = false;
        state.isListSuccess = true;
        state.listErrorMessage = "";
        state.evaluations = action.payload;
      })
      .addCase(getAllEvaluations.rejected, (state, action) => {
        state.isListLoading = false;
        state.isListError = true;
        state.isListSuccess = false;
        state.listErrorMessage = action.payload;
      })
      .addCase(getEvaluationById.pending, (state) => {
        state.isDetailsLoading = true;
        state.isDetailsError = false;
        state.isDetailsSuccess = false;
        state.detailsErrorMessage = "";
      })
      .addCase(getEvaluationById.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsError = false;
        state.isDetailsSuccess = true;
        state.detailsErrorMessage = "";
        state.evaluation = action.payload;
      })
      .addCase(getEvaluationById.rejected, (state, action) => {
        state.isDetailsLoading = false;
        state.isDetailsError = true;
        state.isDetailsSuccess = false;
        state.detailsErrorMessage = action.payload;
      })
      .addCase(updateEvaluation.pending, (state) => {
        state.isUpdateLoading = true;
        state.isUpdateError = false;
        state.isUpdateSuccess = false;
        state.updateErrorMessage = "";
      })
      .addCase(updateEvaluation.fulfilled, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = false;
        state.isUpdateSuccess = true;
        state.updateErrorMessage = "";
        state.evaluations = state.evaluations.map((evaluation) =>
          evaluation._id === action.payload._id ? action.payload : evaluation
        );
        state.evaluation = action.payload;
      })
      .addCase(updateEvaluation.rejected, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = true;
        state.isUpdateSuccess = false;
        state.updateErrorMessage = action.payload;
      })
      .addCase(deleteEvaluation.pending, (state) => {
        state.isDeleteLoading = true;
        state.isDeleteError = false;
        state.isDeleteSuccess = false;
        state.deleteErrorMessage = "";
      })
      .addCase(deleteEvaluation.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = false;
        state.isDeleteSuccess = true;
        state.deleteErrorMessage = "";
        state.evaluations = state.evaluations.filter(
          (evaluation) => evaluation._id !== action.payload
        );
        state.evaluation = null;
      })
      .addCase(deleteEvaluation.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.isDeleteSuccess = false;
        state.deleteErrorMessage = action.payload;
      });
  },
});

export const {
  resetEvaluationCreate,
  resetEvaluationList,
  resetEvaluationDetails,
  resetEvaluationUpdate,
  resetEvaluationDelete,
} = evaluationSlice.actions;

export default evaluationSlice.reducer;
