import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FiUser, FiBell } from "react-icons/fi";
import {
  updateNewUser,
  logout,
  resetSendOTP,
  resetVerifyOTP,
  resetUpdateNewUser,
} from "../features/auth/authSlice";
import user from "../staticData/user";
import { toast } from "react-toastify";
import SendOTPModal from "./SendOTPModal";
import VerifyOTPModal from "./VerifyOTPModal";
import LoginProfile from "./LoginProfile";
import { FiSettings } from "react-icons/fi";
import { BiUserCircle } from "react-icons/bi";

const Header1 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // states
  const [globalPhone, setGlobalPhone] = useState("");

  const {
    userInfo,
    isSendOTPError,
    isSendOTPSuccess,
    isSendOTPLoading,
    sendOTPErrorMessage,

    isVerifyOTPError,
    isVerifyOTPSuccess,
    isVerifyOTPLoading,
    verifyOTPErrorMessage,

    isUpdateNewUserError,
    isUpdateNewUserSuccess,
    isUpdateNewUserLoading,
    updateNewUserErrorMessage,
  } = useSelector((state) => state.auth);

  // modal display states
  const [showSendOTPModal, setShowSendOTPModal] = useState(false);
  const [showVerifyOTPModal, setShowVerifyOTPModal] = useState(false);
  const [showLoginProfile, setShowLoginProfile] = useState(userInfo?.newUser);

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    if (isSendOTPError) {
      toast.error(sendOTPErrorMessage, { position: "top-center" });
    } else if (isSendOTPSuccess) {
      setShowSendOTPModal(false);
      setShowVerifyOTPModal(true);
      dispatch(resetSendOTP());
    }
  }, [isSendOTPError, isSendOTPSuccess]);

  useEffect(() => {
    if (isVerifyOTPError) {
      toast.error(verifyOTPErrorMessage, { position: "top-center" });
    } else if (isVerifyOTPSuccess) {
      setShowVerifyOTPModal(false);
      if (userInfo.newUser) {
        setShowLoginProfile(true);
      } else {
        toast.success("Login Successful", { position: "top-center" });
      }
      dispatch(resetVerifyOTP());
    }
  }, [isVerifyOTPError, isVerifyOTPSuccess]);

  useEffect(() => {
    if (isUpdateNewUserError) {
      toast.error(updateNewUserErrorMessage, { position: "top-center" });
    } else if (isUpdateNewUserSuccess) {
      setShowLoginProfile(false);
      toast.success("Login Successful", { position: "top-center" });
      dispatch(resetUpdateNewUser());
    }
  }, [isUpdateNewUserError, isUpdateNewUserSuccess]);

  return (
    <header className="fixed-top shadow">
      <Navbar bg="light" variant="light" expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img style={{ maxWidth: "130px" }} src="/img/logo.png"></img>
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto align-items-center">
              {userInfo ? (
                <>
                  {userInfo.userType === "admin" && (
                    <>
                      {/* Admin */}
                      <LinkContainer to="/createSmartPhone">
                        <Nav.Link>Add SmartPhone</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/createTablet">
                        <Nav.Link>Add Tablet</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/createWearables">
                        <Nav.Link>Add Wearables</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/createGadget">
                        <Nav.Link>Add Gadgets</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/createOffer">
                        <Nav.Link>Smart Offers</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/createNavbar">
                        <Nav.Link>Navbars</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/dashboard">
                        <Nav.Link>Dashboard</Nav.Link>
                      </LinkContainer>
                    </>
                  )}
                </>
              ) : null}
            </Nav>

            <Nav className="ms-auto align-items-center">
              {/* <LinkContainer to=''>
                <Nav.Link>
                  <FiBell style={{ color: 'lightgreen' }} size='20' />
                </Nav.Link>
              </LinkContainer> */}

              {userInfo?.userType === "customer" && (
                <>
                  {/* User */}
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-none" className="rounded">
                      <FiSettings size="23" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="rounded">
                      <Dropdown.Item href="/updateProfile">
                        Update Profile
                      </Dropdown.Item>

                      <Dropdown.Divider />

                      <Dropdown.Item href="/userDashboard">
                        Dashboard
                      </Dropdown.Item>
                      <Dropdown.Item href="/addSmartPhone">
                        Customized Smartphone Add Request
                      </Dropdown.Item>
                      <Dropdown.Item href="/addTablet">
                        Customized Tablet Add Request
                      </Dropdown.Item>
                      <Dropdown.Item href="/addWearable">
                        Customized Wearables Add Request
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}

              {userInfo ? (
                <Nav.Link onClick={logoutHandler}>
                  <BiUserCircle
                    className="me-2"
                    // style={{ color: "lightgreen" }}
                    size="23"
                  />
                  LOGOUT
                </Nav.Link>
              ) : (
                <Nav.Link onClick={() => setShowSendOTPModal(true)}>
                  <BiUserCircle
                    className="me-2"
                    // style={{ color: "lightgreen" }}
                    size="23"
                  />
                  LOGIN
                </Nav.Link>
              )}

              {/* Send OTP Modal */}
              <SendOTPModal
                showSendOTPModal={showSendOTPModal}
                setShowSendOTPModal={setShowSendOTPModal}
                setGlobalPhone={setGlobalPhone}
              />

              {/* Verify OTP Modal */}
              <VerifyOTPModal
                showVerifyOTPModal={showVerifyOTPModal}
                setShowVerifyOTPModal={setShowVerifyOTPModal}
                globalPhone={globalPhone}
              />

              {/* Update New User Modal */}

              {/* {userInfo.newUser ? (
                <>
                  <LoginProfile
                    showLoginProfile={showLoginProfile}
                    setShowLoginProfile={setShowLoginProfile}
                    globalPhone={globalPhone}
                  />
                </>
              ) : null} */}

              <LoginProfile
                showLoginProfile={showLoginProfile}
                setShowLoginProfile={setShowLoginProfile}
                // globalPhone={globalPhone}
              />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header1;
