import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllOffers,
  resetOfferList,
} from "../features/offers/offersSlice";
import { LinkContainer } from "react-router-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

// const offers = [
//   {
//     label: "pffer",
//     value: "offer1",
//     imageSrc: "/img/Navbar/banner1.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer2",
//     imageSrc: "/img/Navbar/banner2.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer3",
//     imageSrc: "/img/Navbar/banner3.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer4",
//     imageSrc: "/img/Navbar/banner4.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer5",
//     imageSrc: "/img/Navbar/banner5.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer6",
//     imageSrc: "/img/Navbar/banner6.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer7",
//     imageSrc: "/img/Navbar/banner7.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer8",
//     imageSrc: "/img/Navbar/banner8.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer9",
//     imageSrc: "/img/Navbar/banner9.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer10",
//     imageSrc: "/img/Navbar/banner10.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer11",
//     imageSrc: "/img/Navbar/banner11.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer12",
//     imageSrc: "/img/Navbar/banner12.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer13",
//     imageSrc: "/img/Navbar/banner13.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer14",
//     imageSrc: "/img/Navbar/banner14.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer15",
//     imageSrc: "/img/Navbar/banner15.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer16",
//     imageSrc: "/img/Navbar/banner16.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer17",
//     imageSrc: "/img/Navbar/banner17.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer18",
//     imageSrc: "/img/Navbar/banner18.jpg",
//   },
//   {
//     label: "pffer",
//     value: "offer19",
//     imageSrc: "/img/Navbar/banner19.jpg",
//   },
// ];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Offers = (deviceType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allOffers, setAllOffers] = useState([]);

  const { userInfo } = useSelector((state) => state.auth)

  const {
    offers,

    isListLoading,
    isListError,
    listErrorMessage,
    isListSuccess,
  } = useSelector((state) => state.offer);

  useEffect(() => {
    if (isListError){
      // toast.error(listErrorMessage, { position: "top-center" });
    } else if(isListSuccess){
      setAllOffers(offers)
    }
    else {
      dispatch(getAllOffers());
    }
  }, [
    dispatch,
    isListError,
    isListSuccess,
    listErrorMessage,
    offers,
  ])

  useEffect(() => {
    return () => {
      dispatch(resetOfferList());
    };
  }, [dispatch])

  return (
    <Container className="my-5">
      <h1 className="text-center mb-3">Smart Offers</h1>
      <Carousel
        responsive={responsive}
        transitionDuration={500}
        autoPlay={deviceType !== "mobile" ? true : false}
        autoPlaySpeed={2000}
        deviceType={deviceType}
        infinite={true}
        ssr={true}
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
      >
        {offers.map((offer) => (
          <Col lg={3} md={3} sm={12} style={{ height: "100%", width: "100%" }}>
            <LinkContainer to="" className="mx-2">
              <Card className="cursorAll rounded-4">
                <Card.Img
                  style={{ borderRadius: "15px" }}
                  cascade
                  className="img-fluid"
                  src={offer.image}
                />
              </Card>
            </LinkContainer>
          </Col>
        ))}
      </Carousel>
    </Container>
  );
};

export default Offers;
