import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import smartphoneService from "./smartphoneService";

const initialState = {
  smartphones: [],
  smartphone: null,

  isListError: false,
  isListLoading: false,
  isListSuccess: false,
  listErrorMessage: "",

  isCreateError: false,
  isCreateLoading: false,
  isCreateSuccess: false,
  createErrorMessage: "",

  isDetailsError: false,
  isDetailsLoading: false,
  isDetailsSuccess: false,
  detailsErrorMessage: "",

  isUpdateError: false,
  isUpdateLoading: false,
  isUpdateSuccess: false,
  updateErrorMessage: "",

  isDeleteError: false,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  deleteErrorMessage: "",
};

//create smartphone
export const createSmartphone = createAsyncThunk(
  "smartphone/createSmartphone",
  async (smartphone, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const createdSmartphone = await smartphoneService.createSmartphone(
        smartphone,
        token
      );
      return createdSmartphone;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all smartphones
export const getAllSmartphones = createAsyncThunk(
  "smartphone/getAllSmartphones",
  async (_, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const smartphones = await smartphoneService.getAllSmartphones();
      return smartphones;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get smartphone by id
export const getSmartphoneById = createAsyncThunk(
  "smartphone/getSmartphoneById",
  async (id, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.userInfo.token;
      const smartphone = await smartphoneService.getSmartphoneById(id);
      return smartphone;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update smartphone
export const updateSmartphone = createAsyncThunk(
  "smartphone/updateSmartphone",
  async (smartphone, thunkAPI) => {
    const { id, smartPhoneData } = smartphone;
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const updatedSmartphone = await smartphoneService.updateSmartphone(
        id,
        smartPhoneData,
        token
      );
      return updatedSmartphone;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete smartphone
export const deleteSmartphone = createAsyncThunk(
  "smartphone/deleteSmartphone",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.userInfo.token;
      const deletedSmartphone = await smartphoneService.deleteSmartphone(
        id,
        token
      );
      return deletedSmartphone;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const smartphoneSlice = createSlice({
  name: "smartphone",
  initialState,
  reducers: {
    resetSmartphoneCreate: (state) => {
      state.isCreateError = false;
      state.isCreateLoading = false;
      state.isCreateSuccess = false;
      state.createErrorMessage = "";
    },
    resetSmartphoneList: (state) => {
      state.smartphones = [];
      state.isListError = false;
      state.isListLoading = false;
      state.isListSuccess = false;
      state.listErrorMessage = "";
    },
    resetSmartphoneDetails: (state) => {
      state.smartphone = null;
      state.isDetailsError = false;
      state.isDetailsLoading = false;
      state.isDetailsSuccess = false;
      state.detailsErrorMessage = "";
    },
    resetSmartphoneUpdate: (state) => {
      state.isUpdateError = false;
      state.isUpdateLoading = false;
      state.isUpdateSuccess = false;
      state.updateErrorMessage = "";
    },
    resetSmartphoneDelete: (state) => {
      state.isDeleteError = false;
      state.isDeleteLoading = false;
      state.isDeleteSuccess = false;
      state.deleteErrorMessage = "";
    },
  },

    extraReducers: (builder) => {
        builder
        .addCase(createSmartphone.pending, (state) => {
            state.isCreateLoading = true;
            state.isCreateSuccess = false;
            state.isCreateError = false;
            state.createErrorMessage = "";
        })
        .addCase(createSmartphone.fulfilled, (state, action) => {
            state.isCreateLoading = false;
            state.isCreateSuccess = true;
            state.isCreateError = false;
            state.createErrorMessage = "";
            state.smartphones.push(action.payload);
            state.smartphone = action.payload;
        })
        .addCase(createSmartphone.rejected, (state, action) => {
            state.isCreateLoading = false;
            state.isCreateSuccess = false;
            state.isCreateError = true;
            state.createErrorMessage = action.payload;
        })
        .addCase(getAllSmartphones.pending, (state) => {
            state.isListLoading = true;
            state.isListSuccess = false;
            state.isListError = false;
            state.listErrorMessage = "";
        })
        .addCase(getAllSmartphones.fulfilled, (state, action) => {
            state.isListLoading = false;
            state.isListSuccess = true;
            state.isListError = false;
            state.listErrorMessage = "";
            state.smartphones = action.payload;
        })
        .addCase(getAllSmartphones.rejected, (state, action) => {
            state.isListLoading = false;
            state.isListSuccess = false;
            state.isListError = true;
            state.listErrorMessage = action.payload;
        })
        .addCase(getSmartphoneById.pending, (state) => {
            state.isDetailsLoading = true;
            state.isDetailsSuccess = false;
            state.isDetailsError = false;
            state.detailsErrorMessage = "";
        })
        .addCase(getSmartphoneById.fulfilled, (state, action) => {
            state.isDetailsLoading = false;
            state.isDetailsSuccess = true;
            state.isDetailsError = false;
            state.detailsErrorMessage = "";
            state.smartphone = action.payload;
        })
        .addCase(getSmartphoneById.rejected, (state, action) => {
            state.isDetailsLoading = false;
            state.isDetailsSuccess = false;
            state.isDetailsError = true;
            state.detailsErrorMessage = action.payload;
        })
        .addCase(updateSmartphone.pending, (state) => {
            state.isUpdateLoading = true;
            state.isUpdateSuccess = false;
            state.isUpdateError = false;
            state.updateErrorMessage = "";
        })
        .addCase(updateSmartphone.fulfilled, (state, action) => {
            state.isUpdateLoading = false;
            state.isUpdateSuccess = true;
            state.isUpdateError = false;
            state.updateErrorMessage = "";
            state.smartphones = state.smartphones.map((smartphone) => {
                if (smartphone._id === action.payload._id) {
                    return action.payload;
                }
                return smartphone;
            });
            state.smartphone = action.payload;
        })
        .addCase(updateSmartphone.rejected, (state, action) => {
            state.isUpdateLoading = false;
            state.isUpdateSuccess = false;
            state.isUpdateError = true;
            state.updateErrorMessage = action.payload;
        })
        .addCase(deleteSmartphone.pending, (state) => {
            state.isDeleteLoading = true;
            state.isDeleteSuccess = false;
            state.isDeleteError = false;
            state.deleteErrorMessage = "";
        })
        .addCase(deleteSmartphone.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.isDeleteSuccess = true;
            state.isDeleteError = false;
            state.deleteErrorMessage = "";
            state.smartphones = state.smartphones.filter(
                (smartphone) => smartphone._id !== action.payload._id
            );
            state.smartphone = null;
        })
        .addCase(deleteSmartphone.rejected, (state, action) => {
            state.isDeleteLoading = false;
            state.isDeleteSuccess = false;
            state.isDeleteError = true;
            state.deleteErrorMessage = action.payload;
        });
    }
});

export const {
    resetSmartphoneCreate,
    resetSmartphoneList,
    resetSmartphoneDetails,
    resetSmartphoneUpdate,
    resetSmartphoneDelete,
} = smartphoneSlice.actions;

export default smartphoneSlice.reducer;
