import axios from "axios";

//create evaluation
const createEvaluation = async (evaluation, token) => {
  const response = await axios.post("/api/v1/evaluations", evaluation, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

//get all evaluations
const getAllEvaluations = async (token) => {
  const response = await axios.get("/api/v1/evaluations?sort=-createdAt", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

//get evaluation by id
const getEvaluationById = async (id, token) => {
  const response = await axios.get(`/api/v1/evaluations/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

//update evaluation
const updateEvaluation = async (id, evaluation, token) => {
  const response = await axios.patch(`/api/v1/evaluations/${id}`, evaluation, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

//delete evaluation
const deleteEvaluation = async (id, token) => {
  const response = await axios.delete(`/api/v1/evaluations/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

const evaluationService = {
    createEvaluation,
    getAllEvaluations,
    getEvaluationById,
    updateEvaluation,
    deleteEvaluation,
};

export default evaluationService;
