import axios from "axios";

// create navbar
const createNavbar = async (navbar, token) => {
  const response = await axios.post("/api/v1/navbar", navbar, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

// get all navbars
const getAllNavbars = async () => {
  const response = await axios.get("/api/v1/navbar?sort=-createdAt");
  return response.data.data;
};

// get navbar by id
const getNavbarById = async (id) => {
  const response = await axios.get(`/api/v1/navbar/${id}`);
  return response.data.data;
};

// delete navbar
const deleteNavbar = async (id, token) => {
  const response = await axios.delete(`/api/v1/navbar/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

const navbarService = {
  createNavbar,
  getAllNavbars,
  getNavbarById,
  deleteNavbar,
};

export default navbarService;
