import React,{useEffect} from "react";
import { Container, Row, ListGroup } from "react-bootstrap";

const WhyST = () => {
  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <Container id="about" fluid className="text-center mb-4">
        <Row
          style={{
            backgroundColor: "#4AA24A",
            paddingBottom: "15px",
            paddingTop: "20px",
          }}
        >
          <h2>Why Smart Think</h2>
        </Row>

        <Container>
          <Row className="mt-3">
            <ListGroup style={{ textAlign: "justify" }}>
              At Smart Think, we believe that technology should be accessible to
              everyone, which is why we offer a wide range of high-quality
              devices and accessories at affordable prices. Here are just a few
              reasons why you should choose Smart Think for all your mobile and
              gadget needs:
              <ListGroup.Item variant="success" className="m-2">
                1. Extensive product selection: We carry a wide range of
                products from the world's leading brands, including Apple,
                Samsung, Huawei, Google, and more. Whether you're looking for a
                new smartphone, tablet, laptop, or accessory, we've got you
                covered.
              </ListGroup.Item>
              <ListGroup.Item variant="success" className="m-2">
                2. Competitive prices: At Smart Think, we believe that everyone
                should have access to the latest technology, which is why we
                offer our products at affordable prices. We work hard to keep
                our prices competitive, so you don't have to break the bank to
                stay up-to-date.
              </ListGroup.Item>
              <ListGroup.Item variant="success" className="m-2">
                3. Exceptional customer service: Our knowledgeable and friendly
                staff is always available to answer any questions you may have
                and help you find the perfect device to fit your needs. We
                understand that buying a new gadget can be overwhelming, which
                is why we're here to make the process as simple and stress-free
                as possible.
              </ListGroup.Item>
              <ListGroup.Item variant="success" className="m-2">
                4. Repair services: Accidents happen, and when they do, Smart
                Think is here to help. Our skilled technicians are experienced
                in repairing a variety of issues, including cracked screens,
                water damage, and battery replacements. We understand how
                important your device is to you and will work quickly to get it
                back up and running in no time.
              </ListGroup.Item>
              <ListGroup.Item variant="success" className="m-2">
                5. Convenience: With our online store, you can browse and
                purchase products from the comfort of your own home. We also
                offer in-store pickup and same-day delivery services, so you can
                get your new device as soon as possible.
              </ListGroup.Item>
              At Smart Think, we're committed to providing our customers with
              the best possible experience. Whether you're in the market for a
              new smartphone or need a repair, we're here to help. Shop with us
              today and experience the Smart Think difference.
            </ListGroup>
          </Row>
        </Container>
      </Container>
    )
  );
};

export default WhyST;
