import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import CategoriesToBuyNav from "../components/CategoriesToBuyNav";
import SmartPhoneDetails from "../components/smartphones/SmartPhoneDetails";
import {
  getSmartphoneById,
  resetSmartphoneDetails,
  deleteSmartphone,
  resetSmartphoneDelete,
} from "../features/smartphone/smartphoneSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { AiTwotoneDelete, AiFillEdit } from "react-icons/ai";

const SmartPhoneDetailsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { userInfo } = useSelector((state) => state.auth);

  const [smartPhone, setSmartPhone] = useState(null);

  const {
    smartphone,

    isDetailsLoading,
    isDetailsError,
    isDetailsSuccess,
    detailsErrorMessage,

    isDeleteLoading,
    isDeleteError,
    isDeleteSuccess,
    deleteErrorMessage,
  } = useSelector((state) => state.smartphone);

  useEffect(() => {
    if (isDetailsError) {
      toast.error(detailsErrorMessage, { position: "top-center" });
    } else if (isDetailsSuccess) {
      setSmartPhone(smartphone);
    } else {
      dispatch(getSmartphoneById(id));
    }
  }, [
    dispatch,
    id,
    isDetailsError,
    isDetailsSuccess,
    detailsErrorMessage,
    smartphone,
  ]);

  useEffect(() => {
    if (isDeleteError) {
      toast.error(deleteErrorMessage, { position: "top-center" });
    } else if (isDeleteSuccess) {
      toast.success("Smartphone Deleted Successfully", {
        position: "top-center",
      });
      navigate("/buySmartPhone");
    }
  }, [isDeleteError, isDeleteSuccess, deleteErrorMessage, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetSmartphoneDetails());
      dispatch(resetSmartphoneDelete());
    };
  }, [dispatch]);

  const deleteHandler = () => {
    if (
      window.confirm(
        "Smartphone will be deleted permanently, You sure you want to proceed?"
      )
    ) {
      dispatch(deleteSmartphone(id));
    }
  };

  // const smartPhone = smartPhones.find((smartPhone) => smartPhone.id === id * 1)
  return (
    <>
      <Container fluid>
        <CategoriesToBuyNav />
      </Container>

      <Container>
        <SmartPhoneDetails deviceType="smartPhone" device={smartPhone} />

        {userInfo && userInfo.userType === "admin" ? (
          <>
            <Row>
              <Col
                sm={12}
                md={12}
                lg={12}
                className="d-flex justify-content-end gap-2 mb-4"
              >
                <LinkContainer to={`/updateSmartphone/${id}`}>
                  <Button variant="outline-dark rounded">
                    <AiFillEdit className="mb-1" size={18} />
                    Update Information
                  </Button>
                </LinkContainer>

                <Button variant="outline-dark rounded" onClick={deleteHandler}>
                  <AiTwotoneDelete className="mb-1" size={18} />
                  Delete Smartphone
                </Button>
              </Col>
            </Row>
          </>
        ) : null}
      </Container>
    </>
  );
};

export default SmartPhoneDetailsScreen;
