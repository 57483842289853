import { React, useEffect } from "react";
import CategoriesToBuyNav from "../components/CategoriesToBuyNav";
import BuySmartPhone from "../components/smartphones/BuySmartPhone";

const BuySmartPhoneScreen = () => {
  return (
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []),
    (
      <>
        <CategoriesToBuyNav />
        <BuySmartPhone />
      </>
    )
  );
};

export default BuySmartPhoneScreen;
